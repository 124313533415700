export default {
	name: 'kh',
	custom:{
		make_id:{
			required: 'តំរូវអោយបំពេញម៉ាក'
		},
		model_id: {
	      	required: 'តំរូវអោយបំពេញម៉ូដែល',
	    },
	    body_type_id: {
	      	required: 'តំរូវអោយបំពេញតួរថយន្ត',
	    },
	    fuel_type_id: {
	      	required: 'តំរូវអោយបំពេញប្រភេទឥន្ធនៈ',
	    },
	    drive_type_id: {
	      	required: 'តំរូវអោយបំពេញប្រភេទរង្វិលកង់',
	    },
	    transmission_id: {
	      	required: 'តំរូវអោយបំពេញប្រអប់លេខ',
	    },
	    doors: {
	      	required: 'តំរូវអោយបំពេញចំនួនទ្វារ',
	    },
	    seats: {
	      	required: 'តំរូវអោយបំពេញចំនួនកៅអី',
	    },
	    gears: {
	      	required: 'តំរូវអោយបំពេញចំនួនលេខប្រអប់លេខ',
	    },
	    cylinders: {
	      	required: 'តំរូវអោយបំពេញចំនួនស៊ីឡាំង',
	    },
	    year_built: {
	      	required: 'តំរូវអោយបំពេញឆ្នាំផលិត',
	    },
	    month_built: {
	      	required: 'តំរូវអោយបំពេញខែផលិត',
	    },
	    turbo: {
	      	required: 'តំរូវអោយបំពេញទូប៊ូ',
	    },
	    engine_capacity: {
	      	required: 'តំរូវអោយបំពេញទំហំម៉ាស៊ីន',
	      	decimal:'បំពេញទំហំម៉ាស៊ីនអាចជាតួលេខ និងអាចមានលេខទសភាគ2ខ្ទង់',
	      	max:'បំពេញទំហំម៉ាស៊ីនមិនអាចលើសពី4តួអក្សរ'
	    },
	    chassis_number: {
	      	max:'បំពេញលេខ VIN មិនអាចលើសពី17តួលេខ'
	    }
	}
};
