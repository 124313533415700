<template>
    <div>
        <div v-if="loading">
            <spinner-component></spinner-component>
        </div>
        <div v-else>
            <div v-if="allReviews.total > 0">
                <ul class="cars-news-block row">
                    <li v-for="review in allReviews.data">
                        <div class="block-content">
                            <div v-if="review.review_type">
                                <a :href="'/car-reviews/'+review.slug" class="video-block">
                                    <iframe :src="review.video"></iframe>
                                </a>
                            </div>
                            <div v-else>
                                <a :href="'/car-reviews/'+review.slug" class="img-block" v-if="review.image">
                                    <img :src="baseUrl+'/timthumb.php?src='+review.image_full_path+'&w=408&h=236&zc=0'" /> 
                                </a>
                                <a :href="'/car-reviews/'+review.slug" class="img-block" v-else>
                                    <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=408&h=236&zc=0'" /> 
                                </a>
                            </div>
                            <div class="text-block">
                                <ul class="d-flex align-items-center flex-wrap review-row">
                                    <li>
                                        <a class="btn-custom text-uppercase" :href="'car-reviews/'+review.slug">
                                            {{ localeLang.TITLE }}
                                        </a>
                                    </li>
                                </ul>
                                <div class="review-desc">
                                    <a :href="'/car-reviews/'+review.slug">{{ review.title | str_limit(30) }}</a>
                                </div>
                                <p>{{ review.excerpt }}</p>
                                <div class="review-date">{{ review.created_at | humanDate }}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="row" v-else> 
                <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                    <div class="no-post-inr">
                        <div class="no-post-img">
                            <img src="/frontend/images/no-post-found.jpg" alt="">
                        </div>
                        <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                        <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" v-if="allReviews.last_page > 1">
            <div class="custom-pagination">
                <paginate
                    :page-count="allReviews.last_page"
                    :page-range="5"
                    :click-handler="paginateReviews"
                    :prev-text="localeLang.PAGINATION.PREV"
                    :next-text="localeLang.PAGINATION.NEXT"
                    :page-class="'page-item'"
                    :active-class="'active'"
                    :disabled-class="'disabled'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination justify-content-center'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>
<script>
    import Paginate from 'vuejs-paginate';
    import CommonMixin from '../../mixins/CommonMixin';
    import SpinnerComponent from '../../components/SpinnerComponent';
    export default {
        name:"reviews-vehicle-admin-component",
        props: [
            "reviews",
            "localeLang"
        ],
        mixins: [CommonMixin],
        components:{
            SpinnerComponent:SpinnerComponent,
            Paginate:Paginate,
        },
        data() {
            return {
                allReviews: [],
                loading : false,
            }
        },
        created(){
            this.allReviews = this.reviews;
        },
        methods:{
            /* Get reviews */
            async getReviews(page = 1){
                this.loading = true;
                let url = '/car-reviews?page='+page;
                axios.get(url).then(response => {
                    this.loading = false;
                    this.allReviews = response.data.data;
                    window.$(window).scrollTop(0);
                }).catch(error => {
                    this.loading = false;
                    this.allReviews = [];
                    window.$(window).scrollTop(0);
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },
            /* Paginate reviews */
            async paginateReviews(page) {
                this.getReviews(page);
            }
        }
    }
</script>
