<template>
    <form @submit.prevent="updateProfile">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>{{ localeLang.NAME_LABEL }}<span class="required">*</span></label>
                    <input type="text" class="form-control" name="name" :placeholder="localeLang.NAME_PLACEHOLDER" :class="errors.has('name')?'is-invalid':''" v-model="request.name" v-validate="'required|alpha_spaces|max:50'" data-vv-as="name">
                    <div class="invalid-feedback" v-if="errors.has('name')">
                        {{ errors.first('name') }}
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label>{{ localeLang.EMAIL_LABEL }}</label>
                    <input type="text" class="form-control" name="email" :placeholder="localeLang.EMAIL_PLACEHOLDER" :class="errors.has('email')?'is-invalid':''" v-model="request.email" v-validate="'email|max:100'" data-vv-as="email">
                    <div class="invalid-feedback" v-if="errors.has('email')">
                        {{ errors.first('email') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>{{ localeLang.PHONE_LABEL }}<span class="required">*</span></label>
                    <input type="text" class="form-control" name="phone" :placeholder="localeLang.PHONE_PLACEHOLDER" :class="errors.has('phone')?'is-invalid':''" v-model="request.phone" v-validate="'required|numeric|min:9|max:15'" data-vv-as="phone" readonly>
                    <div class="invalid-feedback" v-if="errors.has('phone')">
                        {{ errors.first('phone') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="isDealer">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>{{ localeLang.COMPANY_NAME_LABEL }}<span class="required">*</span></label>
                    <input type="text" class="form-control" name="company_name" :placeholder="localeLang.COMPANY_NAME_PLACEHOLDER" :class="errors.has('company_name')?'is-invalid':''" v-model="request.company_name" v-validate="'required|max:100'" data-vv-as="company name">
                    <div class="invalid-feedback" v-if="errors.has('company_name')">
                        {{ errors.first('company_name') }}
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label>{{ localeLang.ADDRESS_LABEL }}<span class="required">*</span></label>
                    <vue-google-autocomplete
                        ref="addressField"
                        name="address"
                        id="map"
                        classname="form-control"
                        :placeholder="localeLang.ADDRESS_PLACEHOLDER"
                        v-on:placechanged="getAddressData"
                        :country="['in', 'kh']"
                        :class="errors.has('address')?'is-invalid':''"
                        v-model="request.address"
                        v-validate="'required|max:100'"
                        data-vv-as="address"
                    >
                    </vue-google-autocomplete>
                    <div class="invalid-feedback" v-if="errors.has('address')">
                        {{ errors.first('address') }}
                    </div>
                </div>
            </div>
        </div>
<div class="row" v-if="isDealer">
        <div class="col-lg-12">
                <div class="form-group">
                    <label>{{ localeLang.BIO }}<span class="required">*</span></label>

                    <textarea   class="form-control" name="bio" rows="10" cols="50" :placeholder="localeLang.BIO" :class="errors.has('bio')?'is-invalid':''" v-model="request.bio" v-validate="'required|max:2000'" data-vv-as="Dealer Description"></textarea>
                    <div class="invalid-feedback" v-if="errors.has('bio')">
                        {{ errors.first('bio') }}
                    </div>
                </div>
            </div>
</div>
        <div class="btn-outer">
            <button type="button" class="submit-btn" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                <span>{{ localeLang.ACTION_TEXT }}</span>
            </button>
            <button type="submit" class="submit-btn" :disabled="errors.any()" v-else>
                {{ localeLang.ACTION_TEXT }}
            </button>
        </div>
    </form>
</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    import FormMixin from '../../mixins/FormMixin';
    import VueGoogleAutocomplete from '../VueGoogleAutocomplete'
    import profileDictionary from '../../locale/kh/profileDictionary';
    import VeeValidate, { Validator } from 'vee-validate';

    export default {
        name:"user-profile-component",
        props: [
            "profile",
            "localeName",
            "localeLang",
            "isDealer"
        ],
        mixins: [CommonMixin, FormMixin],
        components:{
            VeeValidate: VeeValidate,
            VueGoogleAutocomplete: VueGoogleAutocomplete
        },
        data() {
            return {
                loading : false,
                is_profile: false,
                user: this.profile,
                request: {
                    name: "",
                    email: "",
                    phone: "",
                    company_name: "",
                    address:"",
                    country:"",
                    state:"",
                    city:"",
                    postcode:"",
                    latitude:"",
                    longitude:"",
                    bio:"",
                }
            }
        },
        mounted(){
            if(this.profile){
                for (let user_profile in this.profile) {
                    if(this.request.hasOwnProperty(user_profile)){
                        if(this.profile[user_profile]){
                            this.request[user_profile] = this.profile[user_profile];
                        }
                    }
                }
            }
            if(this.localeName === 'kh'){
                Validator.localize('kh', profileDictionary);
            }
        },
        methods:{
            updateProfile() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post("/profile", this.request).then(response => {
                            this.loading = false;
                            this.user = this.request;
                            flash(response.data.message, response.data.status);
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            /**
            * When the location found
            * @param {Object} addressData Data of the found location
            * @param {Object} placeResultData PlaceResult object
            * @param {String} id Input container ID
            */
            getAddressData: function (data, placeResultData, id) {
                this.request.address = placeResultData.formatted_address;
                this.request.country = data.country;
                this.request.state = data.administrative_area_level_1;
                this.request.city = data.locality;
                this.request.postcode = data.postal_code;
                this.request.latitude = data.latitude;
                this.request.longitude = data.longitude;
            }
        }
    }
</script>
