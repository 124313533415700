window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');

    require('./scripts/iphone-menu.js');
    
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

window.token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.$(document).ready(function(){
    window.$(document).on("click", '.content-box-link', function(e){
        window.$(this).closest(".content-box").toggleClass( "info-active");
    });

    window.$(document).on( "mouseenter", '.car_for_sale_nav_item', function() {
        window.$('body').addClass('menu-bg-hover');
    });

    window.$(document).on( "mouseleave", '.car_for_sale_nav_item', function() {
        window.$('body').removeClass('menu-bg-hover'); 
    });

    window.$(document).on( "click", '.back-to-prev-btn', function() {
        //window.history.go(-1);
        window.location = document.referrer;
    });

    window.$('[data-toggle="tooltip"]').tooltip();

    let btn = window.$('.backtotop');

    window.$(window).scroll(function() {
      if (window.$(window).scrollTop() > 300) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });

    btn.on('click', function(e) {
      e.preventDefault();
      window.$('html, body').animate({scrollTop:0}, '300');
    });
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
