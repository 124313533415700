<template>
    <form @submit.prevent="search" ref="primarySearchForm">
        <ul class="BannerFormTab d-flex">
            <li>
                <div class="formRadio">
                    <input type="radio" name="type" value="" v-model="vehicleFilters.type">
                    <label>{{ localeLang.ALL }}</label>
                </div>
            </li>
            <li v-for="(type, key) in types">
                <div class="formRadio">
                    <input type="radio" name="type" :value="type.slug" v-model="vehicleFilters.type">
                    <label>{{ type.name }}</label>
                </div>
            </li>
        </ul>
        <div class="formContent">
            <div class="formContentInner">
                <div class="verticalText">
                    {{ localeLang.FIND_YOUR_NEXT_CAR }}
                </div>
                <div class="form-group">
                    <label>{{ localeLang.MAKE_LABEL }}</label>
                    <div class="select-outer">
                        <select class="form-control select-2-field" name="make" v-model="vehicleFilters.make" ref="makeField">
                            <option value="">{{ localeLang.MAKE_PLACEHOLDER }}</option>
                            <option v-for="make in makes" :value="make.slug">{{ make.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ localeLang.MODEL_LABEL }}</label>
                    <div class="select-outer">
                        <select class="form-control select-2-field" name="model" v-model="vehicleFilters.model" ref="modelField">
                            <option value="">{{ localeLang.MODEL_PLACEHOLDER }}</option>
                            <option v-for="model in vehicleModels" :value="model.slug">{{ model.name | capitalize | str_limit(20) }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ localeLang.ADDED_BY.TITLE }}</label>
                    <div class="select-outer">
                        <select class="form-control" name="type" v-model="vehicleFilters.added" ref="addedByField">
                            <option value="">{{ localeLang.ADDED_BY.PLACEHOLDER }}</option>
                            <option v-for="(addedBy, key) in localeLang.ADDED_BY.OPTIONS" :value="key">{{ addedBy }}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>{{ localeLang.MIN_PRICE_LABEL }}</label>
                            <div class="select-outer">
                                <select class="form-control select-2-field" name="min_price" v-model="vehicleFilters.min_price" ref="minPriceField">
                                    <option value="">{{ localeLang.MIN_PRICE_PLACEHOLDER }}</option>
                                    <option v-for="minPriceList in vehicleMinPriceLists" :value="minPriceList.value" :disabled="minPriceList.disabled">{{ minPriceList.display }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>{{ localeLang.MAX_PRICE_LABEL }}</label>
                            <div class="select-outer">
                                <select class="form-control select-2-field" name="max_price" v-model="vehicleFilters.max_price" ref="maxPriceField">
                                    <option value="">{{ localeLang.MAX_PRICE_PLACEHOLDER }}</option>
                                    <option v-for="maxPriceList in vehicleMaxPriceLists" :value="maxPriceList.value" :disabled="maxPriceList.disabled">{{ maxPriceList.display }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ localeLang.BODY_TYPE_LABEL }}</label>
                    <div class="select-outer">
                        <select class="form-control select-2-field" name="body_type" v-model="vehicleFilters.body_type" ref="bodyTypeField">
                            <option value="">{{ localeLang.BODY_TYPE_PLACEHOLDER }}</option>
                            <option v-for="(bodyType, key) in bodyTypes" :value="bodyType.slug">{{ bodyType.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ localeLang.FUEL_TYPE_LABEL }}</label>
                    <div class="select-outer">
                        <select class="form-control select-2-field" name="fuel_type" v-model="vehicleFilters.fuel_type" ref="fuelTypeField">
                            <option value="">{{ localeLang.FUEL_TYPE_PLACEHOLDER }}</option>
                            <option v-for="(fuelType, key) in fuelTypes" :value="fuelType.slug">{{ fuelType.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="btn searchBtn" disabled v-if="loading">
            <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
            <span>{{ localeLang.FILTER_ACTION }}</span>
        </button>
        <button type="submit" class="btn searchBtn" v-else>{{ localeLang.FILTER_ACTION }}</button>
    </form>
</template>
<script>
    import 'select2/dist/css/select2.min.css';
    import select2 from 'select2';
    import CommonMixin from '../../mixins/CommonMixin';
    import VehicleMixin from '../../mixins/VehicleMixin';
    export default {
        name:"home-search-component",
        props:[
            "types", 
            "makes",
            "bodyTypes", 
            "fuelTypes",
            "localeLang"
        ],
        mixins: [CommonMixin, VehicleMixin],
        computed: {
            searchUrl: function () {
                let searchUrl = "/vehicles";

                if(this.vehicleFilters.make){
                    searchUrl += "/make-"+this.vehicleFilters.make;
                }
                if(this.vehicleFilters.model){
                    searchUrl += "/model-"+this.vehicleFilters.model;
                }
                if(this.vehicleFilters.body_type){
                    searchUrl += "/style-"+this.vehicleFilters.body_type;
                }
                if(this.vehicleFilters.type){
                    searchUrl += "/type-"+this.vehicleFilters.type;
                }
                if(this.vehicleFilters.added){
                    searchUrl += "/added-"+this.vehicleFilters.added;
                }
                if(this.vehicleFilters.fuel_type){
                    searchUrl += "/fule-"+this.vehicleFilters.fuel_type;
                }
                if(this.vehicleFilters.min_price && this.vehicleFilters.max_price){
                    searchUrl += "/price-"+this.vehicleFilters.min_price+"-"+this.vehicleFilters.max_price;
                }else if(this.vehicleFilters.min_price){
                    searchUrl += "/over-"+this.vehicleFilters.min_price;
                }else if(this.vehicleFilters.max_price){
                    searchUrl += "/under-"+this.vehicleFilters.max_price;
                }
                return searchUrl;
            }
        },
        watch: {
            "vehicleFilters.make": function(n, o) {
                this.vehicleModels = [];
                this.vehicleFilters.model = "";
                if(this.vehicleFilters.make != ""){
                    this.getModels();
                }
            },
            "vehicleFilters.model": function(n, o) {
                this.vehicleBadges = [];
                this.vehicleFilters.badge = "";

                if(this.vehicleFilters.model != ""){
                    this.getBadges();
                }
            },
            "vehicleFilters.min_price": function(n, o) {
                this.vehicleMaxPriceLists = this.vehicleMaxPriceLists.map(priceList => ({
                    disabled: ((this.vehicleFilters.min_price !== "") && (priceList.value < this.vehicleFilters.min_price)),
                    value:priceList.value,
                    display:priceList.display
                }));
            },
            "vehicleFilters.max_price": function(n, o) {
                this.vehicleMinPriceLists = this.vehicleMinPriceLists.map(priceList => ({
                    disabled:  ((this.vehicleFilters.max_price !== "") && (priceList.value > this.vehicleFilters.max_price)),
                    value:priceList.value,
                    display:priceList.display
                }));
            }
        },
        methods: {
            search() {
                this.loading = true;
                window.location = this.searchUrl;
            }
        },
        mounted(){
            let vm = this;
            Vue.nextTick(() => {
                /* make select2 field */
                window.$(this.$refs.makeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.make = this.value;
                });

                /* model select2 field */
                window.$(this.$refs.modelField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.model = this.value;
                });

                /* added select2 field */
                window.$(this.$refs.addedByField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.added = this.value;
                });

                /* min price select2 field */
                window.$(this.$refs.minPriceField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.min_price = this.value;
                });

                /* max price select2 field */
                window.$(this.$refs.maxPriceField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.max_price = this.value;
                });

                /* body type select2 field */
                window.$(this.$refs.bodyTypeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.body_type = this.value;
                });

                /* fuel type select2 field */
                window.$(this.$refs.fuelTypeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 5
                }).on("select2:select", function(e) {
                    vm.vehicleFilters.fuel_type = this.value;
                });

            });
        },
        destroyed: function () {
            window.$(this.$refs.makeField).off().select2('destroy');
            window.$(this.$refs.modelField).off().select2('destroy');
            window.$(this.$refs.addedByField).off().select2('destroy');
            window.$(this.$refs.minPriceField).off().select2('destroy');
            window.$(this.$refs.maxPriceField).off().select2('destroy');
            window.$(this.$refs.bodyTypeField).off().select2('destroy');
            window.$(this.$refs.fuelTypeField).off().select2('destroy');
        }
    }
</script>
