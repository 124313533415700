<template>
    <div class="create-ads-inner global-form create-car-ads-form">
        <div class="row">
            <div class="col-12">
                <ul class="add-steps d-flex">
                    <li :class="(steps >= 1) ? 'complete': ''">
                        <a :href="(steps >= 1) ? '/vehicle/'+vehicle.slug+'/general' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-car"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.GENERAL_INFO }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 2) ? 'complete': ''">
                        <a :href="(steps >= 2) ? '/vehicle/'+vehicle.slug+'/features' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-list"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.SELECT_FEATURES }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 3) ? 'complete': ''">
                        <a :href="(steps >= 3) ? '/vehicle/'+vehicle.slug+'/details' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-user-plus"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ADD_DETAILS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 4) ? 'complete': ''">
                        <a :href="(steps >= 4) ? '/vehicle/'+vehicle.slug+'/photos' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-camera-retro"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ATTACH_PHOTOS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 5) ? 'complete': ''">
                        <a :href="(steps >= 5) ? '/vehicle/'+vehicle.slug+'/preview' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-shopping-basket"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.REVIEW_COMPLETE }}</h3>
                        </a>
                    </li>
                </ul>
                <div class="progress mx-auto" :data-value="progress"> 
                    <span class="progress-left"> 
                        <span class="progress-bar border-primary"></span> 
                    </span> 
                    <span class="progress-right"> 
                        <span class="progress-bar border-primary"></span> 
                    </span>
                    <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="value-text">{{ progress }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-block-outer">
            <div class="step-box1 step-4">
                <div class="row">
                    <div class="col-12">
                        <div class="car-find-sub-hd"> 
                            {{ localeLang.PHOTOS.SUB_TITLE }} {{ vehicle.title }} 
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <upload-multiple-images
                            @image-processing="imageProcessing"
                            @image-processed="imageProcessed"
                            @limit-exceeded="limitExceeded"
                            @invalid-file="invalidFile"
                            :vehicle-slug="vehicle.slug"
                            :data-images="images"
                            idUpload="myIdUpload"
                            editUpload="myIdEdit"
                            accept="image/jpeg,image/png,image/jpg"
                            :drag-text="localeLang.PHOTOS.LABLES.DRAGE_TEXT"
                            :drop-text="localeLang.PHOTOS.LABLES.DROP_TEXT"
                            :browse-text="localeLang.PHOTOS.LABLES.BROWSE_TEXT"
                            :primary-text="localeLang.PHOTOS.LABLES.PRIMARY_TEXT"
                            :delete-text="localeLang.MESSAGES.WARN_DELETE_PHOTO"
                            :error-text="localeLang.MESSAGES.SOMTHING_WRONG"
                            :mark-is-primary-text="localeLang.PHOTOS.LABLES.MARK_IS_PRIMARY_TEXT"
                        ></upload-multiple-images>
                        <div class="d-flex justify-content-center processing-loader" v-if="isImageProcessing">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <p>{{ localeLang.PHOTOS.LABLES.WAIT_TILL_PROCESSING }}</p>
                        </div>
                        <p style="margin-top:10px">
                            <strong>{{ localeLang.PHOTOS.NOTE.TITLE }}: </strong>
                            {{ localeLang.PHOTOS.NOTE.TEXT }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-outer text-right">
            <a :href="'/vehicle/'+vehicle.slug+'/details'" class="submit-btn pull-left blue-btn">{{ localeLang.ACTIONS.PREV }}</a>
            <div v-if="(isImageProcessing === false) && (isImageError === false)">
                <button type="button" class="submit-btn" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                    <span>{{ localeLang.ACTIONS.NEXT }}</span>
                </button>
                <button type="button" class="submit-btn" v-on:click="savePhotos()" :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.NEXT }}</button>
            </div>
        </div>
    </div>
</template>
<script>
    import FormMixin from '../../../mixins/FormMixin';
    import CommonMixin from '../../../mixins/CommonMixin';
    import VehicleFormMixin from '../../../mixins/VehicleFormMixin';
    import UploadMultipleImages from '../../UploadMultipleImages' 
    import VeeValidate from 'vee-validate';
    import bootbox from "bootbox";

    Vue.use(VeeValidate);

    export default {
        name:"user-manage-ad-photos-component",
        props:[
            "vehicle",
            "localeLang"
        ],
        components:{
            UploadMultipleImages: UploadMultipleImages
        },
        mixins: [CommonMixin, VehicleFormMixin, FormMixin],
        data() {
            return {
                loading : false,
                isImageProcessing : false,
                isImageError : false,
                steps: 3,
                progress: 60,
                images:[]
            }
        },
        created() {
            if(this.vehicle){
                this.steps = this.vehicle.steps;
                this.progress = (this.vehicle.steps * 20);
                for (let i = 0; i < Object.keys(this.vehicle.vehicle_images).length; i++) {
                    let index = Object.keys(this.vehicle.vehicle_images)[i];
                    let image = this.vehicle.vehicle_images[index];
                    this.images.push({
                        id: image.id,
                        path: this.baseUrl+'/timthumb.php?src='+image.image_full_path+'&w=434&h=261&zc=0',
                        default: (image.image_type === 'front') ? 1 : 0,
                        highlight: (image.image_type === 'front') ? 1 : 0,
                        caption: image.caption,
                    });
                } 
            }
        },
        methods: {
            savePhotos() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/api/vehicle/manage/photos', {
                            vehicle_slug: this.vehicle.slug
                        }).then(response => {
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                window.location = "/vehicle/"+response.data.slug+"/preview"
                            }else{
                                this.loading = false;
                            }
                        }).catch(error => {
                            this.loading = false;
                            flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            console.log(error);
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            
            async imageProcessing(){
                this.isImageProcessing = true;
            },
            async imageProcessed(){
                this.isImageProcessing = false;
            },
            async limitExceeded(){
                bootbox.alert(this.localeLang.PHOTOS.LABLES.MAX_IMAGE_TEXT);
            },
            async invalidFile(){
                bootbox.alert(this.localeLang.PHOTOS.LABLES.INVALID_FILE_TEXT);
            } 
        }
    }
</script>
<style>
    .image-list{
        height: 261px !important;
    }
</style>
