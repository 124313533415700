<template>
    <div class="alert fade show text-left" :class="alertType" role="alert" v-show="show">
        {{ body }}
    </div>
</template>
<script>
    export default {
        name: "flash-message-component",
        props: ["message"],
        data() {
            return {
                alertType: "success",
                show: false,
                body: ""
            };
        },
        created() {
            if (this.message) {
                this.flash(this.message);
            }
            window.events.$on("flash", message => this.flash(message));
        },
        beforeDestroy() {
            window.events.$off("flash", message => this.flash(message));
        },
        methods: {
            flash(data) {
                this.alertType = "alert-" + data.variant;
                this.body = data.message;
                this.show = true;
                //document.body.scrollTop = 100; // For Safari
                //document.documentElement.scrollTop = 100; // For Chrome, Firefox, IE and Opera
                setTimeout(() => {
                    this.hide();
                }, 20000);
            },
            hide() {
                this.show = false;
            }
        }
    };
</script>

<style scoped>
    .alert-error {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
    
</style>
