<template>
    <div>
        <!--tab-->
        <ul class="nav nav-tabs">
            <li class="nav-item"> 
                <a class="nav-link active" data-toggle="tab" href="#body-type">
                    {{ localeLang.BODY_TYPE_LABEL }}
                </a> 
            </li>
            <li class="nav-item"> 
                <a class="text nav-link" data-toggle="tab" href="#brand">
                    {{ localeLang.BRAND_LABEL }}
                </a> 
            </li>
        </ul>
        <div class="tab-content">
            <div id="body-type" class="container tab-pane active">
                <div v-if="Object.keys(bodyTypes).length > 0">
                    <div class="body-type-slider owl-carousel" ref="bodyTypeSlider">
                        <div class="item" v-for="bodyType in bodyTypes">
                            <div class="car-content-box"> 
                                <a class="d-block" :href="'/vehicles/style-'+bodyType.slug+'/type-new'">
                                    <div class="img-block d-flex align-items-center justify-content-center"> 
                                        <img :src="bodyType.icon ? bodyType.icon_full_path : '/frontend/images/defaults/vehicle-body-type.png'" alt=""> 
                                    </div>
                                    <div class="car-name text-center text-uppercase">{{ bodyType.name }}</div>
                                </a> 
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>No body types exists yet!</p>
                </div> 
            </div>
            <div id="brand" class="container tab-pane fade">
                <div v-if="Object.keys(makes).length > 0">
                    <div class="brands-slider owl-carousel" ref="brandSlider">
                        <div class="item" v-for="make in makes">
                            <div class="car-content-box"> 
                                <a class="d-block" :href="'/vehicles/make-'+make.slug+'/type-new'">
                                    <div class="img-block d-flex align-items-center justify-content-center"> 
                                        <img :src="make.icon ? make.icon_full_path : '/frontend/images/defaults/vehicle-brand.png'" alt="">  
                                    </div>
                                    <div class="car-name text-center text-uppercase">{{ make.name }}</div>
                                </a> 
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>No brands exists yet!</p>
                </div> 
            </div>
        </div>
        <!--tab end--> 
    </div>
</template>
<script>
    import 'owl.carousel';
    export default {
        name:"home-brand-search-component",
        props:[
            "makes", 
            "bodyTypes",
            "localeLang"
        ],
        mounted() {
            let vm = this;
            Vue.nextTick(function(){
                /* Body type slider */
                window.$(this.$refs.bodyTypeSlider).owlCarousel({
					margin: 15,
                    loop: false,
                    rewind: true,
                    dot: false,
                    nav: true,
                    autoplay: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        480: {
                            items: 2
                        },
                        767: {
                            items: 3
                        },
                        992: {
                            items: 4
                        },
                        1200: {
                            items: 6
                        }
                    }
				});

                /* Brand slider */
                window.$(this.$refs.brandSlider).owlCarousel({
					margin: 15,
                    loop: false,
                    rewind: true,
                    dot: false,
                    nav: true,
                    autoplay: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        480: {
                            items: 2
                        },
                        767: {
                            items: 3
                        },
                        992: {
                            items: 4
                        },
                        1200: {
                            items: 6
                        }
                    }
				});
                
            }.bind(vm));
        },
        destroyed: function () {
            window.$(this.$refs.bodyTypeSlider).owlCarousel('destroy');
            window.$(this.$refs.brandSlider).owlCarousel('destroy');
        }
    }
</script>
