<template>
    <div class="ps-fixed-top" :class="showCompare ? 'show-bar': ''">
        <div class="container">
            <a class="btn icon-btn ps-btn1" href="javascript:void('0');" v-on:click.prevent="toggleCompare()">
                {{ localeLang.COMPARE.TITLE }} ({{ compareCount }}) <i class="fa fa-angle-down"></i>
            </a>
            <div class="ps-fixed-inr">
                <div class="d-flex flex-wrap ps-inr2">
                    <div class="bx1">
                        <h3>{{ localeLang.COMPARE.TITLE }}</h3>
                        <a class="ps-btn2" href="javascript:void('0');" v-on:click.prevent="toggleCompare()">
                            {{ localeLang.COMPARE.TOGGLE_ACTION }} <i class="fa fa-angle-up"></i>
                        </a>
                    </div>
                    <ul>
                        <li v-for="(vehicle, key) in comparingVehicles">
                            <div class="cpr-img" v-if="vehicle.image">
                                <img :src="baseUrl+'/timthumb.php?src='+vehicle.image+'&w=408&h=236&zc=0'" /> 
                            </div>
                            <div class="cpr-img" v-else>
                                <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=408&h=236&zc=0'" /> 
                            </div>
                            <div class="cpr-info">
                                <div class="cpr-nm">{{ vehicle.title }}</div>
                                <a class="ps-btn3 btn icon-btn" href="javascript:;" v-on:click.prevent="remove(vehicle.id, key)">
                                    {{ localeLang.COMPARE.REMOVE_ACTION }} <i class="fa fa-remove"></i>
                                </a>
                            </div>
                        </li>
                    </ul>
                    <div class="bx2">
                        <a :href="compareButtonEnabled ? compareUrl : 'javascript:;'" class="ps-btn4 btn icon-btn">
                            {{ localeLang.COMPARE.TITLE }} ({{ compareCount }})
                        </a>
                        <a href="javascript:;" class="ps-btn5" v-on:click.prevent="removeAll()">
                            {{ localeLang.COMPARE.REMOVE_ALL_ACTION }} <i class="fa fa-remove"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    export default {
        name:"vehicle-compare-list-component",
        props:[
            "comparingVehicles",
            "localeLang"
        ],
        mixins: [CommonMixin],
        data() {
            return {
                showCompare : false
            }
        },
        computed: {
            /* compare count */
            compareCount: function(){
                return Object.keys(this.comparingVehicles).length;
            },
            /* compare url */
            compareUrl: function() {
                let compareUrl = "/compare/";
                for (let i = 0; i < this.compareCount; i++) {
                    let index = Object.keys(this.comparingVehicles)[i];
                    let vehicle = this.comparingVehicles[index];
                    if(i === 0){
                        compareUrl +=vehicle.slug;
                    }else{
                        compareUrl +="-vs-"+vehicle.slug;
                    }
                }
                return compareUrl;
            },
            /* compare button enable/disable */
            compareButtonEnabled: function(){
                return (this.compareCount > 1) ? true : false;
            }

        },
        watch:{
            compareCount: function(n, o){
                if(n > 0){
                    this.showCompare = true;
                }
            }
        },
        methods:{
            /* toggle compare */
            toggleCompare(){
                if(this.showCompare){
                    this.showCompare = false;
                }else{
                    this.showCompare = true;
                }
            },
            /* remove vehicle from compare*/
            remove(vehicleId, key){
                this.$emit('handle-remove-compare', vehicleId, key);
            },

            /* remove all vehicle from compare */
            removeAll(){
                this.$emit('handle-remove-all-compare');
            }
          
        }
    }
</script>
