<template>
    <div class="owl-carousel block-slider slider-with-nav" ref="reviewsSliderContainer">
  	<div class="items" v-for="review in reviews">
        <div class="block-content">
			<div v-if="review.review_type">
				<a :href="'/car-reviews/'+review.slug" class="video-block">
					<iframe :src="review.video"></iframe>
				</a>
			</div>
			<div v-else>
				<a :href="'/car-reviews/'+review.slug" class="img-block" v-if="review.image">
					<img :src="baseUrl+'/timthumb.php?src='+review.image_full_path+'&w=408&h=236&zc=0'" /> 
				</a>
				<a :href="'/car-reviews/'+review.slug" class="img-block" v-else>
					<img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=408&h=236&zc=0'" /> 
				</a>
			</div>
			<div class="text-block">
				<ul class="d-flex align-items-center flex-wrap review-row">
					<li>
						<a :href="'/car-reviews/'+review.slug" class="btn-custom text-uppercase">{{ localeLang.SUB_TITLE }}</a>
					</li>
				</ul>
				<div class="review-desc">
					<a :href="'/car-reviews/'+review.slug">
						{{ review.title | str_limit(30) }}
					</a>
				</div>
				<p>{{ review.excerpt }}</p>
				<div class="review-date">{{ review.created_at | humanDate }}</div>
			</div>
		</div>
  	</div>
</div>
  	
</template>
<script>
	import 'owl.carousel';
	import CommonMixin from '../../mixins/CommonMixin';
    export default {
        name:"home-reviews-component",
		props:[
			"reviews",
			"localeLang"
		],
		mixins: [CommonMixin],
        data() {
            return {
                loading : false,
            }
        },
        mounted() {
            let vm = this;
            Vue.nextTick(function(){
                window.$(this.$refs.reviewsSliderContainer).owlCarousel({
					margin: 40,
					loop: false,
                    rewind: true,
					autoplay:false,
					nav:true,
					responsive: {
					0: {
						items: 1,
					},
					600: {
						items: 2,
					},
					1000: {
						items: 3,
					}
					}
				});
                
            }.bind(vm));
        },
    }
</script>
