<template>
    <div class="owl-carousel block-slider slider-with-nav" ref="newsSliderContainer">
        <div class="items" v-for="news in news">
            <div class="block-content">
                <div v-if="news.news_type">
                    <a :href="'/news/'+news.slug" class="video-block">
                        <iframe :src="news.video"></iframe>
                    </a>
                </div>
                <div v-else>
                    <a :href="'/news/'+news.slug" class="img-block" v-if="news.image">
                        <img :src="baseUrl+'/timthumb.php?src='+news.image_full_path+'&w=408&h=236&zc=0'" /> 
                    </a>
                    <a :href="'/news/'+news.slug" class="img-block" v-else>
                        <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=408&h=236&zc=0'" /> 
                    </a>
                </div>
                <div class="text-block">
                    <ul class="d-flex align-items-center flex-wrap review-row">
                        <li>
                            <a :href="'/news/'+news.slug" class="btn-custom text-uppercase">{{ localeLang.SUB_TITLE }}</a>
                        </li>
                    </ul>
                    <div class="review-desc">
                        <a :href="'/news/'+news.slug">
                            {{ news.title | str_limit(30) }}
                        </a>
                    </div>
                    <p>{{ news.excerpt }}</p>
                    <div class="review-date">{{ news.created_at | humanDate }}</div>
                </div>
            </div>
        </div>
    </div>
            
</template>
<script>
	import 'owl.carousel';
    import CommonMixin from '../../mixins/CommonMixin';
    export default {
        name:"home-news-component",
        props:["news", "localeLang"],
        mixins: [CommonMixin],
        mounted() {
            let vm = this;
            Vue.nextTick(function(){
                window.$(this.$refs.newsSliderContainer).owlCarousel({
					margin: 40,
					loop: false,
                    rewind: true,
					autoplay:false,
                    nav:true,
					responsive: {
    					0: {
    						items: 1,
    					},
    					600: {
    						items: 2,
    					},
    					1000: {
    						items: 3,
    					}
					}
				});
                
            }.bind(vm));
        },
        destroyed: function () {
            window.$(this.$refs.newsSliderContainer).owlCarousel('destroy');
        }
    }
</script>
