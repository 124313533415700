export default {
	name: 'kh',
	custom:{
		email: {
	      	required: 'តំរូវអោយបំពេញអ៊ីម៉ែល',
	      	email: 'ត្រូវបំពេញអ៊ីម៉ែលអោយបានត្រឹមត្រូវ',
	      	max:'បំពេញអ៊ីម៉ែលមិនអាចលើសពី100តួអក្សរ'
	    }
	},
};
