<template>
    <div>
        <div v-if="loading">
            <spinner-component></spinner-component>
        </div>
        <div v-else>
            <div v-if="allVehicles.total > 0">
                <div class="row">
                    <div class="col-sm-6" v-for="(vehicle, key) in allVehicles.data">
                        <div class="car-item">
                            <div class="list-item-img"> 
                                <a :href="'/vehicle/'+vehicle.slug" v-if="vehicle.main_image"> 
                                    <img :src="baseUrl+'/timthumb.php?src='+vehicle.main_image.image_full_path+'&w=434&h=261&zc=0'" /> 
                                </a>
                                <a :href="'/vehicle/'+vehicle.slug" v-else>
                                    <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=434&h=261&zc=0'" /> 
                                </a>
                                <div class="price-block double-price" v-if="vehicle.discount_price">
                                    <span class="original-price">{{ vehicle.price | toCurrency }}</span>
                                    <span class="discount-price" v-if="vehicle.is_masked_price">
                                        {{ vehicle.discount_price | toMaskedPrice(vehicle.initial_masking_digits) }}*</span>
                                    </span>
                                    <span class="discount-price" v-else>{{ vehicle.discount_price | toCurrency }}*</span>
                                </div>
                                <div class="price-block" v-else>
                                    <span v-if="vehicle.is_masked_price">{{ vehicle.price | toMaskedPrice(vehicle.initial_masking_digits) }}*</span>
                                    <span v-else>{{ vehicle.price | toCurrency }}*</span>
                                </div>
                                <div class="label-block" v-if="vehicle.is_featured == 1">
                                    {{ localeLang.BADGES.FEATURED }}
                                </div>
                                <div class="badges" v-if="vehicle.is_inspected">
                                    <img src="/frontend/images/badges.png">
                                    {{ localeLang.BADGES.INSPECTED }}
                                </div>
                            </div>
                            <div class="item-icon-bar">
                                <ul>
                                    <li>
                                        <a href="javascript:;">&nbsp;</a>
                                    </li> 
                                    <li>
                                        <a class="active" href="javascript:;" v-on:click.prevent="deleteFavourite(allVehicles.data, key)">
                                            <i class="fa fa-heart"></i> {{ localeLang.ACTIONS.SAVE_FAV }}
                                        </a>
                                    </li> 
                                </ul>    
                            </div>    
                            <div class="list-item-detail">
                                <h4>
                                    <a :href="'/vehicle/'+vehicle.slug">
                                        {{ vehicle.title }}
                                    </a>
                                </h4> 
                                <ul class="d-flex flex-wrap">
                                    <li v-if="vehicle.bodystyle">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/body-type-icon.png" alt="body-type-icon">
                                            </div>
                                            <span>{{ vehicle.bodystyle.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.fuel_type">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/engine-icon.png" alt="odometer-icon">
                                            </div>
                                            <span>{{ vehicle.fuel_type.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.transmissions">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/transmision-icon.png" alt="transmision-icon">
                                            </div>
                                            <span>{{ vehicle.transmissions.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.odometer">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/odometer-icon.png" alt="odometer-icon">
                                            </div>
                                            <span>{{ vehicle.odometer | toNumber }} {{ localeLang.LABLES.KM }}</span>
                                        </div>
                                    </li>
                                </ul>   
                                <div class="view-info-sec d-flex">
                                    <div class="left-info">
                                        <span>
                                            {{ (vehicle.role === 'Dealer') ? localeLang.LABLES.DEALER : localeLang.LABLES.SELLER }} {{ (vehicle.category_id === 1) ? localeLang.LABLES.NEW_CAR : localeLang.LABLES.USED_CAR }}
                                        </span> 
                                        <span>
                                            {{ vehicle.state ? vehicle.state : vehicle.country }} {{ vehicle.city ? '-' +vehicle.city : '' }}
                                        </span>
                                    </div>
                                    <div class="inquery-btn-col"> 
                                        <a class="view-detail-btn" :href="'/vehicle/'+vehicle.slug">
                                            {{ localeLang.ACTIONS.DETAIL }}
                                        </a> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                    <div class="no-post-inr">
                        <div class="no-post-img">
                            <img src="/frontend/images/no-post-found.jpg" alt="">
                        </div>
                        <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                        <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" v-if="allVehicles.last_page > 1">
            <div class="custom-pagination">
                <paginate
                    :page-count="allVehicles.last_page"
                    :page-range="5"
                    :click-handler="paginateVehicles"
                    :prev-text="localeLang.PAGINATION.PREV"
                    :next-text="localeLang.PAGINATION.NEXT"
                    :page-class="'page-item'"
                    :active-class="'active'"
                    :disabled-class="'disabled'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination justify-content-center'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>
<script>
    import bootbox from "bootbox";
    import Paginate from 'vuejs-paginate';
    import CommonMixin from '../../mixins/CommonMixin';
    import SpinnerComponent from '../../components/SpinnerComponent';
    export default {
        name:"user-saved-vehicle-component",
        props: [
            "vehicles",
            "localeLang", 
            "loggedinUser"
        ],
        mixins: [CommonMixin],
        components:{
            Paginate:Paginate,
            SpinnerComponent:SpinnerComponent
        },
        data() {
            return {
                allVehicles: [],
                loading : false,
            }
        },
        mounted(){
            this.allVehicles = this.vehicles;
        },
        methods:{
            /* Get vehicles */
            async getVehicles(page = 1){
                this.loading = true;
                let url = '/saved-vehicles/?page='+page;
                axios.get(url).then(response => {
                    this.loading = false;
                    this.allVehicles = response.data.data;
                    window.$(window).scrollTop(0);
                }).catch(error => {
                    this.loading = false;
                    this.allVehicles = [];
                    window.$(window).scrollTop(0);
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

            /* delete from  favourite */
            async deleteFavourite(items, key){
                let vm = this;
                bootbox.confirm(this.localeLang.MESSAGES.REMOVE_FROM_SAVED1+" "+items[key].title+" "+this.localeLang.MESSAGES.REMOVE_FROM_SAVED2, function(result){
                    if(result){
                        let url = '/api/vehicle/manage/'+items[key].id+'/toogle-favourite';
                        axios.get(url).then(response => {
                            this.loading = false;
                            if(response.data.status === "success"){
                                Vue.delete(items, key);
                            }
                            flash(response.data.message, 'success');
                        }).catch(error => {
                            this.loading = false;
                            flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            console.log(error);
                        });
                    }
                });
            },
            /* Paginate vehicles */
            paginateVehicles(page) {
                this.getVehicles(page);
            },
        }
    }
</script>
