<template>
    <div>
        <div class="login-top">
            <h4>{{ title }}</h4>
            <p>{{ localeLang.SUB_TITLE }}</p>
        </div>
        <div class="login-middle">
            <form @submit.prevent="registerAccount" v-if="steps === 1">
                <div class="form-group">
                    <select name="role_id"  v-model="request.role_id" v-validate="'required'" :class="errors.has('name')?'is-invalid':''" data-vv-as="role"  class="form-control" placeholder="Select Your Role" key="name">
                        <option value="">{{ localeLang.FIELDS.ROLES.TITLE }}</option>
                        <option :value="roleid" v-for="(role, roleid) in localeLang.FIELDS.ROLES.OPTIONS">{{ role }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="errors.has('role_id')">
                        {{ errors.first('role_id') }}
                    </div>
                </div>
                <div class=" form-group">
                    <input type="text" :placeholder="localeLang.FIELDS.NAME" class="form-control"  name="name" :class="errors.has('name')?'is-invalid':''" v-model="request.name"  v-validate="'required|alpha_spaces|max:50'" data-vv-as="name" key="name" >
                    <div class="invalid-feedback" v-if="errors.has('name')">
                        {{ errors.first('name') }}
                    </div>
                </div>
                <div v-if="request.role_id == 3">
                    <div class=" form-group">
                        <input type="text" :placeholder="localeLang.FIELDS.COMPANY_NAME" class="form-control"  name="company_name" :class="errors.has('company_name')?'is-invalid':''" v-model="request.company_name"  v-validate="{ required: (request.role_id == 3) ? true : false, max:100}" data-vv-as="company name" key="company_name" >
                        <div class="invalid-feedback" v-if="errors.has('company_name')">
                            {{ errors.first('company_name') }}
                        </div>
                    </div>
                    <div class="form-group">
                        <vue-google-autocomplete
                            ref="addressField"
                            name="address"
                            id="map"
                            classname="form-control"
                            :placeholder="localeLang.FIELDS.LOCATION_ADDRESS"
                            v-on:placechanged="getAddressData"
                            :country="['in', 'kh']"
                            :class="errors.has('address')?'is-invalid':''"
                            v-model="request.address"
                            v-validate="{ required: (request.role_id == 3) ? true : false, max:100}"
                            data-vv-as="address"
                            autocomplete="off"
                        >
                        </vue-google-autocomplete>
                        <div class="invalid-feedback" v-if="errors.has('address')">
                            {{ errors.first('address') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <select name="countryCode"  v-model="request.countryCode" data-vv-as="country code" class="form-control">
                                <option :value="countryid" v-for="(country, countryid) in localeLang.FIELDS.COUNTRY">{{ country }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <input type="text" :placeholder="localeLang.FIELDS.PHONE" class="form-control"  name="phone" :class="errors.has('phone')?'is-invalid':''" v-model="request.phone" v-validate="'required|numeric|min:4|max:16'"   data-vv-as="phone" key="phone">
                            <div class="invalid-feedback" v-if="errors.has('phone')">
                                {{ errors.first('phone') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" :placeholder="localeLang.FIELDS.EMAIL" class="form-control" name="email" :class="errors.has('email')?'is-invalid':''" v-model="request.email" v-validate="'email|max:50'" data-vv-as="email address" ref="email" key="email">
                    <div class="invalid-feedback" v-if="errors.has('email')">
                        {{ errors.first('email') }}
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" :placeholder="localeLang.FIELDS.PASSWORD" class="form-control password " name="password" :class="errors.has('password')?'is-invalid':''" v-model="request.password" v-validate="'required|min:8|max:12'" data-vv-as="password" ref="password" key="password">
                    <div class="invalid-feedback" v-if="errors.has('password')">
                        {{ errors.first('password') }}
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" :placeholder="localeLang.FIELDS.CONFIRM_PASSWORD" class="form-control password" name="password_confirmation" :class="errors.has('password_confirmation')?'is-invalid':''" v-model="request.password_confirmation" v-validate="'required|confirmed:password'" data-vv-as="confirm password" key="password_confirmation">
                    <div class="invalid-feedback" v-if="errors.has('password_confirmation')">
                        {{ errors.first('password_confirmation') }}.
                    </div>
                </div>

                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.SIGN_UP }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block " :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.SIGN_UP }}</button>
                </div>
            </form>
            <form @submit.prevent="verifyOtp" v-else>
                <div class=" form-group">
                    <input type="text" :placeholder="localeLang.FIELDS.OTP" class="form-control"  name="otp" :class="errors.has('otp')?'is-invalid':''" v-model="request.otp"  v-validate="'required|numeric|min:6|max:6'" data-vv-as="OTP" key="otp" >
                    <div class="invalid-feedback" v-if="errors.has('otp')">
                        {{ errors.first('otp') }}
                    </div>
                </div>
                <div class="text-right">
                    <a href="javascript:;" v-if="loading === false" class="forgot-pass font-weight-bold" v-on:click="resendOtp()">{{ localeLang.ACTIONS.RSEND_OTP }}</a>
                    <a href="javascript:;" v-else class="forgot-pass font-weight-bold">{{ localeLang.ACTIONS.RSEND_OTP }}</a>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.VERIFY_OTP }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block" :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.VERIFY_OTP }}</button>
                </div>
            </form>
            <div class="or-seperate">
                <span class="register">
                    <strong>{{ localeLang.LABELS.OR }}</strong>
                </span>
            </div>
            <div class="register-option d-flex">
                <a href="/login/facebook" class="facebook">
                    <i class="fa fa-facebook"></i>
                    <span>{{ localeLang.ACTIONS.LOGIN_WITH_FB }}</span>
                </a>
                <a href="/login/google" class="google-plus">
                    <i class="fa fa-google-plus"></i>
                    <span>{{ localeLang.ACTIONS.LOGIN_WITH_GOOGLE }}</span>
                </a>
            </div>
            <span class="form-bottom">
                <a href="/login" class="join">{{ localeLang.ACTIONS.SIGN_IN }}</a>
            </span>
        </div>
    </div>
</template>
<script>
    import FormMixin from '../../mixins/FormMixin';
    import VueGoogleAutocomplete from '../VueGoogleAutocomplete'
    import registerDictionary from '../../locale/kh/registerDictionary';
    import VeeValidate, { Validator } from 'vee-validate';

    Vue.use(VeeValidate);

    export default {
        name:"auth-register-component",
        mixins: [FormMixin],
        components:{
            VueGoogleAutocomplete: VueGoogleAutocomplete
        },
        props:[
            "localeLang",
            "flashSuccess",
            "flashError"
        ],
        data() {
            return {
                loading : false,
                steps: 1,
                title: this.localeLang.TITLES.SIGN_UP,
                request: {
                    name: "",
                    phone:"",
                    email: "",
                    password: "",
                    role_id:"",
                    countryCode:"+855",
                    otp:"",
                    otpType: 1,
                    company_name:"",
                    address:"",
                    country:"",
                    state:"",
                    city:"",
                    latitude:"",
                    longitude:"",
                }
            }
        },
        mounted(){
            if(this.flashSuccess){
                flash(this.flashSuccess, 'success');
            }
            if(this.flashError){
                flash(this.flashError, 'error');
            }
            Vue.nextTick(function(){
                $(".password").on({
                    keydown: function (e) {
                        if (e.which === 32)
                            return false;
                    },
                    change: function () {
                        this.value = this.value.replace(/\s/g, "");
                    }
                });
            });
            if(this.localeName === 'kh'){
                Validator.localize('kh', registerDictionary);
            }
        },
        methods:{

            registerAccount() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/register', this.request).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                this.title = this.localeLang.TITLES.VERIFY_OTP
                                this.steps = 2;

                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            verifyOtp() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/verify-otp', {phone: this.request.phone,otp:this.request.otp,otpType:this.request.otpType}).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){

                                this.resetFormFields(this.request);
                                window.location = "/login";
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            resendOtp() {
                this.loading = true;
                axios.post('/resend-otp',
                        {phone: this.request.phone,otpType:this.request.otpType}
                    ).then(response => {
                    this.loading = false;
                    if(response.data.status === 'success'){
                        this.request.otp = "";
                        this.$validator.reset();
                    }
                    flash(response.data.message, response.data.status);
                }).catch(error => {
                    this.loading = false;
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },
            /**
            * When the location found
            * @param {Object} addressData Data of the found location
            * @param {Object} placeResultData PlaceResult object
            * @param {String} id Input container ID
            */
            getAddressData: function (data, placeResultData, id) {
                this.request.address = placeResultData.formatted_address;
                this.request.country = data.country;
                this.request.state = data.administrative_area_level_1;
                this.request.city = data.locality;
                this.request.latitude = data.latitude;
                this.request.longitude = data.longitude;
            }
        }
    }
</script>
