<template>
    <div>
        <div class="row subscription-plan-block" v-if="Object.keys(plans).length > 0">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-for="(plan, index) in plans">
                <div class="panel subs-plan-box" :class="{'ultimate-plan':(index == 0), 'premium-plan':(index === 1), 'standard-plan':(index === 2), 'lite-plan':(index === 3)}">
                    <div class="panel-heading">
                        <h3 class="panel-title">
                            {{ plan.title }}
                        </h3>
                    </div>
                    <div class="panel-body">
                        <table class="table">
                            <tbody>
                                <tr v-if="plan.total_listing_count">
                                    <td>
                                        {{ plan.total_listing_count }} 
                                        {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_IN_LISTING }}
                                    </td>
                                </tr>
                                <tr class="active" v-if="plan.total_featured_count">
                                    <td>
                                        {{ plan.total_featured_count }} 
                                        {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_IN_FEATURED }}
                                    </td>
                                </tr>
                                <tr v-if="plan.total_on_sale_count">
                                    <td>
                                        {{ plan.total_on_sale_count }} 
                                        {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_IN_ONSALE }}
                                    </td>
                                </tr>
                                <tr v-if="plan.total_until_sold_count">
                                    <td>
                                        {{ plan.total_until_sold_count }} 
                                        {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_IN_UNTILSOLD }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="panel-footer">
                        <div class="the-price">
                            <h2>{{ plan.price | toCurrency }}</h2>
                            <small v-if="plan.plan_interval == 'day'">
                                {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_A_DAY }}
                            </small>
                            <small v-else-if="plan.plan_interval == 'week'">
                                {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_A_WEEK }}
                            </small>
                            <small v-else-if="plan.plan_interval == 'month'">
                                {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_A_MONTH }}
                            </small>
                            <small v-else-if="plan.plan_interval == 'year'">
                                {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_AN_YEAR }}
                            </small>
                        </div>
                        <div>
                            <button type="button" class="submit-btn" disabled v-if="loading.id === plan.id">
                                <span class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                                <span>{{ localeLang.SUBSCIPTION_PLANS.ACTIONS.UPGRADE }}</span>
                            </button>
                            <button type="button" class="submit-btn" v-on:click="upgradePlan(plan.id)"  v-else>
                                {{ localeLang.SUBSCIPTION_PLANS.ACTIONS.UPGRADE }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                <div class="no-post-inr">
                    <div class="no-post-img">
                        <img src="/frontend/images/no-post-found.jpg" alt="">
                    </div>
                    <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                    <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import bootbox from "bootbox";
    import CommonMixin from '../../../mixins/CommonMixin';
    export default {
        name:"user-subscription-upgrade-plan-component",
        props:[
            "plans",
            "localeLang"
        ],
        mixins: [CommonMixin],
        data() {
            return {
                loading : {
                    id: false
                }
            }
        },
        methods:{
            /* upgrade plan */
            upgradePlan(planId){
                let vm = this;
                bootbox.confirm(vm.localeLang.MESSAGES.SURE_TO_UPGRADE, function(result){
                    if(result){
                        vm.loading.id = planId;
                        axios.get("/api/subscription/upgrade-plan/"+planId).then(response => {
                            vm.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === 'success'){
                                window.location = '/dashboard';
                            }
                        }).catch(error => {
                            vm.loading.id = false;
                            console.log(error);
                        });
                    }
                });
            },
        }
    }
</script>
<style scoped>
    .the-price h2{
        width: 100%;
    }
</style>
