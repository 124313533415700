<template>
    <form @submit.prevent="changePassword">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>{{ localeLang.OLD_PASSWORD_LABEL }}<span class="required">*</span></label>
                    <input type="password" class="form-control" name="old_password" :placeholder="localeLang.OLD_PASSWORD_PLACEHOLDER" :class="errors.has('old_password')?'is-invalid':''" v-model="request.old_password" v-validate="'required|min:8|max:12'" data-vv-as="old password" ref="old_password">
                    <div class="invalid-feedback" v-if="errors.has('old_password')">
                        {{ errors.first('old_password') }}
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label>{{ localeLang.NEW_PASSWORD_LABEL }}<span class="required">*</span></label>
                    <input type="password" class="form-control" name="new_password" :placeholder="localeLang.NEW_PASSWORD_PLACEHOLDER" :class="errors.has('new_password')?'is-invalid':''" v-model="request.new_password" v-validate="'required|min:8|max:12'" data-vv-as="new password" ref="new_password">
                    <div class="invalid-feedback" v-if="errors.has('new_password')">
                        {{ errors.first('new_password') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>{{ localeLang.CONFIRM_PASSWORD_LABEL }}<span class="required">*</span></label>
                    <input type="password" class="form-control" name="new_password_confirmation" :placeholder="localeLang.CONFIRM_PASSWORD_PLACEHOLDER" :class="errors.has('new_password_confirmation')?'is-invalid':''" v-model="request.new_password_confirmation" v-validate="'required|confirmed:new_password'" data-vv-as="confirm password">
                    <div class="invalid-feedback" v-if="errors.has('new_password_confirmation')">
                        {{ errors.first('new_password_confirmation') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-outer">
            <button type="button" class="submit-btn" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                <span>{{ localeLang.ACTION_TEXT }}</span>
            </button>
            <button type="submit" class="submit-btn blue-btn mr-2" :disabled="errors.any()" v-else>{{ localeLang.ACTION_TEXT }}</button>
        </div>
    </form>
</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    import FormMixin from '../../mixins/FormMixin';
    import passwordDictionary from '../../locale/kh/passwordDictionary';
    import VeeValidate, { Validator } from 'vee-validate';
    Vue.use(VeeValidate);
    export default {
        name:"user-password-component",
        props:[
            "localeName",
            "localeLang"
        ],
        mixins: [CommonMixin, FormMixin],
        data() {
            return {
                loading : false,
                request: {
                    old_password:"",
                    new_password: "",
                    new_password_confirmation: ""
                }
            }
        },
        mounted(){
            if(this.localeName === 'kh'){
                Validator.localize('kh', passwordDictionary);
            }
            let vm = this;
            Vue.nextTick(function(){
                window.$(vm.$refs.old_password, vm.$refs.new_password).on({
                    keydown: function (e) {
                        if (e.which === 32)
                            return false;
                    },
                    change: function () {
                        vm.request.old_password = this.value.replace(/\s/g, "");
                    }
                });
                window.$(vm.$refs.new_password).on({
                    keydown: function (e) {
                        if (e.which === 32)
                            return false;
                    },
                    change: function () {
                        vm.request.new_password = this.value.replace(/\s/g, "");
                    }
                });
            });
        },
        methods:{
            changePassword() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/change/password', this.request).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                this.resetFormFields(this.request);
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
        }
    }
</script>
