<template>
    <div class="chat-screen-outer" v-if="conversations.length > 0">
        <div class="chat-list">
            <div class="select-outer">
                <select class="form-control" ref="vehicleField">
                    <option value="">{{ localeLang.FILTER.LABEL }}</option>
                    <option v-for="(option, index) in vehicles" :value="option.id">{{ option.title }} </option>
                </select>
            </div>
            <div class="side-chat-list" v-on:scroll.passive="handleThreadScroll" ref="sideChatList">
                <div class="accordion-block">
                    <div class="card">
                        <div class="card-body">
                            <ul>
                                <li v-for="(conversation, key) in allConversations" :class="{'active':(activeConversation.id === conversation.id)}">
                                    <div class="cmn-chat-list">
                                        <div class="cmn-chat-img">
                                            <a href="javascript:;" v-on:click.prevent="changeConversationThread(key)">

                                                <img :src="(conversation.participants[0].profile_image) ? conversation.participants[0].avatar_full_path : avatar_default" alt="">
                                            </a>
                                        </div>
                                        <div class="cmn-chat-info">
                                            <a href="javascript:;" v-on:click.prevent="changeConversationThread(key)">
                                                {{ conversation.participants[0].name }}
                                                <strong>{{ conversation.messages | last_message  }}</strong>
                                                <strong>{{ conversation.messages | last_message_time }}</strong>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li v-show="loading.thread">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">{{ localeLang.LOADING }}...</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="chat-block">
            <div class="cmn-chat-list user-chat-info">
                <div class="cmn-chat-img">
                    <img :src="(activeConversation.participants[0].profile_image) ? activeConversation.participants[0].avatar_full_path : avatar_default" alt="">
                </div>
                <div class="cmn-chat-info">
                    {{ activeConversation.participants[0].name }}
                    <div class="ttl1">
                        <div class="ttl-name">
                            <a :href="'/vehicle/'+activeConversation.vehicle.slug" class="text-light" v-if="activeConversation.vehicle.title.length > 20">
                                {{ activeConversation.vehicle.title | str_limit(20) }}
                            </a>
                            <a :href="'/vehicle/'+activeConversation.vehicle.slug" class="text-light" v-else>
                                {{ activeConversation.vehicle.title }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-msg">
                <div v-if="loading.page">
                    <spinner-component></spinner-component>
                </div>
                <div v-else>
                    <div class="chat-inr" v-chat-scroll="{always: false, smooth: true}" v-if="allMessages.length > 0" v-on:scroll.passive="handleChatMessageScroll" ref="chatMessageList">
                        <div v-show="loading.chat">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">{{ localeLang.LOADING }}...</span>
                                </div>
                            </div>
                        </div>
                        <div class="chat-loop" :class="(message.user.id === userId) ? 'chat-right' : 'chat-left'" v-for="message in allMessages">
                            <div class="chat-img">
                                <img :src="(message.user.profile_image) ? message.user.avatar_full_path : avatar_default" alt="">
                            </div>
                            <div class="chat-dtl">
                                <div class="chat-name">{{ message.user.name }}</div>
                                <p>{{ message.content }}</p>
                                <div class="chat-time">
                                    {{ message.date_time }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-inr" v-else>
                        <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                            <p>{{ localeLang.NO_CHAT }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-type">
                <form @submit.prevent="sendMessage">
                    <textarea class="chat-area" :placeholder="localeLang.CHAT_INPUT_PLACEHOLDER+'...'" name="content" :class="errors.has('content')?'is-invalid':''" v-model="request.content" v-validate="'required'" data-vv-as="message"></textarea>
                    <div class="invalid-feedback" v-if="errors.has('content')">
                        {{ errors.first('content') }}
                    </div>
                    <button type="button" class="chat-send2" disabled v-if="loading.sending">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTION_TEXT }}</span>
                    </button>
                    <button type="submit" class="chat-send2" :disabled="errors.any()" v-else>{{ localeLang.ACTION_TEXT }}</button>
                </form>
            </div>
        </div>
    </div>
    <div class="chat-screen-outer" v-else>
        <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
            <div class="no-post-inr">
                <h2>{{ localeLang.NO_CONVERSATIONS.LINE1 }}</h2>
                <p>{{ localeLang.NO_CONVERSATIONS.LINE2 }}</p>
                <small>{{ localeLang.NO_CONVERSATIONS.LINE3 }}</small>
            </div>
        </div>
    </div>
</template>
<script>
    import VeeValidate from 'vee-validate';
    import VueChatScroll from 'vue-chat-scroll';
    import CommonMixin from '../../../mixins/CommonMixin';
    import SpinnerComponent from '../../../components/SpinnerComponent';

    export default {
        name:"user-conversation-component",
        props: [
            "conversations",
            "vehicles",
            "userId",
            "localeLang"
        ],
        mixins: [CommonMixin],
        components:{
            VueChatScroll: VueChatScroll,
            SpinnerComponent: SpinnerComponent,
            VeeValidate: VeeValidate
        },
        data() {
            return {
                allMessages: [],
                allConversations:[],
                activeConversation: [],
                activeVehicleId: "",
                pages:{
                    thread: 1,
                    chat:1
                },
                loading:{
                    page:false,
                    thread: false,
                    chat:false,
                    sending:false
                },
                request:{
                    conversation_id : "",
                    user_id : "",
                    content: ""
                }
            }
        },
        created(){
            if(this.conversations.length > 0){
                this.allConversations = this.conversations;
                this.setActiveConversation();
            }
        },
        mounted(){
            let vm = this;
            Vue.nextTick(() => {
                /* vehicle field */
                window.$(this.$refs.vehicleField).select2({
                    width:"100%",
                    minimumResultsForSearch: 10
                }).on("select2:select", function(e) {
                    vm.activeVehicleId = this.value;
                    vm.pages.thread = 1;
                    vm.getVehicleThreads(vm.activeVehicleId);
                });
            });
        },
        methods:{
            /* Set active conversation */
            setActiveConversation(){
                let vm = this;
                if(vm.allConversations){
                    vm.activeConversation = vm.allConversations[0];
                    vm.getMessages(vm.activeConversation.id);
                }
            },
            /* Get messages */
            async getMessages(conversationId){

                this.loading.page = true;
                axios.get('/api/conversation/'+conversationId+'/messages').then(response => {
                    this.allMessages = response.data.messages;
                    this.allMessages.reverse();
                    this.loading.page = false;
                }).catch(error => {
                    this.loading.page = false;
                    this.allMessages = [];
                    console.log(error);
                });
            },

            /* Send message */
            async sendMessage(){
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.loading.sending = true;
                        this.request.conversation_id = this.activeConversation.id;
                        this.request.user_id = this.userId;
                        axios.post('/api/conversation/send-message', this.request).then(response => {
                            this.loading.sending = false;
                            if(response.data.status === 'success'){
                                if(this.allMessages.length >= 20){
                                    Vue.set(this.allMessages, 19, response.data.message);
                                }else{
                                    this.allMessages.push(response.data.message);
                                }
                                this.request.content = "";
                                this.$validator.reset();
                            }else{
                                //this.allMessages = [];
                                flash(response.data.message, response.data.status);
                            }
                        }).catch(error => {
                            this.loading.sending = false;
                            //this.messages = [];
                            console.log(error);
                        });
                    }
                });
            },
            /* Get vehicle threads */
            async getVehicleThreads(vehicleId){
                if(vehicleId){
                    this.loading.page = true;
                    axios.get('/api/conversation/'+vehicleId+'/conversations').then(response => {
                        if(response.data.status === 'success'){
                            this.allConversations = response.data.conversations;
                            this.setActiveConversation();
                        }else{
                            flash(response.data.message, response.data.status);
                        }
                        this.loading.page = false;
                    }).catch(error => {
                        this.loading.page = false;
                        this.allConversations = [];
                        console.log(error);
                    });
                }else{
                    this.allConversations = this.conversations;
                    await this.setActiveConversation();
                }
            },
            /* Change conversation thread */
            async changeConversationThread(conversationIndex){
                let vm = this;
                vm.activeConversation = vm.allConversations[conversationIndex];
                console.log(vm.activeConversation);
                vm.getMessages(this.activeConversation.id);
            },
            /* Load more threads */
            async handleThreadScroll(){
                let vm = this;

                if(vm.loading.thread) return;

                if($(vm.$refs.sideChatList).scrollTop() + $(vm.$refs.sideChatList).innerHeight() >= $(vm.$refs.sideChatList)[0].scrollHeight) {
                    vm.loading.thread = true;
                    vm.pages.thread = (vm.pages.thread + 1);
                    let url = "";
                    if(vm.activeVehicleId){
                        url = '/api/conversation/'+vm.activeVehicleId+'/conversations/'+vm.pages.thread;
                    }else{
                        url = '/api/conversation/all-conversations/'+vm.pages.thread;
                    }
                    axios.get(url)
                    .then( function( response ){
                        if(response.data.status === 'success'){
                            if(Object.keys(response.data.conversations).length){
                                vm.apendConversations(response.data.conversations);
                            }else{
                                vm.pages.thread = (vm.pages.thread - 1);
                            }
                        }
                        vm.loading.thread = false;
                    }).catch(error => {
                        vm.loading.thread = false;
                        vm.pages.thread = (vm.pages.thread - 1);
                        console.log(error);
                    });
                }
            },
            /* Load more messages */
            async handleChatMessageScroll(){
                let vm = this;
                if(vm.loading.chat) return;
                if($(vm.$refs.chatMessageList).scrollTop() == 0) {
                    vm.loading.chat = true;
                    vm.pages.chat = (vm.pages.chat + 1);
                    axios.get('/api/conversation/'+vm.activeConversation.id+'/messages/'+vm.pages.chat)
                    .then( function( response ){
                        if(response.data.status === 'success'){
                            if(Object.keys(response.data.messages).length){
                                vm.prependMessages(response.data.messages);
                            }
                        }else{
                            vm.pages.chat = (vm.pages.chat - 1);
                        }
                        vm.loading.chat = false;
                    }).catch(error => {
                        vm.loading.chat = false;
                        vm.pages.chat = (vm.pages.chat - 1);
                        console.log(error);
                    });
                }
            },
            /* prepend messages */
            prependMessages(allmessages){
                let messages = allmessages.reverse();
                for (let i = 0; i < Object.keys(messages).length; i++) {
                    let index = Object.keys(messages)[i];
                    this.allMessages.unshift(messages[index]);
                }
            },
            /* apend conversations */
            apendConversations(conversations){
                for (let i = 0; i < Object.keys(conversations).length; i++) {
                    let index = Object.keys(conversations)[i];
                    this.allConversations.push(conversations[index]);
                }
            }
        },
        filters:{
            last_message(messages){
                if(Object.keys(messages).length){
                    return messages[0].content;
                }
                return '';
            },
            last_message_time(messages){
                if(Object.keys(messages).length){
                    return messages[0].human_time;
                }
                return '';
            }
        },
        destroyed: function () {
            window.$(this.$refs.vehicleField).off().select2('destroy');
        }
    }
</script>
