import moment from "moment";
export default {
    data() {
        return {
            initialMaskingDigits:[
                {id:1, name:1},
                {id:2, name:2},
                {id:3, name:3}
            ],
            models:[],
            badges:[],
            series:[]
        }
    },
    mounted() {
        let vm = this;
        Vue.nextTick(function(){
            /* Progress bar */
            window.$(".progress").each(function() {
                var value   = window.$(this).attr('data-value');
                var left    = window.$(this).find('.progress-left .progress-bar');
                var right   = window.$(this).find('.progress-right .progress-bar');
                if (value > 0) {
                    if (value <= 50) {
                        right.css('transform', 'rotate(' + vm.percentageToDegrees(value) + 'deg)')
                    } else {
                        right.css('transform', 'rotate(180deg)')
                        left.css('transform', 'rotate(' + vm.percentageToDegrees(value - 50) + 'deg)')
                    }
                }
            });

        }.bind(vm));
    },
    methods: {
        /* Get vehicle models by make id */
        async getModelsByMakeId(makeId){
            let url = '/api/vehicle/make/'+makeId+'/models-by-id';
            axios.get(url).then(response => {
                this.models = response.data.data;
                this.loading = false;
            }).catch(error => {
                this.models = [];
                flash('Somthing went wrong. Please try again.', 'error');
                this.loading = false;
                console.log(error);
            });
        },
        /* Get vehicle badges by model id */
        async getBadgesByModelId(modelId){
            let url = '/api/vehicle/model/'+modelId+'/badges-by-id';
            axios.get(url).then(response => {
                this.badges = response.data.data;
                this.loading = false;
            }).catch(error => {
                this.badges = [];
                flash('Somthing went wrong. Please try again.', 'error');
                this.loading = false;
                console.log(error);
            });
        },

        /* Get vehicle series by badge id */
        async getSeriesByBadgeId(badgeId){
            let url = '/api/vehicle/badge/'+badgeId+'/series-by-id';
            axios.get(url).then(response => {
                this.series = response.data.data;
                this.loading = false;
            }).catch(error => {
                this.series = [];
                flash('Somthing went wrong. Please try again.', 'error');
                this.loading = false;
                console.log(error);
            });
        },
        /* Get key name form object */
        getValueFormObjectById(arrObj, searchKey, getKey){
            let item = arrObj.filter(item => item.id == searchKey);
            if(item.hasOwnProperty(0)){
                if(item[0].hasOwnProperty(getKey)){
                    return item[0][getKey];
                }
            }
            return "";
        },
        /* Get years as array object */
        yearsRange(startYear,next=0){
            let currentYear = new Date().getFullYear() + next;
            let years = [];

            startYear = startYear || 1989;
            while ( startYear <= currentYear ) {
                years.push({
                    id: startYear,
                    name: startYear
                });
                startYear++;
            }
            return years.reverse();
        },
        /* Get years from to as array object */
        expiryYearsRange(){
            let currentYear = new Date().getFullYear();
            let endYear = (currentYear+10)
            let years = [];
            while ( endYear >= currentYear ) {
                years.push({
                    id: currentYear,
                    name: currentYear
                });
                currentYear++;
            }
            return years;
        },
        /* Get numbers as array object */
        numberRange(start, end){
            let numbers = [];

            while ( start <= end ) {
                numbers.push({
                    id: start,
                    name: start
                });
                start++;
            }
            return numbers;
        },
        /* Progressbar percentage to degree */
        percentageToDegrees(percentage) {
            return percentage / 100 * 360;
        }
    }
}
