<template>
    <div>
        <li class="d-flex align-items-center">
            <p class="show-map">
            <a href="javascript:;" v-on:click.prevent="showModalForMap()">
                {{ localeLang.TITLE }}
            </a>
            </p>
        </li>
        <!-- Custom Popup -->
        <div class="modal fade custom-popup distance-from-me-modal" id="distance-from-me-modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <button data-dismiss="modal" class="close" type="button">×</button>        
                        <h2>{{ localeLang.TITLE }}</h2>
                        <div ref="route_map" id="route_map" style="height:500px;">
                            <spinner-component></spinner-component>
                        </div>
                    </div>	
                </div>
            </div>
        </div>
        <!-- Custom Popup -->
    </div>
</template>
<script>
    import SpinnerComponent from './../SpinnerComponent';
    export default {
        name:"vehicle-distance-from-me",
        props:[
            "vehicleLatitude",
            "vehicleLongitude" ,
            "localeLang",
        ],
        components:{
            SpinnerComponent
        },
        methods:{ 
            showModalForMap(vehicleId) {
                let vm = this;
                window.$('.distance-from-me-modal').modal({
                    keyboard: false,
                    backdrop: 'static'
                }).on('shown.bs.modal', function (e) {

                    google.maps.event.addDomListener(window, "load", vm.loadLocationRoute());

                }).on('hidden.bs.modal', function (e) {
                });
            },
            loadLocationRoute(){
                var map, markerA, markerB;
                let vm = this;
                // If the browser supports the Geolocation API
                if (typeof navigator.geolocation == "undefined") {
                    flash("Your browser doesn't support the Geolocation API", "error");
                    window.$('.distance-from-me-modal').modal('hide');
                    return;
                }
                let path = [];
                if (navigator.geolocation) {
                    //navigator.geolocation.watchPosition(function(position) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        // Save the origin position
                        // Create the map
                        var myOptions = {
                            zoom : 14,
                            center :new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
                            mapTypeId : google.maps.MapTypeId.ROADMAP
                        }
                        var map = new google.maps.Map(document.getElementById("route_map"), myOptions);
                    
                        var directionsService = new google.maps.DirectionsService();
                        var directionsRequest = {
                            origin:new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
                            destination:new google.maps.LatLng(vm.vehicleLatitude, vm.vehicleLongitude),
                            travelMode: google.maps.DirectionsTravelMode.DRIVING,
                            unitSystem: google.maps.UnitSystem.METRIC
                        };
                        directionsService.route(
                            directionsRequest,
                            function(response, status)
                            {
                                if (status == google.maps.DirectionsStatus.OK)
                                {
                                    new google.maps.DirectionsRenderer({
                                        map: map,
                                        directions: response
                                    });
                                }
                                else{
                                    flash("Sorry unable to retrieve your route", "error");
                                }
                            }
                        );
                        
                    },
                    function(positionError){
                        flash(positionError.message, "error");
                        window.$('.distance-from-me-modal').modal('hide');
                    });
                }
            }
        }
    }
</script>
