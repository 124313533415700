<template>
    <div>
        <star-rating 
            :rating="rating" 
            :star-size="16"
            :show-rating="false" 
            :read-only="true"
            :round-start-rating="false"
        ></star-rating>
        <p>{{ localLang.BASED_ON }} {{ reviewsCount }} {{ localLang.TITLE }}</p>
    </div>
</template>
<script>
    import StarRating from 'vue-star-rating'
    export default {
        name:"vehicle-compare-rating-component",
        props:[
            "rating",
            "localLang",
            "reviewsCount"
        ],
        components:{
            StarRating:StarRating
        }
    }
</script>
