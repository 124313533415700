<template>
    <!--Our Newsletter Section-->
    <div class="newsletter-section">
        <div class="container d-flex justify-content-between align-items-center flex-wrap">
            <div class="subs-hd">{{ localeLang.TITLE }}</div>
            <div class="subs-form">
                <form @submit.prevent="subscribeToNewsLetter">
                    <input type="text" :placeholder="localeLang.PLACEHOLDER" class="form-control" :class="(errors.has('email') || message)?'is-invalid':''" name="email" v-model="request.email"  v-validate="'required|email|max:100'" data-vv-as="Email" key="email">
                    <div class="invalid-feedback" v-if="errors.has('email')">
                        {{ errors.first('email')  }}
                    </div>
                    <div class="invalid-feedback" v-else-if="message.length > 0" style="color: #28a745">
                        {{ message }}
                    </div>
                    <button type="button" class="subs-btn" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTION }}</span>
                    </button>
                    <button class="subs-btn" type="submit" :disabled="errors.any()" v-else>{{ localeLang.ACTION }}</button>
                </form>
            </div>
        </div>
    </div>
    <!--Our Newsletter Section-->
</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    import FormMixin from '../../mixins/FormMixin';
    import newsletterDictionary from '../../locale/kh/newsletterDictionary';
    import VeeValidate, { Validator } from 'vee-validate';

    Vue.use(VeeValidate);
    export default {
        name:"home-newsletter-component",
        props:[
            "localeName",
            "localeLang"
        ],
        mixins: [CommonMixin, FormMixin],
        data() {
            return {
                loading : false,
                message: '',
                request: {
                    email: "",
                }
            }
        },
        mounted(){
            if(this.localeName === 'kh'){
                Validator.localize('kh', newsletterDictionary);
            }
        },
        methods:{
            subscribeToNewsLetter() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('api/subscribe-to-news-letter', this.request).then(response => {
                            this.message = response.data.message;
                            if(response.data.status === "success"){
                               this.resetFormFields(this.request);
                            }
                            this.loading = false;
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }
                            console.log(error);
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },            
        }
    }
</script>
