export default {
    name: 'kh',
    custom: {
        sale_price: {
            required: 'តំរូវអោយបំពេញតំលៃដាក់លក់',
            numeric: 'បំពេញតំលៃដាក់លក់អាចជាតួលេខប៉ុណ្ណោះ',
            min: 'បំពេញតំលៃដាក់លក់យ៉ាងតិចមាន3តួលេខ',
            max: 'បំពេញតំលៃដាក់លក់មិនអាចលើសពី7តួលេខ',
            min_value: 'បំពេញតំលៃដាក់លក់ត្រូវលើសពីតំលៃដែលបញ្ចុះ'
        },
        discounted_price: {
            numeric: 'បំពេញតំលៃដែលបញ្ចុះអាចជាតួលេខប៉ុណ្ណោះ',
            min: 'បំពេញតំលៃដែលបញ្ចុះយ៉ាងតិចមាន3តួលេខ',
            max: 'បំពេញតំលៃដែលបញ្ចុះមិនអាចលើសពី7តួលេខ',
            max_value: 'បំពេញតំលៃដែលបញ្ចុះមិនត្រូវលើសតំលៃដាក់លក់'
        }
    }
};