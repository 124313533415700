<template>
    <div class="user-img" v-if="loading">
        <spinner-component></spinner-component>
    </div>
    <div class="user-img" v-else> 
        <img :src="baseUrl+'/timthumb.php?src='+profile_image+'&w=85&h=85&zc=0'" alt="">
        <label class="edit-btn"> 
            <i class="fa fa-pencil"></i>
            <input type="file" class="btn" name="avatar" v-validate="'image|ext:jpeg,jpg,png'" data-vv-as="profile picture"  v-on:change="changeAvatar()" ref="avatar">
        </label>
    </div>
</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    import FormMixin from '../../mixins/FormMixin';
    import SpinnerComponent from './../SpinnerComponent';
    import VeeValidate from 'vee-validate';
    Vue.use(VeeValidate);

    export default {
        name:"user-profile-picture-component",
        mixins: [CommonMixin, FormMixin],
        components:{
            SpinnerComponent
        },
        props:[
            "errorMessage", 
            "avatar"
        ],
        data() {
            return {
                loading : false,
                profile_image : "",
                request: {
                    avatar: null
                }
            }
        },
        mounted(){
            if(this.avatar){
                this.profile_image = this.avatar;
            }else{
                this.profile_image = this.avatar_default;
            }
        },
        methods:{
            /*
                Handles a change on the file upload
            */
            changeAvatar(){
                this.loading = true;
                this.request.avatar = this.$refs.avatar.files[0];
                this.$validator.validate().then(valid => {
                    if (valid) {
                        let formData = new FormData();
                        if(this.request.avatar){
                            formData.append('avatar', this.request.avatar);
                        }
                        axios.post("/profile/change-avatar", formData, {
                            headers:{
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            this.loading = false;
                            this.profile_image = response.data.avatar;
                            flash(response.data.message, response.data.status);
                        }).catch(responseError => {
                            this.loading = false;
                            if(responseError.hasOwnProperty('response')){
                                // check if errors exist
                                if(responseError.response.data.hasOwnProperty('errors')) {
                                    flash(responseError.response.data.errors.avatar[0], "error");
                                }
                            }else{
                                flash(this.errorMessage, "error");
                                console.log(responseError);
                            }
                        });
                    }else{
                        this.loading = false;
                        flash(this.errors.first('avatar'), "error");
                    }
                });
            }
        }
    }
</script>
<style scoped>
    .spinner > div {
        color:#d8322d !important
    }
</style>
