<template>

    <social-sharing
        :url="dealerDeeplink"
        :title="dealerName"
        :description="dealerDescription"
        quote="S.P Car Market"
        twitter-user="carmarket"
        inline-template>
        <ul class="share-links">


            <li>
                <network network="facebook">
                    <a href="javascript:;">
                       <i class="fa fa-facebook-f"></i>
                    </a>
                </network>
            </li>
            <li>
                <network network="twitter">
                    <a href="javascript:;">
                       <i class="fa fa-twitter"></i>
                    </a>
                </network>
            </li>
            <li>
                <network network="whatsapp">
                    <a href="javascript:;">
                        <i class="fa fa-whatsapp"></i>
                    </a>
                </network>
            </li>

 <li>

                        <a href="javascript:void(0)" title="Copy link" onclick="copyToClipboard('#texttocopy')">
                            <i class="fa fa-copy"></i>
                        </a>
 <div class="showcopymsg"></div>
                </li>
        </ul>
    </social-sharing>


</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    import SocialSharing from 'vue-social-sharing';
    export default {
        name:"dealer-social-share-component",
        props:[
            'dealerDeeplink',
            "dealerId",
            "dealerName",
            "dealerDescription",
            "dealerSharerTitle",
            "dealerShareWith",
        ],
        components:{
            SocialSharing: SocialSharing
        },
        mixins: [CommonMixin],

    }
</script>
