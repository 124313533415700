<template>
    <div class="create-ads-inner global-form create-car-ads-form">
        <div class="row">
            <div class="col-12">
                <ul class="add-steps d-flex">
                    <li :class="(steps >= 1) ? 'complete': ''">
                        <a :href="(steps >= 1) ? '/vehicle/'+vehicle.slug+'/general' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-car"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.GENERAL_INFO }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 2) ? 'complete': ''">
                        <a :href="(steps >= 2) ? '/vehicle/'+vehicle.slug+'/features' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-list"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.SELECT_FEATURES }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 3) ? 'complete': ''">
                        <a :href="(steps >= 3) ? '/vehicle/'+vehicle.slug+'/details' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-user-plus"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ADD_DETAILS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 4) ? 'complete': ''">
                        <a :href="(steps >= 4) ? '/vehicle/'+vehicle.slug+'/photos' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-camera-retro"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ATTACH_PHOTOS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 5) ? 'complete': ''">
                        <a :href="(steps >= 5) ? '/vehicle/'+vehicle.slug+'/preview' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-shopping-basket"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.REVIEW_COMPLETE }}</h3>
                        </a>
                    </li>
                </ul>
                <div class="progress mx-auto" :data-value="progress"> 
                    <span class="progress-left"> 
                        <span class="progress-bar border-primary"></span> 
                    </span> 
                    <span class="progress-right"> 
                        <span class="progress-bar border-primary"></span> 
                    </span>
                    <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="value-text">{{ progress }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-block-outer">
            <div class="step-box1 step-3">
                <div class="row">
                    <div class="col-12">
                        <div class="car-find-sub-hd"> 
                            {{ localeLang.DETAILS.SUB_TITLE }} {{ vehicle.title }} 
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.LOCATION_ADDRESS }}
                                <span class="required">*</span>
                            </label>
                            <vue-google-autocomplete
                                ref="addressField"
                                name="address"
                                id="map"
                                classname="form-control"
                                :placeholder="localeLang.DETAILS.FIELDS.LOCATION_ADDRESS"
                                v-on:placechanged="getAddressData"
                                :country="['in', 'kh']"
                                :class="errors.has('address')?'is-invalid':''"  
                                v-model="request.address" 
                                v-validate="'required|max:100'" 
                                data-vv-as="address"
                            >
                            </vue-google-autocomplete>
                            <div class="invalid-feedback" v-if="errors.has('address')">
                                {{ errors.first('address') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.ODOMETER }}
                                <span class="required">*</span>
                            </label>
                            <input type="text" class="form-control" :placeholder="localeLang.DETAILS.FIELDS.ODOMETER" :class="errors.has('odometer')?'is-invalid':''" name="odometer" v-model="request.odometer" v-validate="'required|decimal:2|max:10'" data-vv-as="odometer" > 
                            <div class="invalid-feedback" v-if="errors.has('odometer')">
                                {{ errors.first('odometer') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.INTERIOR_COLOR }}<span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="interior_color_id"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('interior_color_id') ? errors.first('interior_color_id') : ''"
                                :options="colors"
                                :default-option="vehicle ? vehicle.interior_colour_id : ''"
                                v-validate="'required'" 
                                data-vv-as="interior color" 
                                v-model="request.interior_color_id"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.EXTERIOR_COLOR }}<span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="exterior_color_id"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('exterior_color_id') ? errors.first('exterior_color_id') : ''"
                                :options="colors"
                                :default-option="vehicle ? vehicle.exterior_colour_id : ''"
                                v-validate="'required'" 
                                data-vv-as="exterior color" 
                                v-model="request.exterior_color_id"
                            ></field-select2-component>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.EXPIRY_MONTH.TITLE }}
                            </label>
                            <field-select2-component
                                name="registration_expiry_month"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :options="localeLang.DETAILS.FIELDS.EXPIRY_MONTH.OPTIONS"
                                :default-option="vehicle ? vehicle.expiry_month : ''"
                                v-model="request.registration_expiry_month"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.EXPIRY_YEAR }}
                            </label>
                            <field-select2-component
                                name="registration_expiry_year"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :options="expiryYearsRange()"
                                :default-option="vehicle ? vehicle.expiry_year : ''"
                                v-model="request.registration_expiry_year"
                            ></field-select2-component>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.LIFESTYLE }}<span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="lifestyle_id"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('lifestyle_id') ? errors.first('lifestyle_id') : ''"
                                :options="lifestyles"
                                :default-option="vehicle ? vehicle.lifestyle_id : ''"
                                v-validate="'required'" 
                                data-vv-as="lifestyle" 
                                v-model="request.lifestyle_id"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.PLATE_NUMBER }}
                            </label>
                            <input type="text" class="form-control" :placeholder="localeLang.DETAILS.FIELDS.PLATE_NUMBER" :class="errors.has('registration_plate_number')?'is-invalid':''" name="registration_plate_number" v-model="request.registration_plate_number" v-validate="'max:9'" data-vv-as="registration plate number" > 
                            <div class="invalid-feedback" v-if="errors.has('registration_plate_number')">
                                {{ errors.first('registration_plate_number') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.FUEL_ECONOMY.TITLE }}<span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="fuel_economy_id"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('fuel_economy_id') ? errors.first('fuel_economy_id') : ''"
                                :options="localeLang.DETAILS.FIELDS.FUEL_ECONOMY.OPTIONS"
                                :default-option="vehicle ? vehicle.fuel_economy_id : ''"
                                v-validate="'required'" 
                                data-vv-as="fuel economy" 
                                v-model="request.fuel_economy_id"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.PHONE_NUMBER }}
                                <span class="required">*</span>
                            </label>
                            <input type="text" class="form-control" :placeholder="localeLang.DETAILS.FIELDS.PHONE_NUMBER" :class="errors.has('contact_phone_number')?'is-invalid':''" name="contact_phone_number" v-model="request.contact_phone_number" v-validate="'required|numeric|min:9|max:15'"  data-vv-as="contact phone number" >
                            <div class="invalid-feedback" v-if="errors.has('contact_phone_number')">
                                {{ errors.first('contact_phone_number') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.COMMENT_ENGLISH }}
                            </label>
                            <textarea class="form-control" :placeholder="localeLang.DETAILS.FIELDS.COMMENT_ENGLISH" :class="errors.has('english_comment')?'is-invalid':''" name="english_comment" v-model="request.english_comment" v-validate="'max:500'" data-vv-as="english comment" ></textarea> 
                            <div class="invalid-feedback" v-if="errors.has('english_comment')">
                                {{ errors.first('english_comment') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.COMMENT_KHMER }}
                            </label>
                            <textarea class="form-control" :placeholder="localeLang.DETAILS.FIELDS.COMMENT_KHMER" :class="errors.has('khmer_comment')?'is-invalid':''" name="khmer_comment" v-model="request.khmer_comment" v-validate="'max:500'" data-vv-as="khmer comment" ></textarea> 
                            <div class="invalid-feedback" v-if="errors.has('khmer_comment')">
                                {{ errors.first('khmer_comment') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.SALE_PRICE }}<span class="required">*</span>
                            </label>
                            <input type="text" class="form-control" :placeholder="localeLang.DETAILS.FIELDS.SALE_PRICE" :class="errors.has('sale_price')?'is-invalid':''" name="sale_price" v-model="request.sale_price" v-validate="{ required: true, numeric: true, min:3, max:7, min_value: (request.discounted_price != null) ? request.discounted_price : false, max_value: (maxSalePrice) ? maxSalePrice : false}" data-vv-as="sale price"> 
                            <small v-if="maxSalePrice">{{ localeLang.DETAILS.FIELDS.SALE_PRICE_NOTE }} ${{ maxSalePrice }}</small>
                            <div class="invalid-feedback" v-if="errors.has('sale_price')">
                                {{ errors.first('sale_price') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6" v-if="isDealer">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.DISCOUNT_PRICE }}
                            </label>
                            <input type="text" class="form-control" :placeholder="localeLang.DETAILS.FIELDS.DISCOUNT_PRICE" :class="errors.has('discounted_price')?'is-invalid':''" name="discounted_price" v-model="request.discounted_price" v-validate="{ numeric: true, min:3, max:7, max_value: (request.sale_price) ? request.sale_price : false}" data-vv-as="discounted price" > 
                            <div class="invalid-feedback" v-if="errors.has('discounted_price')">
                                {{ errors.first('discounted_price') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <label class="d-block">
                            {{ localeLang.DETAILS.FIELDS.IS_MASKING_ENABLED.TITLE }}
                        </label>
                        <div class="custom-radio radio-inline form-group">
                            <div class="radio-inner">
                                <input type="radio" id="is_masked_price_1" name="is_masked_price" value="1" v-model="request.is_masked_price" checked>
                                <label for="is_masked_price_1">{{ localeLang.DETAILS.FIELDS.IS_MASKING_ENABLED.OPTIONS.YES }}</label>
                            </div>
                            <div class="radio-inner">
                                <input type="radio" id="is_masked_price_2" name="is_masked_price" value="0" v-model="request.is_masked_price">
                                <label for="is_masked_price_2">{{ localeLang.DETAILS.FIELDS.IS_MASKING_ENABLED.OPTIONS.NO }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6" v-if="request.is_masked_price == 1">
                        <div class="form-group">
                            <label>
                                {{ localeLang.DETAILS.FIELDS.INITIAL_MASKING }}<span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="initial_masking_digits"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('initial_masking_digits') ? errors.first('initial_masking_digits') : ''"
                                :options="initialMaskingDigits"
                                :default-option="vehicle ? vehicle.initial_masking_digits : ''"
                                v-validate="'required'" 
                                data-vv-as="initial digits without masking" 
                                v-model="request.initial_masking_digits"
                            ></field-select2-component>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="d-block">{{ localeLang.DETAILS.FIELDS.IS_WRITTEN_OF.TITLE }}</label>
                        <div class="custom-radio radio-inline form-group">
                            <div class="radio-inner">
                                <input type="radio" id="written-off1" name="is_written_off" value="1" v-model="request.is_written_off" checked>
                                <label for="written-off1">{{ localeLang.DETAILS.FIELDS.IS_WRITTEN_OF.OPTIONS.YES }}</label>
                            </div>
                            <div class="radio-inner">
                                <input type="radio" id="written-off2" name="is_written_off" value="0" v-model="request.is_written_off">
                                <label for="written-off2">{{ localeLang.DETAILS.FIELDS.IS_WRITTEN_OF.OPTIONS.NO }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="d-block">{{ localeLang.DETAILS.FIELDS.IS_REGISTERED.TITLE }}</label>
                        <div class="custom-radio radio-inline form-group">
                            <div class="radio-inner">
                                <input type="radio" id="car_registered_1" name="is_car_registered" value="1" v-model="request.is_car_registered" checked>
                                <label for="car_registered_1">{{ localeLang.DETAILS.FIELDS.IS_REGISTERED.OPTIONS.YES }}</label>
                            </div>
                            <div class="radio-inner">
                                <input type="radio" id="car_registered_2" name="is_car_registered" value="0" v-model="request.is_car_registered">
                                <label for="car_registered_2">{{ localeLang.DETAILS.FIELDS.IS_REGISTERED.OPTIONS.NO }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-outer text-right">
            <a :href="'/vehicle/'+vehicle.slug+'/features'" class="submit-btn pull-left blue-btn">{{ localeLang.ACTIONS.PREV }}</a>
            <button type="button" class="submit-btn" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                <span>{{ localeLang.ACTIONS.NEXT }}</span>
            </button>
            <button type="button" class="submit-btn" v-on:click="saveDetails()" :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.NEXT }}</button>
        </div>
    </div>
</template>
<script>
    import CommonMixin from '../../../mixins/CommonMixin';
    import VehicleFormMixin from '../../../mixins/VehicleFormMixin';
    import FormMixin from '../../../mixins/FormMixin';
    import FieldSelect2Component from '../../FieldSelect2Component';
    import VueGoogleAutocomplete from '../../VueGoogleAutocomplete' 
    import mangeAdDetailsDictionary from '../../../locale/kh/mangeAdDetailsDictionary';
    import VeeValidate, { Validator } from 'vee-validate';
    
    Vue.use(VeeValidate);

    export default {
        name:"user-manage-ad-details-component",
        props:[ 
            "vehicle",
            "colors",
            "lifestyles",
            "maxSalePrice",
            "isDealer", 
            "localeName",
            "localeLang"
        ],
        components:{
            FieldSelect2Component: FieldSelect2Component,
            VueGoogleAutocomplete: VueGoogleAutocomplete
        },
        mixins: [CommonMixin, VehicleFormMixin, FormMixin],
        data() {
            return {
                loading : false,
                steps: 2,
                progress: 40,
                request: {
                    vehicle_slug: this.vehicle.slug,
                    address:"",
                    country:"",
                    state:"",
                    city:"",
                    postcode:"",
                    latitude:"",
                    longitude:"",
                    odometer:"",
                    sale_price:"",
                    discounted_price:"",
                    interior_color_id:"",
                    exterior_color_id:"",
                    registration_expiry_month:"",
                    registration_expiry_year:"",
                    lifestyle_id:"",
                    registration_plate_number:"",
                    fuel_economy_id:"",
                    contact_phone_number:"",
                    english_comment:"",
                    khmer_comment:"",
                    is_written_off:1,
                    is_car_registered:1,
                    is_masked_price: 1,
                    initial_masking_digits:null
                }
            }
        },
        created() {
            if(this.vehicle){
                this.steps = this.vehicle.steps;
                this.progress = (this.vehicle.steps * 20);
                this.request.address = this.vehicle.address ? this.vehicle.address : '';
                this.request.country = this.vehicle.country  ? this.vehicle.country : '';
                this.request.state = this.vehicle.state  ? this.vehicle.state : '';
                this.request.city = this.vehicle.city  ? this.vehicle.city : '';
                this.request.postcode = this.vehicle.postcode  ? this.vehicle.postcode : '';
                this.request.latitude = this.vehicle.latitude  ? this.vehicle.latitude : '';
                this.request.longitude = this.vehicle.longitude  ? this.vehicle.longitude : '';
                this.request.odometer = this.vehicle.odometer  ? this.vehicle.odometer : '';
                this.request.sale_price = this.vehicle.price  ? this.vehicle.price : '';
                this.request.discounted_price = this.vehicle.discount_price  ? this.vehicle.discount_price : '';
                this.request.registration_plate_number = this.vehicle.plate_number  ? this.vehicle.plate_number : '';
                this.request.contact_phone_number = this.vehicle.phone  ? this.vehicle.phone : '';
                if(this.vehicle.hasOwnProperty('translations')){
                    if(this.vehicle.translations.hasOwnProperty(0)){
                        this.request.english_comment = this.vehicle.translations[0].description;
                    }
                    if(this.vehicle.translations.hasOwnProperty(1)){
                        this.request.khmer_comment = this.vehicle.translations[1].description;
                    }
                }
                if(this.vehicle.written_off != null){
                    this.request.is_written_off = this.vehicle.written_off;
                }
                if(this.vehicle.is_registered != null){
                    this.request.is_car_registered = this.vehicle.is_registered;
                }
                if(this.vehicle.is_masked_price != null){
                    this.request.is_masked_price = this.vehicle.is_masked_price;
                    if(this.vehicle.is_masked_price){
                        this.request.initial_masking_digits = this.vehicle.initial_masking_digits;
                    }
                }
            }
        },
        mounted(){
            if(this.localeName === 'kh'){
                Validator.localize('kh', mangeAdDetailsDictionary);
            }
        },
        methods: {
            saveDetails() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/api/vehicle/manage/details', this.request).then(response => {
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                window.location = "/vehicle/"+response.data.slug+"/photos"
                            }else{
                                this.loading = false;
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },

            /**
            * When the location found
            * @param {Object} addressData Data of the found location
            * @param {Object} placeResultData PlaceResult object
            * @param {String} id Input container ID
            */
            getAddressData: function (data, placeResultData, id) {
                this.request.address = placeResultData.formatted_address;
                this.request.country = data.country;
                this.request.state = data.administrative_area_level_1;
                this.request.city = data.locality;
                this.request.postcode = data.postal_code;
                this.request.latitude = data.latitude;
                this.request.longitude = data.longitude;
            }
        }
    }
</script>
