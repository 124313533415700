<template>
    <div class="comment-row" v-if="vehicle.description">
        <div class="comment-heading">{{ localeLang.TITLE }}</div>
        <p>
            {{ vehicle.description.substr(0, 400) }}
            <span v-show="(vehicle.description.length > 400) && (showMore === false)">...</span>
            <span v-show="showMore === true">{{ vehicle.description.substr(401) }}</span>
        </p>
        <div class="btn-outer" v-show="(vehicle.description.length > 400) && (showMore === false)">
            <a href="javascript:;" class="btn-custom" v-on:click="showMore = true"> 
                {{ localeLang.READ_MORE }} <i class="fa fa-angle-double-right"></i> 
            </a>
        </div>
        <div class="btn-outer" v-show="showMore === true">
            <a href="javascript:;" class="btn-custom" v-on:click="showMore = false"> 
                {{ localeLang.READ_LESS }} <i class="fa fa-angle-double-left"></i> 
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        name:"vehicle-comment-component",
        props:[
            "vehicle",
            "localeLang"
        ],
        data() {
            return {
                showMore:false
            }
        }
    }
</script>
