<template>
    <div class="row">
        <div class="col-md-4">
            <div class="image-container position-relative text-center" v-if="!images.length">
                <div class="drag-upload-cover position-absolute" v-if="isDragover" @drop="onDrop">
                    <div class="centered full-width text-center text-primary">
                        <i class="icon-move"></i>
                        <h4 class="drop-text-here"><b>{{ dropText }}</b></h4>
                    </div>
                </div>
                <div v-else class="image-center position-absolute display-flex flex-column justify-content-center align-items-center" @dragover.prevent="onDragover">
                    <i class="fa fa-camera"></i>
                    <div class="text-center">
                        <label class="drag-text">{{ dragText }}</label>
                        <br>
                        <a class="browse-text">{{ browseText }}</a>
                    </div>
                    <div class="image-input position-absolute full-width full-height">
                        <label :for="idUpload" class="full-width full-height cursor-pointer"></label>
                    </div>
                </div>
            </div>
            <div class="image-container position-relative text-center image-list" v-else>
                <div class="preview-image full-width position-relative cursor-pointer" @click="openGallery(currentIndexImage)">
                    <div class="image-overlay position-relative full-width full-height"></div>
                    <div class="image-overlay-details full-width">
                        <i class="fa fa-search"></i>
                    </div>
                    <div class="show-image centered">
                        <img class="show-img img-responsive img" :src="imagePreview">
                    </div>
                </div>
                <div class="image-bottom display-flex position-absolute full-width align-items-center justify-content-between" :class="!showPrimary && 'justify-content-end'">
                    <div class="image-bottom-left display-flex align-items-center" v-if="showPrimary">
                        <div class="display-flex align-items-center" v-show="imageDefault">
                            <span class="image-primary display-flex align-items-center">
                                <i class="fa fa-check"></i>
                                {{ primaryText }}
                            </span>
                            <popper trigger="click" :options="{placement: 'top'}">
                                <div class="popper popper-custom">
                                    {{ popupText }}
                                </div>
                                <i slot="reference" class="fa fa-info"></i>
                            </popper>
                        </div>
                        <a @click.prevent="markIsPrimary(currentIndexImage)" v-show="!imageDefault">
                            {{ markIsPrimaryText }}
                        </a>
                    </div>
                    <div class="display-flex">
                        <label class="image-edit display-flex cursor-pointer" :for="idEdit">
                            <i class="fa fa-edit"></i>
                        </label>
                        <a class="image-delete display-flex cursor-pointer" @click.prevent="deleteImage(currentIndexImage)">
                            <i class="fa fa-trash"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
		 <div class="loop-img-outer">
            <div class="row" v-if="images.length && multiple">
                <div class="col-4 loop-img-b" :class="image.highlight && 'image-highlight'" v-for="(image, index) in images" :key="index" @click="changeHighlight(index)">
                    <img class="show-img img-responsive img" :src="image.path">
                </div>
                <div class="col-4 loop-img-b add-img-btn" v-if="images.length < maxImage">
                    <i class="fa fa-plus"></i>
                    <div class="input-add-image position-absolute full-width full-height">
                        <label :for="idUpload" class="display-block full-width full-height cursor-pointer"></label>
                    </div>
                </div>
            </div>
			</div>
            <input class="display-none" :id="idUpload" @change="uploadFieldChange" name="images" :multiple="multiple" :accept="accept" type="file">
            <input class="display-none" :id="idEdit" @change="editFieldChange" name="image" :accept="accept" type="file">
        </div>
        <vue-image-lightbox-carousel
            ref="lightbox"
            :show="showLightbox"
            @close="showLightbox = false"
            :images="images"
            @change="changeHighlight"
            :showCaption="false"
        >
        </vue-image-lightbox-carousel>
    </div>
    
</template>
<script>
    import { forEach, findIndex, orderBy, cloneDeep } from 'lodash'
    import Popper from 'vue-popperjs'
    import 'vue-popperjs/dist/css/vue-popper.css'
    import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
    import bootbox from "bootbox";

    export default {
        name: 'UploadMultipleImages',
        props: {
            vehicleSlug: {
                type: String
            },
            dragText: {
                type: String,
                default: 'Drag images (multiple)'
            },
            browseText: {
                type: String,
                default: '(or) Select'
            },
            primaryText: {
                type: String,
                default: 'Cover photo'
            },
            markIsPrimaryText: {
                type: String,
                default: 'Set as Cover photo'
            },
            popupText: {
                type: String,
                default: 'This is the first image people will see so make sure it\'s your best one!'
            },
            maxImageText: {
                type: String,
                default: 'You are only allowed to upload maximum 10 vehicle photos'
            },
            dropText: {
                type: String,
                default: 'Drop your files here ...'
            },
            deleteText: {
                type: String,
                default: 'Are you sure you want to delete this?'
            },
            errorText: {
                type: String,
                default: 'Somthing went wrong please try again later'
            },
            accept: {
                type: String,
                default: 'image/gif,image/jpeg,image/png,image/bmp,image/jpg'
            },
            dataImages: {
                type: Array,
                default: () => {
                    return []
                }
            },
            multiple: {
                type: Boolean,
                default: true
            },
            showPrimary: {
                type: Boolean,
                default: true
            },
            maxImage: {
                type: Number,
                default: 15
            },
            idUpload: {
                type: String,
                default: 'image-upload'
            },
            idEdit: {
                type: String,
                default: 'image-edit'
            }
        },
        data () {
            return {
                currentIndexImage: 0,
                images: [],
                isDragover: false,
                showLightbox: false,
                arrLightBox: []
            }
        },
        components: {
            Popper,
            VueImageLightboxCarousel
        },
        computed: {
            imagePreview () {
                let index = findIndex(this.images, { highlight: 1 });
                if (index > -1) {
                    return this.images[index].path;
                } else {
                    return this.images.length ? this.images[0].path : '';
                }
            },
            imageDefault () {
                let index = findIndex(this.images, { highlight: 1 });
                if (index > -1) {
                    return this.images[index].default;
                } else {
                    if (this.images[this.currentIndexImage]) {
                        return this.images[this.currentIndexImage].default
                    }
                }
            }
        },
        methods: {
            onDrop (e) {
                this.isDragover = false
                e.stopPropagation()
                e.preventDefault()
                let files = e.dataTransfer.files
                if (!files.length) {
                    return false
                }
                if(!this.isValidNumberOfImages(files.length)){
                    return false
                }
                if(!this.isValidImage(files)){
                    return false
                }
                this.$emit('image-processing');
                forEach(files, (value, index) => {
                    let isLast = false;
                    if(index === (files.length - 1)){
                        isLast = true;
                    }
                    this.createImage(value, isLast)
                    if (!this.multiple) {
                        return false
                    }
                })
                if (document.getElementById(this.idUpload)) {
                    document.getElementById(this.idUpload).value = []
                }
            },
            onDragover () {
                this.isDragover = true
            },
            async createImage (file, isLast) {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('vehicle_slug', this.vehicleSlug);
                formData.append('image_type', !this.images.length ? 1 : 0);
                axios.post("/api/vehicle/manage/photos/save", formData, {
                    headers:{
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if(response.data.status === "success"){
                        this.images.push({id: response.data.image.id, name: response.data.image.name, path: response.data.image.path, highlight: response.data.image.highlight, default: response.data.image.default});
                        if (!this.images.length) {
                            this.currentIndexImage = 0;
                        }
                    }
                    flash(response.data.message, response.data.status);
                    this.$emit('image-processed', isLast);
                }).catch(responseError => {
                    if(responseError.hasOwnProperty('response')){
                        // check if errors exist
                        if(responseError.response.data.hasOwnProperty('errors')) {
                            flash(responseError.response.data.errors.file[0], "error");
                        }
                    }else{
                        flash(this.errorText, 'error');
                    }
                    this.$emit('image-processed', isLast);
                });
            },
            editImage (file) {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('image_id', this.images[this.currentIndexImage].id);
                formData.append('vehicle_slug', this.vehicleSlug);
                formData.append('image_type', this.images[this.currentIndexImage].default);
                axios.post("/api/vehicle/manage/photos/save", formData, {
                    headers:{
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if(response.data.status === "success"){
                        if (this.images.length && this.images[this.currentIndexImage]) {
                            this.images[this.currentIndexImage].id = response.data.image.id;
                            this.images[this.currentIndexImage].name = response.data.image.name;
                            this.images[this.currentIndexImage].path = response.data.image.path;
                            this.images[this.currentIndexImage].highlight = response.data.image.highlight;
                            this.images[this.currentIndexImage].default = response.data.image.default
                            this.changeHighlight(this.currentIndexImage);
                        }
                    }
                    flash(response.data.message, response.data.status);
                    this.$emit('image-processed', true);
                }).catch(responseError => {
                    if(responseError.hasOwnProperty('response')){
                        // check if errors exist
                        if(responseError.response.data.hasOwnProperty('errors')) {
                            flash(responseError.response.data.errors.file[0], "error");
                        }
                    }else{
                        flash(this.errorText, 'error');
                        console.log(responseError);
                    }
                });
            },
            uploadFieldChange (e) {
                let files = e.target.files || e.dataTransfer.files
                if (!files.length) {
                    return false
                }
                if(!this.isValidNumberOfImages(files.length)){
                    return false
                }
                if(!this.isValidImage(files)){
                    return false
                }

                this.$emit('image-processing');

                forEach(files, (value, index) => {
                    let isLast = false;
                    if(index === (files.length - 1)){
                        isLast = true;
                    }
                    this.createImage(value, isLast)
                })
                if (document.getElementById(this.idUpload)) {
                    document.getElementById(this.idUpload).value = []
                }
            },
            editFieldChange (e) {
                let files = e.target.files || e.dataTransfer.files
                if (!files.length) {
                    return false
                }
                if(!this.isValidNumberOfImages(files.length)){
                    return false
                }
                if(!this.isValidImage(files)){
                    return false
                }
                this.$emit('image-processing');
                forEach(files, (value, index) => {
                    this.editImage(value)
                })
                if (document.getElementById(this.idEdit)) {
                    document.getElementById(this.idEdit).value = ''
                }
            },
            changeHighlight (currentIndex) {
                this.currentIndexImage = currentIndex
                let arr = this.images
                this.images = []
                arr.map((item, index) => {
                    if (currentIndex === index) {
                        item.highlight = 1
                    } else {
                        item.highlight = 0
                    }
                    return item
                });
                this.images = arr
            },
            async markIsPrimary (currentIndex) {
                this.$emit('image-processing');
                this.images.map((item, index) => {
                    if (currentIndex === index) {
                        item.highlight = 1;
                        item.default = 1;
                    } else {
                        item.highlight = 0;
                        item.default = 0;
                    }
                    return item;
                });
                this.currentIndexImage = 0;
                this.images = orderBy(this.images, 'default', 'desc');
                axios.post("/api/vehicle/manage/photos/update-cover", {
                    image_id: this.images[this.currentIndexImage].id,
                    vehicle_slug:this.vehicleSlug
                }).then(response => {
                    if(response.data.status === 'error'){
                        this.images[this.currentIndexImage].default = 0;
                    }
                    flash(response.data.message, response.data.status);
                    this.$emit('image-processed');
                }).catch(responseError => {
                    flash(this.errorText, 'error');
                    this.images[this.currentIndexImage].default = 0;
                    this.$emit('image-processed');
                });
            },
            async deleteImage (currentIndex) {
                let vm = this;
                bootbox.confirm(vm.deleteText, function(result){ 
                    if(result){
                        vm.$emit('image-processing');
                        axios.delete('/api/vehicle/manage/photos/'+vm.images[currentIndex].id+'/delete').then(response => {
                            if(response.data.status === "success"){
                                if (vm.images[currentIndex].default === 1) {
                                    vm.images[0].default = 1;
                                }
                                vm.images.splice(currentIndex, 1);
                                vm.currentIndexImage = 0;
                                if (vm.images.length) {
                                    vm.images[0].highlight = 1;
                                }
                            }
                            flash(response.data.message, response.data.status);
                            vm.$emit('image-processed');
                        }).catch(error => {
                            flash(vm.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            vm.$emit('image-processed');
                        });
                    }
                });
            },
            openGallery(index) {
                this.showLightbox = true
                this.$refs.lightbox.showImage(index)
            },
            onOpenedLightBox(value) {
                if (value) {
                    this.showLightbox = true
                } else {
                    this.showLightbox = false
                }
            },
            isValidNumberOfImages(amount) {
                let count = this.images.length + amount;
                if (count > this.maxImage) {
                    this.$emit('limit-exceeded', amount)
                    return false
                } else {
                    return true
                }
            },
            isValidImage(files){
                let inValidCount = 0;
                forEach(files, (value, index) => {
                    let ext = files[index].name.split('.').pop();
                    console.log(ext);
                    if((ext !== "jpeg") && (ext !== "jpg") && (ext !== "png")){
                        inValidCount++;
                    }
                });
                if(inValidCount > 0){
                    this.$emit('invalid-file');
                    return false;
                }else{
                    return true;
                }
            }
        },
        watch: {
            dataImages: {
                handler: function (newVal) {
                    this.images = newVal;
                },
                deep: true
            }
        },
        mounted () {
            ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((event) => {
                window.addEventListener(event, (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                });
            });
            document.body.addEventListener('dragleave', (event) => {
                event.stopPropagation()
                event.preventDefault()
                this.isDragover = false
            });
        },
        created () {
            this.images = [];
            this.images = cloneDeep(this.dataImages);
        }
    }
</script>
