<template>
    <div class="create-ads-inner global-form create-car-ads-form">
        <div class="row">
            <div class="col-12">
                <ul class="add-steps d-flex">
                    <li :class="(steps >= 1) ? 'complete': ''">
                        <a :href="(steps >= 1) ? '/vehicle/'+vehicle.slug+'/general' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-car"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.GENERAL_INFO }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 2) ? 'complete': ''">
                        <a :href="(steps >= 2) ? '/vehicle/'+vehicle.slug+'/features' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-list"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.SELECT_FEATURES }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 3) ? 'complete': ''">
                        <a :href="(steps >= 3) ? '/vehicle/'+vehicle.slug+'/details' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-user-plus"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ADD_DETAILS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 4) ? 'complete': ''">
                        <a :href="(steps >= 4) ? '/vehicle/'+vehicle.slug+'/photos' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-camera-retro"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ATTACH_PHOTOS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 5) ? 'complete': ''">
                        <a :href="(steps >= 5) ? '/vehicle/'+vehicle.slug+'/preview' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-shopping-basket"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.REVIEW_COMPLETE }}</h3>
                        </a>
                    </li>
                </ul>
                <div class="progress mx-auto" :data-value="progress"> 
                    <span class="progress-left"> 
                        <span class="progress-bar border-primary"></span> 
                    </span> 
                    <span class="progress-right"> 
                        <span class="progress-bar border-primary"></span> 
                    </span>
                    <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="value-text">{{ progress }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-block-outer">
            <div class="step-box1 step-5">
                <div class="preview-block">
                    <div class="preview1">
                        <div class="row no-gutters">
                            <div class="col-lg-6">
                                <div class="preview-img">
                                    <img :src="baseUrl+'/timthumb.php?src='+vehicle.main_image.image_full_path+'&w=434&h=261&zc=0'" alt="car" v-if="vehicle.main_image" /> 
                                    <img src="/frontend/images/no-img-415x234.jpg" alt="car" v-else />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <h2>{{ vehicle.title }}</h2>
                                <ul class="bullet-list">
                                    <li v-if="vehicle.price">
                                        <strong>{{ localeLang.PREVIEW.PRICE }}:</strong> {{ vehicle.price | toCurrency }}
                                    </li>
                                    <li v-if="vehicle.discount_price">
                                        <strong>{{ localeLang.PREVIEW.DISCOUNT_PRICE }}:</strong> {{ vehicle.discount_price | toCurrency }}
                                    </li>
                                    <li v-if="vehicle.odometer">
                                        <strong>{{ localeLang.DETAILS.FIELDS.ODOMETER }}:</strong> {{ vehicle.odometer | toNumber }} km
                                    </li>
                                    <li v-if="vehicle.interior_color">
                                        <strong>{{ localeLang.DETAILS.FIELDS.INTERIOR_COLOR }}:</strong> {{ vehicle.interior_color.name }}
                                    </li>
                                    <li v-if="vehicle.exterior_color">
                                        <strong>{{ localeLang.DETAILS.FIELDS.EXTERIOR_COLOR }}:</strong> {{ vehicle.exterior_color.name }}
                                    </li>
                                    <li v-if="vehicle.expiry_month && vehicle.expiry_year">
                                        <strong>{{ localeLang.PREVIEW.EXPIRY }}:</strong> {{ vehicle.expiry_month }}/{{ vehicle.expiry_year }}
                                    </li>
                                    <li v-else-if="vehicle.expiry_year">
                                        <strong>{{ localeLang.PREVIEW.EXPIRY }}:</strong> {{ vehicle.expiry_year }}
                                    </li v-else-if="vehicle.expiry_year">
                                    <li v-if="vehicle.transmissions">
                                        <strong>{{ localeLang.GENERAL.FIELDS.TRANSMISSION }}:</strong> {{ vehicle.transmissions.name }}
                                    </li>
                                    <li v-if="vehicle.bodystyle">
                                        <strong>{{ localeLang.GENERAL.FIELDS.BODY_TYPE }}:</strong> {{ vehicle.bodystyle.name }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <ul class="bullet-list pt-3">
                            <li>
                                <strong>{{ localeLang.DETAILS.FIELDS.LOCATION_ADDRESS }}:</strong>{{ vehicle.address }}
                            </li>
                            <li>
                                <strong>{{ localeLang.DETAILS.FIELDS.PHONE_NUMBER }}:</strong> {{ vehicle.phone }}
                            </li>
                            <li v-if="vehicle.badge">
                                <strong>{{ localeLang.GENERAL.FIELDS.BADGE }}:</strong> {{ vehicle.badge.name }}
                            </li>
                        </ul>
                    </div>
                    <div class="preview2">
                        <h3>{{ localeLang.PREVIEW.SHOWCASE }}</h3>
                        <div class="car-item">
                            <div class="list-item-img"> 
                                <a href="javascript:;" v-if="vehicle.main_image">
                                    <img :src="baseUrl+'/timthumb.php?src='+vehicle.main_image.image_full_path+'&w=434&h=261&zc=0'" alt="" /> 
                                </a>
                                <a href="javascript:;" v-else>
                                    <img src="/frontend/images/no-img-415x234.jpg" alt="" /> 
                                </a>
                                <div class="price-block double-price" v-if="vehicle.discount_price">
                                    <span class="original-price">{{ vehicle.price | toCurrency }}</span>
                                    <span class="discount-price" v-if="vehicle.is_masked_price">
                                        {{ vehicle.discount_price | toMaskedPrice(vehicle.initial_masking_digits) }}*</span>
                                    </span>
                                    <span class="discount-price" v-else>{{ vehicle.discount_price | toCurrency }}*</span>
                                </div>
                                <div class="price-block" v-else>
                                    <span v-if="vehicle.is_masked_price">{{ vehicle.price | toMaskedPrice(vehicle.initial_masking_digits) }}*</span>
                                    <span v-else>{{ vehicle.price | toCurrency }}*</span>
                                </div>
                                <div class="label-block" v-if="vehicle.is_featured == 1">
                                    {{ localeLang.BADGES.FEATURED }}
                                </div>
                                <div class="badges" v-if="vehicle.is_inspected">
                                    <img src="/frontend/images/badges.png">
                                    {{ localeLang.BADGES.INSPECTED }}
                                </div>
                                <div class="item-icon-bar">
                                    <ul>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="fa fa-camera"></i> {{ vehicle.vehicle_images_count }}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="fa fa-plus"></i> {{ localeLang.PREVIEW.COMPARE }}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="fa fa-heart"></i> {{ localeLang.PREVIEW.SAVE }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="list-item-detail">
                                <h4>
                                    <a href="javascript:;">
                                        {{ vehicle.title }}
                                    </a>
                                </h4>
                                <ul class="d-flex flex-wrap">
                                    <li v-if="vehicle.bodystyle">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/body-type-icon.png" alt="body-type-icon">
                                            </div>
                                            <span>{{ vehicle.bodystyle.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.fuel_type">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/engine-icon.png" alt="odometer-icon">
                                            </div>
                                            <span>{{ vehicle.fuel_type.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.transmissions">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/transmision-icon.png" alt="transmision-icon">
                                            </div>
                                            <span>{{ vehicle.transmissions.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.odometer">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/odometer-icon.png" alt="odometer-icon">
                                            </div>
                                            <span>{{ vehicle.odometer | toNumber }} km</span>
                                        </div>
                                    </li>
                                </ul> 
                                <p v-if="vehicle.description">
                                    {{ (vehicle.description.length > 100) ? vehicle.description.substring(0, 100)+'...' : vehicle.description }}
                                </p>
                                <div class="view-info-sec d-flex">
                                    <div class="left-info">
                                        <span>
                                            {{ (vehicle.role === 'Dealer') ? localeLang.PREVIEW.DEALER : localeLang.PREVIEW.SELLER }} {{ (vehicle.category_id === 1) ? localeLang.PREVIEW.NEW_CAR : localeLang.PREVIEW.USED_CAR }}
                                        </span>
                                        {{ vehicle.state ? vehicle.state : vehicle.country }} {{ vehicle.city ? '-' +vehicle.city : '' }} 
                                    </div>
                                    <div class="inquery-btn-col d-flex"> 
                                        <a class="enquiry-btn" href="javascript:;">{{ localeLang.PREVIEW.ENQUIRE }}</a> 
                                        <a class="view-detail-btn" href="javascript:;">{{ localeLang.PREVIEW.VIEW_DETAIL }}</a> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <div class="btn-outer text-right">
            <a :href="'/vehicle/'+vehicle.slug+'/photos'" class="submit-btn pull-left blue-btn">{{ localeLang.ACTIONS.PREV }}</a>
            <button type="button" class="submit-btn" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                <span>{{ localeLang.ACTIONS.COMPLETE }}</span>
            </button>
            <button type="button" class="submit-btn" v-on:click="completeVehicle()" v-else>{{ localeLang.ACTIONS.COMPLETE }}</button>
        </div>
    </div>  
</template>
<script>
    import CommonMixin from '../../../mixins/CommonMixin';
    import VehicleFormMixin from '../../../mixins/VehicleFormMixin';
    export default {
        name:"user-manage-ad-preview-component",
        props:[
            "vehicle",
            "localeLang"
        ],
        mixins: [CommonMixin, VehicleFormMixin],
        data() {
            return {
                loading : false,
                steps: 4,
                progress: 80
            }
        },
        created() {
            if(this.vehicle){
                this.steps = this.vehicle.steps;
                this.progress = (this.vehicle.steps * 20);
            }
        },
        methods:{
            /* Complete vehicle */
            async completeVehicle(){
                this.loading = true;
                axios.post("/api/vehicle/manage/complete",{
                    vehicle_slug: this.vehicle.slug
                }).then(response => {
                    if(response.data.status === 'success'){
                        window.location = response.data.redirect;
                    }else{
                        this.loading = false;
                    }
                    flash(response.data.message, response.data.status);
                }).catch(error => {
                    this.loading = false;
                    flash('Somthing went wrong. Please try again.', 'error');
                    console.log(error);
                });
            }
        }
    }
</script>
