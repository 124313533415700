<template>
    <div class="dash-info-tab" v-if="isBuyer">
        <div class="row">
            <div class="col-lg-6">
                <div class="cmn-dash-tab yellow-tab">
                    <div class="btm-dash-bar">
                        <div class="dash-text">
                            {{ localeLang.TOTAL }} <span>{{ localeLang.FAVOURITE_CARS }}</span>
                        </div>
                        <div class="dash-icon">{{ favouriteCount }}</div>
                    </div>
                    <div class="top-dash-bar"> 
                        <a href="javascript:;" class="setting-btn">
                            <img alt="" src="/frontend/images/srch-icon.png">
                        </a> 
                        <a href="/saved-vehicles" class="view-dtl-btn">
                            {{ localeLang.ACTION_TEXT_DETAIL }}
                        </a> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="cmn-dash-tab light-green-tab">
                    <div class="btm-dash-bar">
                        <div class="dash-text">
                            {{ localeLang.TOTAL }} <span>{{ localeLang.ENQUIRY_SENT }}</span>
                        </div>
                        <div class="dash-icon">{{ vehicleInquiriesCount }}</div>
                    </div>
                    <div class="top-dash-bar"> 
                        <a href="javascript:;" class="setting-btn">
                            <img alt="" src="/frontend/images/srch-icon.png">
                        </a> 
                        <a href="/messages" class="view-dtl-btn">
                            {{ localeLang.ACTION_TEXT_DETAIL }}
                        </a> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="cmn-dash-tab light-green-tab">
                    <div class="btm-dash-bar">
                        <div class="dash-text">
                            {{ localeLang.UPDATE_TEXT }} <span>{{ localeLang.PROFILE_TEXT }}</span>
                        </div>
                    </div>
                    <div class="top-dash-bar"> 
                        <a href="javascript:;" class="setting-btn">
                            <img alt="" src="/frontend/images/srch-icon.png">
                        </a> 
                        <a href="/profile" class="view-dtl-btn">
                            {{ localeLang.ACTION_TEXT_CLICK }}
                        </a> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="cmn-dash-tab light-green-tab">
                    <div class="btm-dash-bar">
                        <div class="dash-text">
                            {{ localeLang.CHANGE_TEXT }} <span>{{ localeLang.PASSWORD }}</span>
                        </div>
                    </div>
                    <div class="top-dash-bar"> 
                        <a href="javascript:;" class="setting-btn">
                            <img alt="" src="/frontend/images/srch-icon.png">
                        </a> 
                        <a href="/change/password" class="view-dtl-btn">
                            {{ localeLang.ACTION_TEXT_CLICK }}
                        </a> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dash-info-tab" v-else>
        <div class="row">
            <div class="col-lg-12">
                <div class="cmn-dash-tab blue-tab my-sltd-plan" v-if="subscription">
                    <div class="plan-loop-block" v-if="subscription.plan.plan_title_en">
                        <label>{{ localeLang.SUBSCRIPTION.LABELS.MY_PLAN }}</label>
                        <span>{{ subscription.plan.plan_title_en }}</span>
                    </div>
                    <div class="plan-loop-block" v-if="subscription.plan.plan_price">
                        <label>{{ localeLang.SUBSCRIPTION.LABELS.PLAN_FEES }}</label>
                        <span>
                            {{ subscription.plan.plan_price | toCurrency }} 
                            <small>({{ subscription.plan.plan_interval }})</small>
                        </span>
                    </div>
                    <div class="plan-loop-block" v-if="subscription.plan.discount_total > 0">
                        <label>{{ localeLang.SUBSCRIPTION.LABELS.DISCOUNT_FEES }}</label>
                        <span>
                            {{ subscription.plan.total | toCurrency }} 
                        </span>
                    </div>
                    <div class="plan-loop-block" v-if="subscription.plan.plan_listing_count">
                        <label>{{ localeLang.SUBSCRIPTION.LABELS.TOTAL_LISTINGS }}</label>
                        <span>
                            {{ subscription.plan.plan_listing_count }} 
                            <small>(Consumed: {{ listingCount }})</small>
                        </span>
                    </div>
                    <div class="plan-loop-block" v-if="subscription.plan.plan_featured_count">
                        <label>{{ localeLang.SUBSCRIPTION.LABELS.FEATURED }}</label>
                        <span>
                            {{ subscription.plan.plan_featured_count }} 
                            <small>(Consumed: {{ featuredCount }})</small>
                        </span>
                    </div>
                    <div class="plan-loop-block" v-if="subscription.plan.plan_onsale_count">
                        <label>{{ localeLang.SUBSCRIPTION.LABELS.ON_SALE }}</label>
                        <span>{{ subscription.plan.plan_onsale_count }} <small>(Consumed: {{ onSaleCount }})</small></span>
                    </div>
                    <div class="plan-loop-block" v-if="subscription.plan.plan_untilsold_count">
                        <label>{{ localeLang.SUBSCRIPTION.LABELS.UNTILL_SOLD }}</label>
                        <span>{{ subscription.plan.plan_untilsold_count }} <small>(Consumed: {{ untilSoldCount }})</small></span>
                    </div>
                    <div v-if="subscription.is_recurring">
                        <div class="plan-loop-block">
                            <label>{{ localeLang.SUBSCRIPTION.LABELS.STATUS }}</label>
                            <span v-if="subscription.is_ended === true">
                                {{ localeLang.SUBSCRIPTION.LABEL_VALUES.SUBSCRIPTION_EXPIRED }}
                            </span>
                            <span v-else-if="(subscription.is_cancelled === true) && (subscription.is_on_grace_period === true)">
                                {{ localeLang.SUBSCRIPTION.LABEL_VALUES.SUBSCRIPTION_GRACE_PERIOD }}
                            </span>
                            <span v-else>
                                {{ localeLang.SUBSCRIPTION.LABEL_VALUES.SUBSCRIPTION_ACTIVE }}
                            </span>
                        </div>
                        <div class="text-right p-3" v-if="(subscription.is_cancelled === true) && (subscription.is_on_grace_period === true) && (subscription.is_ended === false)">
                            <button type="button" class="btn manage-btn bg-red-btnn" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                                <span>{{ localeLang.SUBSCRIPTION.ACTIONS.RESUME_SUBSCRIPTION }}</span>
                            </button>
                            <button type="button" class="btn manage-btn bg-red-btn" v-on:click="resumeSubscription(subscription.id)"  v-else>
                                {{ localeLang.SUBSCRIPTION.ACTIONS.RESUME_SUBSCRIPTION }}
                            </button>
                            <a class="btn manage-btn" href="/subscription/downgrade-plan" v-show="subscription.can_downgrade">
                                {{ localeLang.SUBSCRIPTION.ACTIONS.DOWNGRADE_SUBSCRIPTION }}
                            </a>
                            <a class="btn manage-btn" href="/subscription/upgrade-plan" v-show="subscription.can_upgrade">
                                {{ localeLang.SUBSCRIPTION.ACTIONS.UPGRADE_SUBSCRIPTION }}
                            </a>
                        </div>
                        <div class="text-right p-3" v-else-if="(subscription.is_cancelled === false) && (subscription.is_on_grace_period === false) && (subscription.is_ended === false)">
                            <button type="button" class="btn manage-btn bg-red-btnn" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                                <span>{{ localeLang.SUBSCRIPTION.ACTIONS.CANCEL_SUBSCRIPTION }}</span>
                            </button>
                            <button type="button" class="btn manage-btn bg-red-btn" v-on:click="cancelSubscription(subscription.id)"  v-else>
                                {{ localeLang.SUBSCRIPTION.ACTIONS.CANCEL_SUBSCRIPTION }}
                            </button>
                            <a class="btn manage-btn" href="/subscription/downgrade-plan" v-show="subscription.can_downgrade">
                                {{ localeLang.SUBSCRIPTION.ACTIONS.DOWNGRADE_SUBSCRIPTION }}
                            </a>
                            <a class="btn manage-btn" href="/subscription/upgrade-plan" v-show="subscription.can_upgrade">
                                {{ localeLang.SUBSCRIPTION.ACTIONS.UPGRADE_SUBSCRIPTION }}
                            </a>
                        </div>
                        <div class="text-right p-3" v-else>
                            <div v-if="subscription.is_ended === true">
                                <a class="btn manage-btn" href="/subscription/payments">
                                    {{ localeLang.SUBSCRIPTION.ACTIONS.NEW_SUBSCRIPTION }}
                                </a>
                            </div>
                            <div v-else>
                                <a class="btn manage-btn" href="/subscription/downgrade-plan" v-show="subscription.can_downgrade">
                                    {{ localeLang.SUBSCRIPTION.ACTIONS.DOWNGRADE_SUBSCRIPTION }}
                                </a>
                                <a class="btn manage-btn" href="/subscription/upgrade-plan" v-show="subscription.can_upgrade">
                                    {{ localeLang.SUBSCRIPTION.ACTIONS.UPGRADE_SUBSCRIPTION }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="plan-loop-block">
                            <label>Status</label>
                            <span v-if="subscription.is_ended === true">
                                {{ localeLang.SUBSCRIPTION.LABEL_VALUES.SUBSCRIPTION_EXPIRED }}
                            </span>
                            <span v-else="subscription.is_ended === false">
                                {{ localeLang.SUBSCRIPTION.LABEL_VALUES.SUBSCRIPTION_ACTIVE }}
                            </span>
                        </div>
                        <div class="text-right p-3" v-if="subscription.is_ended === true">
                            <a class="btn manage-btn" href="/subscription/payments">
                                {{ localeLang.SUBSCRIPTION.ACTIONS.NEW_SUBSCRIPTION }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="cmn-dash-tab blue-tab my-sltd-plan" v-else>
                    <div class="plan-loop-block">
                        <span>{{ localeLang.SUBSCRIPTION.LABEL_VALUES.NO_SUBSCRIPTION }}</span>
                    </div>
                    <div class="text-right p-3">
                        <a class="btn manage-btn" href="/subscription/payments">
                            {{ localeLang.SUBSCRIPTION.ACTIONS.NEW_SUBSCRIPTION }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="cmn-dash-tab blue-tab">
                    <div class="btm-dash-bar">
                        <div class="dash-text">
                            {{ localeLang.TOTAL }} <span>{{ localeLang.CARS }}</span>
                        </div>
                        <div class="dash-icon">{{ vehiclesCount }}</div>
                    </div>
                    <div class="top-dash-bar"> 
                        <a href="javascript:;" class="setting-btn">
                            <img alt="" src="/frontend/images/srch-icon.png">
                        </a> 
                        <a href="/manage-ads" class="view-dtl-btn">{{ localeLang.ACTION_TEXT_DETAIL }}</a> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="cmn-dash-tab yellow-tab">
                    <div class="btm-dash-bar">
                        <div class="dash-text">
                            {{ localeLang.TOTAL }} <span>{{ localeLang.FEATURED_CARS }}</span>
                        </div>
                        <div class="dash-icon">{{ featuredCount }}</div>
                    </div>
                    <div class="top-dash-bar"> 
                        <a href="javascript:;" class="setting-btn">
                            <img alt="" src="/frontend/images/srch-icon.png">
                        </a> 
                        <a href="/manage-ads" class="view-dtl-btn">
                            {{ localeLang.ACTION_TEXT_DETAIL }}
                        </a> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="cmn-dash-tab light-green-tab">
                    <div class="btm-dash-bar">
                        <div class="dash-text">
                            {{ localeLang.TOTAL }} <span>{{ localeLang.ENQUIRY_RECEIVED }}</span>
                        </div>
                        <div class="dash-icon">{{ vehicleInquiriesCount }}</div>
                    </div>
                    <div class="top-dash-bar"> 
                        <a href="javascript:;" class="setting-btn">
                            <img alt="" src="/frontend/images/srch-icon.png">
                        </a> 
                        <a href="/messages" class="view-dtl-btn">
                            {{ localeLang.ACTION_TEXT_DETAIL }}
                        </a> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="cmn-dash-tab black-tab">
                    <div class="btm-dash-bar">
                        <div class="dash-text">
                            {{ localeLang.TOTAL }} <span>{{ localeLang.FAVOURITE_CARS }}</span>
                        </div>
                        <div class="dash-icon">{{ favouriteCount }}</div>
                    </div>
                    <div class="top-dash-bar"> 
                        <a href="javascript:;" class="setting-btn">
                            <img alt="" src="/frontend/images/srch-icon.png">
                        </a> 
                        <a href="/saved-vehicles" class="view-dtl-btn">
                            {{ localeLang.ACTION_TEXT_DETAIL }}
                        </a> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import bootbox from "bootbox";
    import CommonMixin from '../../mixins/CommonMixin';
    export default {
        name:"user-dashboard-component",
        props:[
            "isBuyer",
            "subscription",
            "vehiclesCount",
            "listingCount", 
            "featuredCount",
            "onSaleCount",
            "untilSoldCount",
            "favouriteCount",
            "vehicleInquiriesCount",
            "flashSuccess", 
            "flashError",
            "localeLang"
        ],
        mixins: [CommonMixin],
        data() {
            return {
                loading : false
            }
        },
        mounted(){
            if(this.flashSuccess){
                flash(this.flashSuccess, 'success');
            }
            if(this.flashError){
                flash(this.flashError, 'error');
            }
        },
        methods:{
            /* cancel subscription */
            cancelSubscription(subscriptionId){
                let vm = this;
                bootbox.confirm(vm.localeLang.SUBSCRIPTION.MESSAGES.SURE_TO_CANCEL, function(result){
                    if(result){
                        vm.loading = true;
                        axios.get("/api/subscription/cancle-subsciption/"+subscriptionId).then(response => {
                            vm.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === 'success'){
                                vm.subscription.is_cancelled = true;
                                vm.subscription.is_on_grace_period = true;
                                vm.subscription.is_ended = false;
                            }
                        }).catch(error => {
                            vm.loading = false;
                            console.log(error);
                        });
                    }
                });
            },
            /* resume subscription */
            resumeSubscription(subscriptionId){
                let vm = this;
                bootbox.confirm(vm.localeLang.SUBSCRIPTION.MESSAGES.SURE_TO_RESUME, function(result){
                    if(result){
                        vm.loading = true;
                        axios.get("/api/subscription/resume-subsciption/"+subscriptionId).then(response => {
                            vm.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === 'success'){
                                vm.subscription.is_ended = false;
                                vm.subscription.is_cancelled = false;
                                vm.subscription.is_on_grace_period = false;
                            }
                        }).catch(error => {
                            vm.loading = false;
                            console.log(error);
                        });
                    }
                });
            },
        }
    }
</script>
