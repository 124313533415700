<template>
    <div>
        <div v-if="loading">
            <spinner-component></spinner-component>
        </div>
        <div v-else>
            <div v-if="allVehicles.total > 0">
                <ul class="cars-review-block row">
                    <li v-for="vehicle in allVehicles.data">
                        <div class="block-content">
                            <a :href="'/vehicle/'+vehicle.slug+'#pane-d'" class="img-block" v-if="vehicle.main_image">
                                <img :src="baseUrl+'/timthumb.php?src='+vehicle.main_image.image_full_path+'&w=408&h=236&zc=0'" /> 
                            </a>
                            <a :href="'/vehicle/'+vehicle.slug+'#pane-d'" class="img-block" v-else>
                                <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=408&h=236&zc=0'" /> 
                            </a>
                            <div class="text-block">
                                <div class="review-heading">
                                    <a :href="'/vehicle/'+vehicle.slug+'#pane-d'">{{ vehicle.title }}</a>
                                </div>
                                <div class="review-rating">
                                    <span class="star-rating">
                                        <star-rating 
                                            :rating="vehicle.average_rating"
                                            :star-size="14"
                                            :show-rating="false" 
                                            :read-only="true"
                                            :round-start-rating="false"
                                        ></star-rating>
                                    </span>
                                    <span class="review-user-count">{{ localeLang.LABLES.BASED_ON }}
                                        <a href="javascript:;">{{ vehicle.vehicle_reviews_count }} {{ localeLang.LABLES.USER_REVIEWS }}</a>
                                    </span>
                                </div>
                                <div class="review-desc">{{ vehicle.latest_review_title }}</div>
                                <div class="reviews-link">
                                    <a :href="'/vehicle/'+vehicle.slug+'#pane-d'">{{ localeLang.LABLES.ALL }} {{ vehicle.title }} {{ localeLang.LABLES.REVIEWS }} ({{ vehicle.vehicle_reviews_count }})</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="row" v-else> 
                <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                    <div class="no-post-inr">
                        <div class="no-post-img">
                            <img src="/frontend/images/no-post-found.jpg" alt="">
                        </div>
                        <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                        <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" v-if="allVehicles.last_page > 1">
            <div class="custom-pagination">
                <paginate
                    :page-count="allVehicles.last_page"
                    :page-range="5"
                    :click-handler="paginateVehicles"
                    :prev-text="localeLang.PAGINATION.PREV"
                    :next-text="localeLang.PAGINATION.NEXT"
                    :page-class="'page-item'"
                    :active-class="'active'"
                    :disabled-class="'disabled'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination justify-content-center'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>
<script>
    import Paginate from 'vuejs-paginate';
    import StarRating from 'vue-star-rating';
    import CommonMixin from '../../mixins/CommonMixin';
    import SpinnerComponent from '../../components/SpinnerComponent';
    export default {
        name:"reviews-vehicle-user-component",
        props: [
            "vehicles",
            "localeLang"
        ],
        mixins: [CommonMixin],
        components:{
            SpinnerComponent:SpinnerComponent,
            StarRating:StarRating,
            Paginate:Paginate,
        },
        data() {
            return {
                allVehicles: [],
                loading : false,
            }
        },
        created(){
            this.allVehicles = this.vehicles;
        },
        methods:{
            /* Get vehicles */
            async getVehicles(page = 1){
                this.loading = true;
                let url = '/reviews?page='+page;
                axios.get(url).then(response => {
                    this.loading = false;
                    this.allVehicles = response.data.data;
                    window.$(window).scrollTop(0);
                }).catch(error => {
                    this.loading = false;
                    this.allVehicles = [];
                    window.$(window).scrollTop(0);
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },
            /* Paginate vehicles */
            async paginateVehicles(page) {
                this.getVehicles(page);
            }
        }
    }
</script>
