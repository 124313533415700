<template>
    <div>
        <a href="javascript:;" v-on:click.prevent="showModel()"> 
            <span class="text-danger">
                {{ localeLang.TITLE }}
            </span>
        </a>
        <span class="ml-2">
            <i class="fa fa-info-circle" data-toggle="tooltip" :title="localeLang.TOOLTIP"></i>
        </span>
        <!-- Custom Popup -->
        <div class="modal fade custom-popup vehicle-inspection-modal" id="vehicle-inspection-modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <button data-dismiss="modal" class="close" type="button">×</button>        
                        <h2>{{ localeLang.FORM.TITLE }}</h2>
                        <form @submit.prevent="inspectVehcile">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6 input-col">
                                        <input type="text" :placeholder="localeLang.FORM.NAME_PLACEHOLDER" class="form-control" name="name" :class="errors.has('name')?'is-invalid':''" v-model="request.name" v-validate="'required|max:50'" data-vv-as="full name">
                                        <div class="invalid-feedback" v-if="errors.has('name')">
                                            {{ errors.first('name') }}
                                        </div>
                                    </div> 
                                    <div class="col-md-6 input-col">
                                        <input type="text" :placeholder="localeLang.FORM.EMAIL_PLACEHOLDER" class="form-control" name="email" :class="errors.has('email')?'is-invalid':''" v-model="request.email" v-validate="'email|max:100'" data-vv-as="email" />
                                        <div class="invalid-feedback" v-if="errors.has('email')">
                                            {{ errors.first('email') }}
                                        </div>
                                    </div> 
                                    <div class="col-md-6 input-col">
                                        <input type="text" :placeholder="localeLang.FORM.PHONE_PLACEHOLDER" class="form-control" name="phone" :class="errors.has('phone')?'is-invalid':''" v-model="request.phone" v-validate="'required|numeric|min:9|max:15'" data-vv-as="phone" />
                                        <div class="invalid-feedback" v-if="errors.has('phone')">
                                            {{ errors.first('phone') }}
                                        </div>
                                    </div> 
                                    <div class="col-md-12 input-col">
                                        <textarea :placeholder="localeLang.FORM.MESSAGE_PLACEHOLDER" class="form-control" name="message" :class="errors.has('message')?'is-invalid':''" v-model="request.message" v-validate="'required|max:500'" data-vv-as="message"></textarea>
                                        <div class="invalid-feedback" v-if="errors.has('message')">
                                            {{ errors.first('message') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group term-info">
                                <p>{{ localeLang.FORM.POLICY.LINE1 }}
                                    <a href="/privacy-policy" target="_blank">
                                        {{ localeLang.FORM.POLICY.LINE2 }}
                                    </a>
                                    {{ localeLang.FORM.POLICY.LINE3 }} 
                                    <a href="/terms-and-conditions" target="_blank">
                                        {{ localeLang.FORM.POLICY.LINE4 }}  
                                    </a>
                                    {{ localeLang.FORM.POLICY.LINE5 }} 
                                    <a href="/privacy-policy" target="_blank">
                                        {{ localeLang.FORM.POLICY.LINE6 }}
                                    </a>.
                                </p>
                            </div>
                            <div class="form-group submit-form">
                                <button type="button" class="submit-btn" disabled v-if="loading">
                                    <span class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                                    <span>{{ localeLang.FORM.ACTION_TEXT }}</span>
                                </button>
                                <button type="submit" class="submit-btn" :disabled="errors.any()" v-else>{{ localeLang.FORM.ACTION_TEXT }}</button>
                            </div>
                        </form>
                    </div>	
                </div>
            </div>
        </div>
        <!-- Custom Popup -->
    </div>
</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    import FormMixin from '../../mixins/FormMixin';
    import bootbox from "bootbox";
    import VeeValidate from 'vee-validate';
    Vue.use(VeeValidate);

    export default {
        name:"vehicle-inspection-component",
        props:[
            "vehicle",
            "localeLang",
            "loggedinUser"
        ],
        mixins: [CommonMixin, FormMixin],
        data() {
            return {
                loading : false,
                request: {
                    vehicle_id: this.vehicle.id,
                    user_id: "",
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                }
            }
        },
        created(){
            if(this.loggedinUser){
                this.request.user_id = this.loggedinUser.id;
                this.request.name = this.loggedinUser.name;
                this.request.email = this.loggedinUser.email;
                this.request.phone = this.loggedinUser.phone;
            }
        },
        methods:{ 
            showModel() {
                let vm = this;
                if(this.loggedinUser){
                    window.$('.vehicle-inspection-modal').modal({
                        keyboard: false,
                        backdrop: 'static'
                    });
                }else{
                    window.location = "/login?intended=true";
                }
            },
            inspectVehcile() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.request.vehicle_id = this.vehicle.id;
                        this.request.user_id = this.loggedinUser.id;
                        axios.post('/api/vehicle/manage/inspect', this.request).then(response => {
                            this.loading = false;
                            window.$('.vehicle-inspection-modal').modal('hide');
                            if(response.data.status === 'success'){
                                if(!this.loggedinUser){
                                    this.request.name = "";
                                    this.request.email = "";
                                    this.request.phone = "";
                                }
                                this.request.message = "";
                                this.$validator.reset();
                            }
                            flash(response.data.message, response.data.status);
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            }
        }
    }
</script>
