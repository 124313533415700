<template>
    <div>
        <div v-if="loading">
            <spinner-component></spinner-component>
        </div>
        <div v-else>
            <div v-if="allVehicles.total > 0">
                <div class="row">
                    <div class="col-sm-6" v-for="(vehicle, key) in allVehicles.data">
                        <div class="car-item">
                            <div class="list-item-img">
                                <a :href="'/vehicle/'+vehicle.slug" v-if="vehicle.main_image">
                                    <img :src="baseUrl+'/timthumb.php?src='+vehicle.main_image.image_full_path+'&w=434&h=261&zc=0'" />
                                </a>
                                <a :href="'/vehicle/'+vehicle.slug" v-else>
                                    <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=434&h=261&zc=0'"  />
                                </a>
                                <div class="price-block double-price" v-if="vehicle.discount_price">
                                    <span class="original-price">{{ vehicle.price | toCurrency }}</span>
                                    <span class="discount-price" v-if="vehicle.is_masked_price">
                                        {{ vehicle.discount_price | toMaskedPrice(vehicle.initial_masking_digits) }}*</span>
                                    </span>
                                    <span class="discount-price" v-else>{{ vehicle.discount_price | toCurrency }}*</span>
                                </div>
                                <div class="price-block" v-else>
                                    <span v-if="vehicle.is_masked_price">{{ vehicle.price | toMaskedPrice(vehicle.initial_masking_digits) }}*</span>
                                    <span v-else>{{ vehicle.price | toCurrency }}*</span>
                                </div>
                                <div class="label-block" v-if="vehicle.is_featured == 1">
                                    {{ localeLang.VEHICLE.BADGE_FEATURED }}
                                </div>
                                <div class="badges" v-if="vehicle.is_inspected">
                                    <img src="/frontend/images/badges.png">
                                    {{ localeLang.VEHICLE.BADGE_INSPECTED }}
                                </div>
                                <div class="item-icon-bar">
                                    <ul>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="fa fa-camera"></i> {{ vehicle.vehicle_images_count }}
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" :class="comparingVehicles.some(item => item.id === vehicle.id) ? 'active': ''" v-on:click.prevent="addToCompare(vehicle.id, key)">
                                                <i class="fa fa-plus"></i> {{ localeLang.VEHICLE.ACTION_COMPARE }}
                                            </a>
                                        </li>
                                        <li v-if="loggedinUser">
                                            <a href="javascript:;" :class="(vehicle.is_my_favourite === true) ? 'active': ''" v-on:click.prevent="toogleFavourite(vehicle.id, key)">
                                                <i class="fa fa-heart"></i> {{ localeLang.VEHICLE.ACTION_SAVE_FAV }}
                                            </a>
                                        </li>
                                        <li v-else>
                                            <a href="/login?intended=true" :class="(vehicle.is_my_favourite === true) ? 'active': ''">
                                                <i class="fa fa-heart"></i> {{ localeLang.VEHICLE.ACTION_SAVE_FAV }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="list-item-detail">
                                <h4>
                                    <a :href="'/vehicle/'+vehicle.slug">
                                        {{ vehicle.title }}
                                    </a>
                                </h4>
                                <ul class="d-flex flex-wrap">
                                    <li v-if="vehicle.bodystyle">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/body-type-icon.png" alt="body-type-icon">
                                            </div>
                                            <span>{{ vehicle.bodystyle.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.fuel_type">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/engine-icon.png" alt="odometer-icon">
                                            </div>
                                            <span>{{ vehicle.fuel_type.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.transmissions">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/transmision-icon.png" alt="transmision-icon">
                                            </div>
                                            <span>{{ vehicle.transmissions.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.odometer">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/odometer-icon.png" alt="odometer-icon">
                                            </div>
                                            <span>{{ vehicle.odometer | toNumber }} {{ localeLang.VEHICLE.LABEL_KM }}</span>
                                        </div>
                                    </li>
                                </ul>
                                <div class="view-info-sec d-flex">
                                    <div class="left-info">
                                        <span>
                                            {{ (vehicle.role === 'Dealer') ? localeLang.VEHICLE.LABEL_DEALER : localeLang.VEHICLE.LABEL_SELLER }} {{ (vehicle.category_id === 1) ? localeLang.VEHICLE.LABEL_NEW_CAR : localeLang.VEHICLE.LABEL_USED_CAR }} -


                                             <a :href="'/vehicle/'+'dealer/'+vehicle.user.id" v-if="vehicle.role === 'Dealer'">
                                             {{ vehicle.user.company_name  }}
                                            </a>

                                           <a style="color:black" href="javascript:;" v-else>
                                              {{ vehicle.user.name }}
                                            </a>





                                        </span>
                                        <span>
                                             {{ vehicle.state ? vehicle.state : vehicle.country }} {{ vehicle.city ? '-' +vehicle.city : '' }}
                                        </span>
                                    </div>
                                    <div class="inquery-btn-col d-flex">
                                        <a href="javascript:;" class="enquiry-btn" v-on:click.prevent="inquiryOnVehicle(vehicle.id)">
                                            {{ localeLang.VEHICLE.ACTION_ENQUIRE }}
                                        </a>
                                        <a :href="'/vehicle/'+vehicle.slug" class="view-detail-btn">
                                            {{ localeLang.VEHICLE.ACTION_DETAIL }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                    <div class="no-post-inr">
                        <div class="no-post-img">
                            <img src="/frontend/images/no-post-found.jpg" alt="">
                        </div>
                        <h2>{{ localeLang.VEHICLE.LABEL_OPPS }}</h2>
                        <p>{{ localeLang.VEHICLE.NO_RESULT }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" v-if="allVehicles.last_page > 1">
            <div class="custom-pagination">
                <paginate
                    :page-count="allVehicles.last_page"
                    :page-range="5"
                    :click-handler="paginateVehicles"
                    :prev-text="localeLang.VEHICLE.LABEL_PREV"
                    :next-text="localeLang.VEHICLE.LABEL_NEXT"
                    :page-class="'page-item'"
                    :active-class="'active'"
                    :disabled-class="'disabled'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination justify-content-center'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>
<script>
    import Paginate from 'vuejs-paginate';
    import CommonMixin from '../../mixins/CommonMixin';
    import SpinnerComponent from '../../components/SpinnerComponent';
    export default {
        name:"vehicle-list-component",
        props:[
            "allVehicles",
            "loggedinUser",
            "comparingVehicles",
            "localeLang"
        ],
        mixins: [CommonMixin],
        components:{
            Paginate:Paginate,
            SpinnerComponent: SpinnerComponent
        },
        data() {
            return {
                loading:false
            }
        },
        methods:{
            /* toggle fav vehicle*/
            toogleFavourite(vehicleId, key){
                this.$emit('handle-toogle-favourite', vehicleId, key);
            },

            /* inquiry vehicle*/
            inquiryOnVehicle(vehicleId){
                this.$emit('handle-vehicle-inquiry', vehicleId);
            },

            /* add vehicle to compare*/
            addToCompare(vehicleId, key){
                this.$emit('handle-add-to-compare', vehicleId, key);
            },

            /* paginate vehicle */
            paginateVehicles(page){
                this.$emit('handle-vehicle-paginate', page);
            }

        }
    }
</script>
