<template>
    <div class="create-ads-inner global-form create-car-ads-form">
        <div class="row">
            <div class="col-12">
                <ul class="add-steps d-flex">
                    <li :class="(steps >= 1) ? 'complete': ''">
                        <a :href="(steps >= 1) ? '/vehicle/'+vehicleSlug+'/general' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-car"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.GENERAL_INFO }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 2) ? 'complete': ''">
                        <a :href="(steps >= 2) ? '/vehicle/'+vehicleSlug+'/features' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-list"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.SELECT_FEATURES }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 3) ? 'complete': ''">
                        <a :href="(steps >= 3) ? '/vehicle/'+vehicleSlug+'/details' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-user-plus"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ADD_DETAILS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 4) ? 'complete': ''">
                        <a :href="(steps >= 4) ? '/vehicle/'+vehicleSlug+'/photos' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-camera-retro"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ATTACH_PHOTOS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 5) ? 'complete': ''">
                        <a :href="(steps >= 5) ? '/vehicle/'+vehicleSlug+'/preview' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-shopping-basket"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.REVIEW_COMPLETE }}</h3>
                        </a>
                    </li>
                </ul>
                <div class="progress mx-auto" :data-value="progress"> 
                    <span class="progress-left"> 
                        <span class="progress-bar border-primary"></span> 
                    </span> 
                    <span class="progress-right"> 
                        <span class="progress-bar border-primary"></span> 
                    </span>
                    <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="value-text">{{ progress }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-block-outer">
            <div class="step-box1 step-2">
                <div class="row">
                    <div class="col-12">
                        <div class="car-find-sub-hd"> 
                            {{ localeLang.FEATURES.SUB_TITLE }} {{ vehicleTitle }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="custom-checkbox checkbox-inline form-group loop-check" v-if="features.length > 0">
                            <div class="checkbox-inner" v-for="(feature, index) in features">
                                <input type="checkbox" :id="'check'+index" name="features[]" :value="feature.id" v-model="request.features">
                                <label :for="'check'+index">{{ feature.name }}</label>
                            </div>
                        </div>
                        <div v-else>No features exists yet!</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-outer text-right">
            <a :href="'/vehicle/'+vehicleSlug+'/general'" class="submit-btn pull-left blue-btn">{{ localeLang.ACTIONS.PREV }}</a>
            <button type="button" class="submit-btn" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                <span>{{ localeLang.ACTIONS.NEXT }}</span>
            </button>
            <button type="button" class="submit-btn" v-on:click="saveFeatures()" :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.NEXT }}</button>
        </div>
    </div>
</template>
<script>
    import CommonMixin from '../../../mixins/CommonMixin';
    import VehicleFormMixin from '../../../mixins/VehicleFormMixin';
    import FormMixin from '../../../mixins/FormMixin';
    import VeeValidate from 'vee-validate';
    Vue.use(VeeValidate);

    export default {
        name:"user-manage-ad-features-component",
        props:[
            "features",
            "vehicleTitle",
            "vehicleSlug",
            "vehicleSteps",
            "vehicleFeatures",
            "localeLang"
        ],
        mixins: [CommonMixin, VehicleFormMixin, FormMixin],
        data() {
            return {
                loading : false,
                steps: 1,
                progress: 20,
                request: {
                    vehicle_slug:this.vehicleSlug,
                    features:this.vehicleFeatures
                }
            }
        },
        created() {
            if(this.vehicleSteps){
                this.steps = this.vehicleSteps;
                this.progress = (this.vehicleSteps * 20);
            }
        },
        methods: {
            saveFeatures() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/api/vehicle/manage/features', this.request).then(response => {
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                window.location = '/vehicle/'+response.data.slug+'/details';
                            }else{
                                this.loading = false;
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            }
        }
    }
</script>
