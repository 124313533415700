<template>
    <div>
        <div class="login-top">
            <h4>{{ title }}</h4>
            <p>{{ localeLang.SUB_TITLE }}</p>
        </div>
        <div class="login-middle">
            <form @submit.prevent="socialLogin" v-if="steps === 1">
              
              <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <select name="countryCode"  v-model="request.countryCode" data-vv-as="country code" class="form-control">
                                <option :value="countryid" v-for="(country, countryid) in localeLang.FIELDS.COUNTRY">{{ country }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <input type="text" :placeholder="localeLang.FIELDS.PHONE" class="form-control"  name="phone" :class="errors.has('phone')?'is-invalid':''" v-model="request.phone" v-validate="'required|numeric|min:4|max:16'" data-vv-as="phone" key="phone">
                            <div class="invalid-feedback" v-if="errors.has('phone')">
                                {{ errors.first('phone') }}
                            </div>
                        </div>
                    </div>
                </div>
                

                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.SIGN_IN }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block" :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.SIGN_IN }}</button>
                </div>
            </form>
            <form @submit.prevent="verifyOtp" v-else>
                <div class="form-group">
                    <select name="role_id"  v-model="request.role_id" v-validate="'required'" :class="errors.has('name')?'is-invalid':''" data-vv-as="role"  class="form-control" placeholder="Select Your Role" key="name">
                        <option value="">{{ localeLang.FIELDS.ROLES.TITLE }}</option>
                        <option :value="roleid" v-for="(role, roleid) in localeLang.FIELDS.ROLES.OPTIONS">{{ role }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="errors.has('role_id')">
                        {{ errors.first('role_id') }}
                    </div>
                </div>
                <div v-if="request.role_id == 3">
                    <div class=" form-group">
                        <input type="text" :placeholder="localeLang.FIELDS.COMPANY_NAME" class="form-control"  name="company_name" :class="errors.has('company_name')?'is-invalid':''" v-model="request.company_name"  v-validate="{ required: (request.role_id == 3) ? true : false, max:100}" data-vv-as="company name" key="company_name" >
                        <div class="invalid-feedback" v-if="errors.has('company_name')">
                            {{ errors.first('company_name') }}
                        </div>
                    </div>
                    <div class="form-group">
                        <vue-google-autocomplete
                            ref="addressField"
                            name="address"
                            id="map"
                            classname="form-control"
                            :placeholder="localeLang.FIELDS.LOCATION_ADDRESS"
                            v-on:placechanged="getAddressData"
                            :country="['in', 'kh']"
                            :class="errors.has('address')?'is-invalid':''"  
                            v-model="request.address" 
                            v-validate="{ required: (request.role_id == 3) ? true : false, max:100}"
                            data-vv-as="address"
                            autocomplete="off"
                        >
                        </vue-google-autocomplete>
                        <div class="invalid-feedback" v-if="errors.has('address')">
                            {{ errors.first('address') }}
                        </div>
                    </div>
                </div>
                <div class=" form-group">
                    <input type="text" :placeholder="localeLang.FIELDS.OTP" class="form-control"  name="otp" :class="errors.has('otp')?'is-invalid':''" v-model="request.otp"  v-validate="'required|numeric|min:6|max:6'" data-vv-as="OTP" key="otp" >
                    <div class="invalid-feedback" v-if="errors.has('otp')">
                        {{ errors.first('otp') }}
                    </div>
                </div>
                <div class="text-right">
                    <a href="javascript:;" v-if="loading === false" class="forgot-pass font-weight-bold" v-on:click="resendOtp()">{{ localeLang.ACTIONS.RSEND_OTP }}</a>
                    <a href="javascript:;" v-else class="forgot-pass font-weight-bold">{{ localeLang.ACTIONS.RSEND_OTP }}</a>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.VERIFY_OTP }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block " :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.VERIFY_OTP }}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import FormMixin from '../../mixins/FormMixin';  
    import loginDictionary from '../../locale/kh/loginDictionary';
    import VueGoogleAutocomplete from '../VueGoogleAutocomplete'
    import VeeValidate, { Validator } from 'vee-validate';

    Vue.use(VeeValidate);
    
    export default {
        name:"auth-social-auth-component",
        mixins: [FormMixin],
        components:{
            VueGoogleAutocomplete: VueGoogleAutocomplete
        },
        props:[
            "localeName",
            "localeLang",
            "flashSuccess", 
            "flashError",
            'user'
        ],
        data() {
            return {
                loading : false,
                steps: 1,
                title:this.localeLang.TITLES.SIGN_IN,
                request: {
                    role_id:"",
                    countryCode:"+855",
                    phone: "",
                    otp:"",
                    otpType:1,
                    user: this.user,
                    company_name:"",
                    address:"",
                    country:"",
                    state:"",
                    city:"",
                    latitude:"",
                    longitude:"",
                    is_social:1
                }
            }
        },
 
        mounted(){
            if(this.flashSuccess){
                flash(this.flashSuccess, 'success');
            }
            if(this.flashError){
                flash(this.flashError, 'error');
            }
            if(this.localeName === 'kh'){
                Validator.localize('kh', loginDictionary);
            }
            if(this.user.hasOwnProperty('step')){
                this.steps = this.user.step;
                this.request.phone  = this.user.phone;
                this.request.countryCode  = this.user.country_code;
            }
        },
        methods: {
            async socialLogin() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/social-login-phone', this.request).then(response => {
                            this.loading = false;
                           
                            if(response.data.status === "success"){
                                    flash(response.data.message, response.data.status);
                                    this.steps = 2;
                                    this.title = this.localeLang.TITLES.VERIFY_OTP                                
                            }else if(response.data.status === "login"){
                                
                                window.location = response.data.url;
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            async verifyOtp() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/social-verify-otp', this.request).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                
                                this.resetFormFields(this.request);
                                window.location = response.data.url;
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            async resendOtp() {
                this.loading = true;
                axios.post('/resend-otp', 
                        {phone: this.request.phone,otpType:this.request.otpType}
                    ).then(response => {
                    this.loading = false;
                    flash(response.data.message, response.data.status);
                }).catch(error => {
                    this.loading = false;
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },
            /**
            * When the location found
            * @param {Object} addressData Data of the found location
            * @param {Object} placeResultData PlaceResult object
            * @param {String} id Input container ID
            */
            getAddressData: function (data, placeResultData, id) {
                this.request.address = placeResultData.formatted_address;
                this.request.country = data.country;
                this.request.state = data.administrative_area_level_1;
                this.request.city = data.locality;
                this.request.latitude = data.latitude;
                this.request.longitude = data.longitude;
            }
        }
    }
</script>
