<template>


    <div>


        <div v-if="loading">
            <spinner-component></spinner-component>
        </div>
        <div v-else>
            <div v-if="allVehicles.total > 0">
                <div class="row">

                <div class="col-sm-6" v-for="(vehicle, key) in allVehicles.data">
                  <div class="car-item">
                    <div class="list-item-img">




   <a :href="'/vehicle/'+vehicle.slug" v-if="vehicle.main_image">
        <img :src="baseUrl+'/timthumb.php?src='+vehicle.main_image.image_full_path+'&w=434&h=261&zc=0'" />
    </a>
    <a :href="'/vehicle/'+vehicle.slug" v-else>
        <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=434&h=261&zc=0'" />
    </a>

    <div class="price-block double-price" v-if="vehicle.discount_price">
    <span class="original-price">{{ vehicle.price | toCurrency }}</span>
    <span class="discount-price" v-if="vehicle.is_masked_price">
        {{ vehicle.discount_price | toMaskedPrice(vehicle.initial_masking_digits) }}*</span>
    </span>
    <span class="discount-price" v-else>{{ vehicle.discount_price | toCurrency }}*</span>
</div>
<div class="price-block" v-else>
    <span v-if="vehicle.is_masked_price">{{ vehicle.price | toMaskedPrice(vehicle.initial_masking_digits) }}*</span>
    <span v-else>{{ vehicle.price | toCurrency }}*</span>
</div>
<div class="label-block" v-if="vehicle.is_featured == 1">
   {{ localeLang.ACTIONS.BADGE_FEATURED }}
</div>
<div class="badges" v-if="vehicle.is_inspected">
    <img src="/frontend/images/badges.png">
     {{ localeLang.ACTIONS.BADGE_INSPECTED }}
</div>


                      <div class="item-icon-bar">
                        <ul>
                    <li><a href="#"><i class="fa fa-camera"></i> {{ vehicle.vehicle_images.length }}</a></li>
                <li v-if="loggedinUser">
                <a href="javascript:;"  :class="(vehicle.is_my_favourite === true) ? 'active': ''" v-on:click.prevent="toogleFavouritedealer(vehicle.id, key)">
                    <i class="fa fa-heart"></i> {{ localeLang.ACTIONS.SAVE_FAV }}
                </a>
                </li>
                <li v-else>
                    <a href="/login?intended=true" >
                        <i class="fa fa-heart"></i> {{ localeLang.ACTIONS.SAVE_FAV }}
                    </a>
                </li>

                        </ul>
                      </div>


                    </div>
                    <div class="list-item-detail">
                      <div class="enquiry-info">
                        <img src="/frontend/images/chat-icon.png" alt="chat-icon">
                       {{ localeLang.TOTAL_IQUI }}: <strong>{{ vehicle.conversations.length }}</strong>
                      </div>

                      <h4>
                          <a :href="'/vehicle/'+vehicle.slug">
                                        {{ vehicle.title }}
                            </a>
                     </h4>


                      <ul class="bullet-points bullet-list icon-set">

                        <li v-if="vehicle.odometer">
                            <i><img src="/frontend/images/odometer-icon.png" alt="odometer-icon"></i>
                            {{ vehicle.odometer | toNumber }} {{ localeLang.LABLES.KM }}
                            </li>
                        <li><i><img src="/frontend/images/body-type-icon.png" alt="odometer-icon"></i>{{ vehicle.bodystyle.name }}</li>
                        <li><i><img src="/frontend/images/engine-icon.png" alt="odometer-icon"></i>{{ vehicle.fuel_type.name }}</li>
                        <li><i><img src="/frontend/images/transmision-icon.png" alt="odometer-icon"></i>{{ vehicle.transmissions.name }}</li>
                     </ul>
                     <div class="view-info-sec d-flex">

                      <div class="inquery-btn-col">

                      <a href="javascript:;" class="enquiry-btn" v-on:click.prevent="inquiryOnVehicle(vehicle.id)">
                       {{ localeLang.ACTIONS.CONTACT }}
                          </a>
                   <a :href="'/vehicle/'+vehicle.slug" class="view-detail-btn">{{ localeLang.ACTIONS.DETAIL }}</a>

                         </div>
                    </div>
                  </div>
                </div>
              </div>

                </div>
            </div>
            <div v-else>
                <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                    <div class="no-post-inr">
                        <div class="no-post-img">
                            <img src="/frontend/images/no-post-found.jpg" alt="">
                        </div>
                        <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                        <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" v-if="allVehicles.last_page > 1">
            <div class="custom-pagination">
                <paginate
                    :page-count="allVehicles.last_page"
                    :page-range="5"
                    :click-handler="paginateVehicles"
                    :prev-text="localeLang.PAGINATION.PREV"
                    :next-text="localeLang.PAGINATION.NEXT"
                    :page-class="'page-item'"
                    :active-class="'active'"
                    :disabled-class="'disabled'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination justify-content-center'"
                >
                </paginate>
            </div>
        </div>

<!-- Custom Popup -->
        <dealer-inquiry-modal-component
            :vehicleForInquiry="vehicleForInquiry"
            :locale-lang="localeLang"
            :locale-name="localeName"
            :loggedinUser="loggedinUser">
        </dealer-inquiry-modal-component>
        <!-- Custom Popup -->

    </div>
</template>
<script>
    import bootbox from "bootbox";
    import Paginate from 'vuejs-paginate';
    import CommonMixin from '../../mixins/CommonMixin';
    import SpinnerComponent from '../../components/SpinnerComponent';
    export default {
        name:"dealer-vehicle-component",
        props: [
            "vehicles",
            "localeLang",
            "localeName",
            "loggedinUser"
        ],
        mixins: [CommonMixin],
        components:{
            Paginate:Paginate,
            SpinnerComponent:SpinnerComponent
        },
        data() {
            return {
                allVehicles: [],
                vehicleForInquiry:"",
                loading : false,
            }
        },
        mounted(){
            this.allVehicles = this.vehicles;
        },
        methods:{
            /* Get vehicles */
            async getVehicles(page = 1){
                this.loading = true;
                let url = '/saved-vehicles/?page='+page;
                axios.get(url).then(response => {
                    this.loading = false;
                    this.allVehicles = response.data.data;
                    window.$(window).scrollTop(0);
                }).catch(error => {
                    this.loading = false;
                    this.allVehicles = [];
                    window.$(window).scrollTop(0);
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

            /* delete from  favourite */
            async deleteFavourite(items, key){
                let vm = this;
                bootbox.confirm(this.localeLang.MESSAGES.REMOVE_FROM_SAVED1+" "+items[key].title+" "+this.localeLang.MESSAGES.REMOVE_FROM_SAVED2, function(result){
                    if(result){
                        let url = '/api/vehicle/manage/'+items[key].id+'/toogle-favourite';
                        axios.get(url).then(response => {
                            this.loading = false;
                            if(response.data.status === "success"){
                                Vue.delete(items, key);
                            }
                            flash(response.data.message, 'success');
                        }).catch(error => {
                            this.loading = false;
                            flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            console.log(error);
                        });
                    }
                });
            },
            /* Paginate vehicles */
            paginateVehicles(page) {
                this.getVehicles(page);
            },



             /* inquiry vehicle*/
            inquiryOnVehicle(vehicleId){
                let vm = this;
                window.$('.dealer-inquiry-modal').modal({
                    keyboard: false,
                    backdrop: 'static'
                }).on('shown.bs.modal', function (e) {
                    vm.vehicleForInquiry = vehicleId;
                }).on('hidden.bs.modal', function (e) {
                    vm.vehicleForInquiry = "";
                });
            },


             /*  toogle favourite */
    toogleFavouritedealer(vehicleId, vehicleKey) {
      var _this8 = this;

      axios.get('/api/vehicle/manage/' + vehicleId + '/toogle-favourite').then(function (response) {
        _this8.loading = false;

        if (response.data.status === 'success') {
          var vehicle = _this8.allVehicles.data[vehicleKey];
          vehicle.is_my_favourite = response.data.is_favourite;
          Vue.set(_this8.allVehicles.data, vehicleKey, vehicle);
          bootbox.alert(response.data.message);
          flash(response.data.message, response.data.status);
        } else if (response.data.status === 'warning') {
          bootbox__WEBPACK_IMPORTED_MODULE_3___default.a.alert(response.data.message);
        } else {
          flash(response.data.message, response.data.status);
        }
      })["catch"](function (error) {
        flash('Somthing went wrong. Please try again.', 'error');
        _this8.loading = false;
        console.log(error);
      });
    },


        }
    }
</script>
