export default {
	name: 'kh',
	custom:{
		type: {
	      	required: 'តំរូវអោយបំពេញប្រភេទ',
	      	max:'បំពេញប្រភេទមិនអាចលើសពី100តួអក្សរ'
	    },
		email: {
	      	email: 'ត្រូវបំពេញអ៊ីម៉ែលអោយបានត្រឹមត្រូវ',
	      	max:'បំពេញអ៊ីម៉ែលមិនអាចលើសពី100តួអក្សរ'
	    },
	    phone: {
	      	required: 'តំរូវអោយបំពេញលេខទូរស័ព្ទ',
	      	numeric:'បំពេញលេខទូរស័ព្ទអាចជាតួលេខប៉ុណ្ណោះ',
	      	min:'បំពេញលេខទូរស័ព្ទយ៉ាងតិចមាន9តួលេខ',
	      	max:'បំពេញលេខទូរស័ព្ទមិនអាចលើសពី15តួលេខ'
	    },
	    subject: {
	      	required: 'តំរូវអោយបំពេញប្រធានបទ',
	      	max:'បំពេញប្រធានបទមិនអាចលើសពី100តួអក្សរ'
	    },
	    message: {
	      	required: 'តំរូវអោយបំពេញសារ',
	      	max:'បំពេញសារមិនអាចលើសពី500តួអក្សរ'
	    }
	},
};
