export default {
	name: 'kh',
	custom:{
		name: {
	      	required: 'តំរូវអោយបំពេញឈ្មោះ',
	      	max:'បំពេញឈ្មោះមិនអាចលើសពី50តួអក្សរ'
	    },
		email: {
	      	email: 'ត្រូវបំពេញអ៊ីម៉ែលអោយបានត្រឹមត្រូវ',
	      	max:'បំពេញអ៊ីម៉ែលមិនអាចលើសពី100តួអក្សរ'
	    },
	    phone: {
	      	required: 'តំរូវអោយបំពេញលេខទូរស័ព្ទ',
	      	numeric:'បំពេញលេខទូរស័ព្ទអាចជាតួលេខប៉ុណ្ណោះ',
	      	min:'បំពេញលេខទូរស័ព្ទយ៉ាងតិចមាន9តួលេខ',
	      	max:'បំពេញលេខទូរស័ព្ទមិនអាចលើសពី15តួលេខ'
	    },
	    company_name: {
	      	required: 'តំរូវអោយបំពេញឈ្មោះក្រុមហ៊ុន',
	      	max:'ឈ្មោះក្រុមហ៊ុនមិនអាចលើសពី100តួអក្សរ'
	    },
	    address: {
	      	required: 'តំរូវអោយបំពេញអាសយដ្ឋាន',
	      	max:'បំពេញអាសយដ្ឋានមិនអាចលើសពី100តួអក្សរ'
	    }
	},
};
