<template>
    <div class="detail-slider">
        <div class="label-block" v-show="isFeatured">
            {{ localeLang.FEATURED }}
        </div>
        <div class="badges detail-badges" v-if="isInspected">
            <img src="/frontend/images/badges.png">
            {{ localeLang.INSPECTED }}
        </div>
        <div id="slider" class="flexslider car-thumb-text" style="width:100%" ref="slider">
            <ul class="slides" v-if="vehicleImages">
                <li v-for="(vehicleImage, imageIndex) in vehicleImages">
                    <a data-lightbox="image" :href="vehicleImage.image_full_path">
                        <img :src="baseUrl+'/timthumb.php?src='+vehicleImage.image_full_path+'&w=910&h=468&zc=0'" />
                    </a>
                </li>
            </ul>
            <ul class="slides" v-else>
                <li>
                    <img src="/frontend/images/no-img-415x234.jpg" alt="no image" />
                </li>
            </ul>
        </div>
        <div id="carousel" class="flexslider car-thumb-sec" style="width:100%" ref="carousel">
            <ul class="slides" v-if="vehicleImages">
                <li v-for="vehicleImage in vehicleImages">
                    <div class="car-thumb-col">
                        <img :src="baseUrl+'/timthumb.php?src='+vehicleImage.image_full_path+'&w=135&h=135&zc=1'">
                    </div>
                </li>
            </ul>
            <ul class="slides" v-else>
                <li>
                    <div class="car-thumb-col">
                        <img src="/frontend/images/no-img-100x92.jpg" alt="no image">
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import 'lightbox2/dist/css/lightbox.min.css';
    import 'flexslider';
    import lightbox from 'lightbox2';
    import CommonMixin from '../../mixins/CommonMixin';
    export default {
        name:"vehicle-images-component",
        props:[
            "vehicleImages", 
            "isFeatured",
            "isInspected",
            "localeLang",
        ],
        mixins: [CommonMixin],
        data() {
            return {
                loading : false,
            }
        },
        mounted() {
            let vm = this;
            Vue.nextTick(function(){
                window.$(vm.$refs.carousel).flexslider({
                    animation: "slide",
                    controlNav: false,
                    animationLoop: false,
                    slideshow: false,
                    itemWidth: 166.5,
                    itemMargin:0,
                    directionNav: true,
                    asNavFor: '#slider'
                });
                window.$(vm.$refs.slider).flexslider({
                    animation: "slide",
                    margin:0,
                    controlNav: false,
                    animationLoop: false,
                    slideshow: false,
                    directionNav: true,
                    sync: "#carousel"
                });

                lightbox.option({
                    'resizeDuration': 200,
                    'wrapAround': true
                });

                var hash = window.location.hash;
                if(hash){
                    window.$('ul.nav a[href="' + hash + '"]').tab('show');
                    var scrollmem = window.$('body').scrollTop();
                    window.$('html, body').scrollTop(scrollmem);
                }

            }.bind(vm));
        },
        destroyed: function () {
            window.$(vm.$refs.carousel).flexslider('destroy');
            window.$(vm.$refs.slider).flexslider('destroy');
        }
    }
</script>
