export default {
    name: 'kh',
    custom: {
        address: {
            required: 'តំរូវអោយបំពេញអាសយដ្ឋាន',
            max: 'បំពេញអាសយដ្ឋានមិនអាចលើសពី100តួអក្សរ'
        },
        odometer: {
            required: 'តំរូវអោយបំពេញកុងទ័រគីឡូម៉ែត្រ',
            decimal: 'បំពេញកុងទ័រគីឡូម៉ែត្រអាចជាតួលេខ និងអាចមានទសភាគ2ខ្ទង់',
            max: 'បំពេញកុងទ័រគីឡូម៉ែត្រមិនអាចលើសពី10តួអក្សរ'
        },
        interior_color_id: {
            required: 'តំរូវអោយបំពេញពណ៌ផ្ទៃខាងក្នុង',
        },
        exterior_color_id: {
            required: 'តំរូវអោយបំពេញពណ៌ផ្ទៃខាងក្រៅ',
        },
        lifestyle_id: {
            required: 'តំរូវអោយបំពេញសំរាប់គោលបំណង',
        },
        registration_plate_number: {
            max: 'បំពេញស្លាកលេខរថយន្តមិនអាចលើសពី9តួអក្សរ'
        },
        fuel_economy_id: {
            required: 'តំរូវអោយបំពេញកំរិតប្រើប្រេងឥន្ធនៈ',
        },
        contact_phone_number: {
            required: 'តំរូវអោយបំពេញលេខទូរស័ព្ទ',
            numeric: 'បំពេញលេខទូរស័ព្ទអាចជាតួលេខប៉ុណ្ណោះ',
            min: 'បំពេញលេខទូរស័ព្ទយ៉ាងតិចមាន9តួលេខ',
            max: 'បំពេញលេខទូរស័ព្ទមិនអាចលើសពី15តួលេខ'
        },
        english_comment: {
            max: 'បំពេញមតិជាសាភាអង់គ្លេសមិនអាចលើសពី500តួអក្សរ'
        },
        khmer_comment: {
            max: 'បំពេញមតិជាសាភាខ្មែរមិនអាចលើសពី500តួអក្សរ'
        },
        sale_price: {
            required: 'តំរូវអោយបំពេញតំលៃដាក់លក់',
            numeric: 'បំពេញតំលៃដាក់លក់អាចជាតួលេខប៉ុណ្ណោះ',
            min: 'បំពេញតំលៃដាក់លក់យ៉ាងតិចមាន3តួលេខ',
            max: 'បំពេញតំលៃដាក់លក់មិនអាចលើសពី7តួលេខ',
            min_value: 'បំពេញតំលៃដាក់លក់ត្រូវលើសពីតំលៃដែលបញ្ចុះ'
        },
        discounted_price: {
            numeric: 'បំពេញតំលៃដែលបញ្ចុះអាចជាតួលេខប៉ុណ្ណោះ',
            min: 'បំពេញតំលៃដែលបញ្ចុះយ៉ាងតិចមាន3តួលេខ',
            max: 'បំពេញតំលៃដែលបញ្ចុះមិនអាចលើសពី7តួលេខ',
            max_value: 'បំពេញតំលៃដែលបញ្ចុះមិនត្រូវលើសតំលៃដាក់លក់'
        },
        initial_masking_digits: {
            required: 'តំរូវអោយជ្រើសរើសចំនួនតួលេខខាងមុខដែលគ្មានការបិទបាំង នៅពេលដែលជ្រើសយកការបិទបាំងតំលៃ',
        }
    }
};