<template>
    <div>
        <div class="login-top">
            <h4>{{ title }}</h4>
            <p>{{ localeLang.SUB_TITLE }}</p>
        </div>
        <div class="login-middle">
            <form @submit.prevent="loginToAccount" v-if="steps === 1">
                <div class="form-group">                    
                    <input type="text" class="form-control" name="email" :class="errors.has('email')?'is-invalid':''" v-model="request.email" :placeholder="localeLang.FIELDS.EMAIL_PHONE" v-validate="'required|max:50'" data-vv-as="Email/Phone">
                    <div class="invalid-feedback" v-if="errors.has('email')">
                        {{ errors.first('email') }}
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" name="password" :class="errors.has('password')?'is-invalid':''" :placeholder="localeLang.FIELDS.PASSWORD" v-model="request.password" v-validate="'required|min:8|max:12'" data-vv-as="password">
                    <div class="invalid-feedback" v-if="errors.has('password')">
                        {{ errors.first('password') }}
                    </div>
                </div>  
                <div class="text-right">
                    <a href="/password/email" class="forgot-pass font-weight-bold">
                        {{ localeLang.ACTIONS.FORGOT_PASSWORD }}
                    </a>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.SIGN_IN }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block" :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.SIGN_IN }}</button>
                </div>
            </form>
            <form @submit.prevent="verifyOtp" v-else>
                <div class=" form-group">
                    <input type="text" :placeholder="localeLang.FIELDS.OTP" class="form-control"  name="otp" :class="errors.has('otp')?'is-invalid':''" v-model="request.otp"  v-validate="'required|numeric|min:6|max:6'" data-vv-as="OTP" key="otp" >
                    <div class="invalid-feedback" v-if="errors.has('otp')">
                        {{ errors.first('otp') }}
                    </div>
                </div>
                <div class="text-right">
                    <a href="javascript:;" v-if="loading === false" class="forgot-pass font-weight-bold" v-on:click="resendOtp()">{{ localeLang.ACTIONS.RSEND_OTP }}</a>
                    <a href="javascript:;" v-else class="forgot-pass font-weight-bold">{{ localeLang.ACTIONS.RSEND_OTP }}</a>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.VERIFY_OTP }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block " :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.VERIFY_OTP }}</button>
                </div>
            </form>
            <div class="or-seperate">
                <span class="register">
                    <strong>{{ localeLang.LABELS.OR }}</strong>
                </span>
            </div>
            <div class="register-option d-flex">
                <a href="/login/facebook" class="facebook">
                    <i class="fa fa-facebook"></i>
                    <span>{{ localeLang.ACTIONS.LOGIN_WITH_FB }}</span>
                </a>
                <a href="/login/google" class="google-plus">
                    <i class="fa fa-google-plus"></i>
                    <span>{{ localeLang.ACTIONS.LOGIN_WITH_GOOGLE }}</span>
                </a>
            </div> 
            <span class="form-bottom">
                {{ localeLang.LABELS.DONT_HAVE_ACCOUNT }}
                <a href="/register" class="join">
                    {{ localeLang.ACTIONS.SIGN_UP }}
                </a>
            </span> 
        </div>
    </div>
</template>
<script>
    import FormMixin from '../../mixins/FormMixin';  
    import loginDictionary from '../../locale/kh/loginDictionary';
    import VeeValidate, { Validator } from 'vee-validate';

    Vue.use(VeeValidate);
    
    export default {
        name:"auth-login-component",
        mixins: [FormMixin],
        props:[
            "localeName",
            "localeLang",
            "flashSuccess", 
            "flashError"
        ],
        data() {
            return {
                loading : false,
                steps: 1,
                title:this.localeLang.TITLES.SIGN_IN,
                request: {
                    email: "",
                    password: "",
                    remember: "",
                    otp:"",
                    otpType:""
                }
            }
        },
        mounted(){
            if(this.flashSuccess){
                flash(this.flashSuccess, 'success');
            }
            if(this.flashError){
                flash(this.flashError, 'error');
            }
            if(this.localeName === 'kh'){
                Validator.localize('kh', loginDictionary);
            }
        },
        methods: {
            async loginToAccount() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/login', this.request).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                if(response.data.s_code === 105){
                                    this.steps = 2;
                                    this.request.otpType = 1;
                                    this.request.phone = response.data.phone;
                                    this.title = this.localeLang.TITLES.VERIFY_OTP
                                }else{
                                    window.location.href = response.data.target_url;
                                }
                                
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            async verifyOtp() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/verify-otp', {
                            phone: this.request.phone,
                            otp:this.request.otp,
                            otpType:this.request.otpType
                            }
                        ).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                
                                this.resetFormFields(this.request);
                                window.location = "/login";
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            async resendOtp() {
                this.loading = true;
                axios.post('/resend-otp', 
                        {
                            phone: this.request.phone,
                            otpType:this.request.otpType
                        }
                    ).then(response => {
                    this.loading = false;
                    if(response.data.status === 'success'){
                        this.request.otp = "";
                        this.$validator.reset();
                    }
                    flash(response.data.message, response.data.status);
                }).catch(error => {
                    this.loading = false;
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

        }
    }
</script>
