<template>
    <div>
        <div v-if="loading">
            <spinner-component></spinner-component>
        </div>
        <div v-else>
            <div v-if="allNews.total > 0">
                <ul class="cars-news-block row">
                    <li v-for="news in allNews.data">
                        <div class="block-content">
                            <div v-if="news.news_type">
                                <a :href="'/news/'+news.slug" class="video-block">
                                    <iframe :src="news.video"></iframe>
                                </a>
                            </div>
                            <div v-else>
                                <a :href="'/news/'+news.slug" class="img-block" v-if="news.image">
                                    <img :src="baseUrl+'/timthumb.php?src='+news.image_full_path+'&w=408&h=236&zc=0'" /> 
                                </a>
                                <a :href="'/news/'+news.slug" class="img-block" v-else>
                                    <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=408&h=236&zc=0'" /> 
                                </a>
                            </div>
                            <div class="text-block">
                                <ul class="d-flex align-items-center flex-wrap review-row">
                                    <li>
                                        <a class="btn-custom text-uppercase" :href="'/news/'+news.slug">
                                            {{ localeLang.TITLE }}
                                        </a>
                                    </li>
                                </ul>
                                <div class="review-desc">
                                    <a :href="'/news/'+news.slug">{{ news.title | str_limit(30) }}</a>
                                </div>
                                <p>{{ news.excerpt }}</p>
                                <div class="review-date">{{ news.created_at | humanDate }}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="row" v-else> 
                <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                    <div class="no-post-inr">
                        <div class="no-post-img">
                            <img src="/frontend/images/no-post-found.jpg" alt="">
                        </div>
                        <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                        <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" v-if="allNews.last_page > 1">
            <div class="custom-pagination">
                <paginate
                    :page-count="allNews.last_page"
                    :page-range="5"
                    :click-handler="paginateNews"
                    :prev-text="localeLang.PAGINATION.PREV"
                    :next-text="localeLang.PAGINATION.NEXT"
                    :page-class="'page-item'"
                    :active-class="'active'"
                    :disabled-class="'disabled'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination justify-content-center'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>
<script>
    import bootbox from "bootbox";
    import Paginate from 'vuejs-paginate';
    import CommonMixin from '../../mixins/CommonMixin';
    import SpinnerComponent from '../../components/SpinnerComponent';
    export default {
        name:"news-list-component",
        props: [
            "news",
            "localeLang"
        ],
        mixins: [CommonMixin],
        components:{
            Paginate:Paginate,
            SpinnerComponent:SpinnerComponent
        },
        data() {
            return {
                allNews: [],
                loading : false,
            }
        },
        mounted(){
            this.allNews = this.news;
        },
        methods:{
            /* Get news */
            async getNews(page = 1){
                this.loading = true;
                let url = '/news?page='+page;
                axios.get(url).then(response => {
                    this.loading = false;
                    this.allNews = response.data.data;
                    window.$(window).scrollTop(0);
                }).catch(error => {
                    this.loading = false;
                    this.allNews = [];
                    window.$(window).scrollTop(0);
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },
            
            /* Paginate news */
            paginateNews(page) {
                this.getNews(page);
            },
        }
    }
</script>
