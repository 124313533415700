<template>
    <div class="create-ads-inner global-form create-car-ads-form">
        <div class="row">
            <div class="col-12">
                <ul class="add-steps d-flex">
                    <li :class="(steps >= 1) ? 'complete': ''">
                        <a :href="(steps >= 1) ? '/vehicle/'+vehicle.slug+'/general' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-car"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.GENERAL_INFO }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 2) ? 'complete': ''">
                        <a :href="(steps >= 2) ? '/vehicle/'+vehicle.slug+'/features' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-list"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.SELECT_FEATURES }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 3) ? 'complete': ''">
                        <a :href="(steps >= 3) ? '/vehicle/'+vehicle.slug+'/details' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-user-plus"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ADD_DETAILS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 4) ? 'complete': ''">
                        <a :href="(steps >= 4) ? '/vehicle/'+vehicle.slug+'/photos' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-camera-retro"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.ATTACH_PHOTOS }}</h3>
                        </a>
                    </li>
                    <li :class="(steps >= 5) ? 'complete': ''">
                        <a :href="(steps >= 5) ? '/vehicle/'+vehicle.slug+'/preview' : 'javascript:;'">
                            <span class="step-icon">
                                <i class="fa fa-shopping-basket"></i>
                            </span>
                            <h3>{{ localeLang.STEPS.REVIEW_COMPLETE }}</h3>
                        </a>
                    </li>
                </ul>
                <div class="progress mx-auto" :data-value="progress">
                    <span class="progress-left">
                        <span class="progress-bar border-primary"></span>
                    </span>
                    <span class="progress-right">
                        <span class="progress-bar border-primary"></span>
                    </span>
                    <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="value-text">{{ progress }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-block-outer">
            <div class="step-box1">
                <div class="row">
                    <div class="col-12">
                        <div class="car-find-sub-hd">
                            {{ localeLang.GENERAL.TITLE }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.MAKE }} <span class="required">*</span>
                            </label>
                            <div class="select-outer">
                                <select class="form-control sltd2" name="make_id" :class="errors.has('make_id')?'is-invalid':''" v-model="request.make_id" v-validate="'required'" data-vv-as="make" ref="makeField">
                                    <option value="">{{ localeLang.PLACEHOLDERS.SELECT_FIELDS }}</option>
                                    <option v-for="(option, index) in makes" :value="option.id">{{ option.name }}</option>
                                </select>
                                <div class="invalid-feedback" v-if="errors.has('make_id')">
                                    {{ errors.first('make_id') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.MODEL }} <span class="required">*</span>
                            </label>
                            <div class="select-outer">
                                <select class="form-control sltd2" name="model_id" :class="errors.has('model_id')?'is-invalid':''" v-model="request.model_id" v-validate="'required'" data-vv-as="model" ref="modelField">
                                    <option value="">{{ localeLang.PLACEHOLDERS.SELECT_FIELDS }}</option>
                                    <option v-for="(option, index) in models" :value="option.id">{{ option.name }}</option>
                                </select>
                                <div class="invalid-feedback" v-if="errors.has('model_id')">
                                    {{ errors.first('model_id') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.BADGE }}
                            </label>
                            <div class="select-outer">
                                <select class="form-control sltd2" name="badge_id" v-model="request.badge_id" ref="badgeField">
                                    <option value="">{{ localeLang.PLACEHOLDERS.SELECT_FIELDS }}</option>
                                    <option v-for="(option, index) in badges" :value="option.id">{{ option.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.SERIES }}
                            </label>
                            <div class="select-outer">
                                <select class="form-control sltd2" name="series_id" v-model="request.series_id" ref="seriesField">
                                    <option value="">{{ localeLang.PLACEHOLDERS.SELECT_FIELDS }}</option>
                                    <option v-for="(option, index) in series" :value="option.id">{{ option.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.BODY_TYPE }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="body_type_id"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('body_type_id') ? errors.first('body_type_id') : ''"
                                :options="bodyTypes"
                                :default-option="vehicle ? vehicle.body_styles_id : ''"
                                v-validate="'required'"
                                data-vv-as="body type"
                                v-model="request.body_type_id"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.FUEL_TYPE }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="fuel_type_id"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('fuel_type_id') ? errors.first('fuel_type_id') : ''"
                                :options="fuelTypes"
                                :default-option="vehicle ? vehicle.fuel_types_id : ''"
                                v-validate="'required'"
                                data-vv-as="fuel type"
                                v-model="request.fuel_type_id"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.DRIVE_TYPE }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="drive_type_id"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('drive_type_id') ? errors.first('drive_type_id') : ''"
                                :options="driveTypes"
                                :default-option="vehicle ? vehicle.drive_types_id : ''"
                                v-validate="'required'"
                                data-vv-as="drive type"
                                v-model="request.drive_type_id"

                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.TRANSMISSION }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="transmission_id"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('transmission_id') ? errors.first('transmission_id') : ''"
                                :options="transmissions"
                                :default-option="vehicle ? vehicle.transmissions_id : ''"
                                v-validate="'required'"
                                data-vv-as="transmission"
                                v-model="request.transmission_id"

                            ></field-select2-component>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.DOORS }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="doors"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('doors') ? errors.first('doors') : ''"
                                :options="numberRange(1, 5)"
                                :default-option="vehicle ? vehicle.doors : ''"
                                v-validate="'required'"
                                data-vv-as="doors"
                                v-model="request.doors"

                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.SEATS }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="seats"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('seats') ? errors.first('seats') : ''"
                                :options="numberRange(1, 10)"
                                :default-option="vehicle ? vehicle.seats : ''"
                                v-validate="'required'"
                                data-vv-as="seats"
                                v-model="request.seats"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.GEARS }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="gears"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('gears') ? errors.first('gears') : ''"
                                :options="numberRange(1, 10)"
                                :default-option="vehicle ? vehicle.gears : ''"
                                v-validate="'required'"
                                data-vv-as="gears"
                                v-model="request.gears"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.CYLINDERS }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="cylinders"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('cylinders') ? errors.first('cylinders') : ''"
                                :options="numberRange(1, 12)"
                                :default-option="vehicle ? vehicle.cylinders_id : ''"
                                v-validate="'required'"
                                data-vv-as="cylinder"
                                v-model="request.cylinders"
                            ></field-select2-component>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.YEAR_BUILT }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="year_built"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('year_built') ? errors.first('year_built') : ''"
                                :options="yearsRange(1900,3)"
                                :default-option="vehicle ? vehicle.year_build : ''"
                                v-validate="'required'"
                                data-vv-as="year built"
                                v-model="request.year_built"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.MONTH_BUILT.TITLE }}
                            </label>
                            <field-select2-component
                                name="month_built"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :options="localeLang.GENERAL.FIELDS.MONTH_BUILT.OPTIONS"
                                :default-option="vehicle ? vehicle.month_build : ''"
                                v-model="request.month_built"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.TURBO.TITLE }} <span class="required">*</span>
                            </label>
                            <field-select2-component
                                name="turbo"
                                :placeholder="localeLang.PLACEHOLDERS.SELECT_FIELDS"
                                :data-v-error="errors.has('turbo') ? errors.first('turbo') : ''"
                                :options="localeLang.GENERAL.FIELDS.TURBO.OPTIONS"
                                :default-option="vehicle ? vehicle.turbo : ''"
                                v-validate="'required'"
                                data-vv-as="turbo"
                                v-model="request.turbo"
                            ></field-select2-component>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.ENGINE_CAPACITY }} <span class="required">*</span>
                            </label>
                            <input type="text" class="form-control" :placeholder="localeLang.GENERAL.FIELDS.ENGINE_CAPACITY" :class="errors.has('engine_capacity')?'is-invalid':''" name="engine_capacity" v-model="request.engine_capacity" v-validate="'required|decimal:2|max:4'" data-vv-as="engine capacity" >
                            <div class="invalid-feedback" v-if="errors.has('engine_capacity')">
                                {{ errors.first('engine_capacity') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>
                                {{ localeLang.GENERAL.FIELDS.VIN_NUMBER }}
                            </label>
                            <input type="text" class="form-control" :placeholder="localeLang.GENERAL.FIELDS.VIN_NUMBER" :class="errors.has('chassis_number')?'is-invalid':''" name="chassis_number" v-model="request.chassis_number" v-validate="'max:17'" data-vv-as="VIN/chassis number" >
                            <div class="invalid-feedback" v-if="errors.has('chassis_number')">
                                {{ errors.first('chassis_number') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="d-block">{{ localeLang.GENERAL.FIELDS.IS_CAR_NEW.TITLE }}</label>
                        <div class="custom-radio radio-inline form-group">
                            <div class="radio-inner">
                                <input type="radio" id="car_type_1" name="car_type" value="1" v-model="request.type">
                                <label for="car_type_1">{{ localeLang.GENERAL.FIELDS.IS_CAR_NEW.OPTIONS.YES }}</label>
                            </div>
                            <div class="radio-inner">
                                <input type="radio" id="car_type_2" name="car_type" value="2" v-model="request.type">
                                <label for="car_type_2">{{ localeLang.GENERAL.FIELDS.IS_CAR_NEW.OPTIONS.NO }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-outer text-right">
            <button type="button" class="submit-btn" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                <span>{{ localeLang.ACTIONS.NEXT }}</span>
            </button>
            <button type="button" class="submit-btn" v-on:click="saveVehicle()" :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.NEXT }}</button>
        </div>
    </div>
</template>
<script>
    import CommonMixin from '../../../mixins/CommonMixin';
    import VehicleFormMixin from '../../../mixins/VehicleFormMixin';
    import FormMixin from '../../../mixins/FormMixin';
    import FieldSelect2Component from '../../FieldSelect2Component';
    import mangeAdGeneralDictionary from '../../../locale/kh/mangeAdGeneralDictionary';
    import VeeValidate, { Validator } from 'vee-validate';

    Vue.use(VeeValidate);

    export default {
        name:"user-manage-ad-general-component",
        props:[
            "types",
            "makes",
            "bodyTypes",
            "fuelTypes",
            "transmissions",
            "driveTypes",
            "makeModels",
            "modelBadges",
            "badgeSeries",
            "vehicle",
            "localeName",
            "localeLang"
        ],
        components:{
            FieldSelect2Component: FieldSelect2Component
        },
        mixins: [CommonMixin, VehicleFormMixin, FormMixin],
        data() {
            return {
                loading : false,
                steps: 0,
                progress: 0,
                request: {
                    title: "",
                    make_id:"",
                    model_id:"",
                    badge_id:"",
                    series_id:"",
                    body_type_id:"",
                    fuel_type_id:"",
                    drive_type_id:"",
                    transmission_id:"",
                    doors:"",
                    seats:"",
                    gears:"",
                    cylinders:"",
                    year_built:"",
                    month_built:"",
                    turbo:"",
                    engine_capacity:"",
                    chassis_number:"",
                    type:1
                }
            }
        },
        computed: {
            vehicle_title: function () {
                let title = "";

                if(this.request.year_built){
                    title += this.request.year_built;
                }

                if(this.request.make_id){
                    let make = this.getValueFormObjectById(this.makes, this.request.make_id, 'name');
                    title += " "+make;
                }

                if(this.request.model_id){
                    let model = this.getValueFormObjectById(this.models, this.request.model_id, 'name');
                    title += " "+model;
                }

                if(this.request.badge_id){
                    let badge = this.getValueFormObjectById(this.badges, this.request.badge_id, 'name');
                    title += " "+badge;
                }

                if(this.request.series_id){
                    let series = this.getValueFormObjectById(this.series, this.request.series_id, 'name');
                    title += " "+series;
                }

                return title;
            }
        },
        mounted(){
            let vm = this;
            Vue.nextTick(() => {
                /* make field */
                window.$(this.$refs.makeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 10
                }).on("select2:select", function(e) {
                    vm.models = [];
                    vm.badges = [];
                    vm.series = [];
                    vm.request.make_id = this.value;
                    vm.request.model_id = "";
                    vm.request.badge_id = "";
                    vm.request.series_id = "";
                    if(vm.request.make_id){
                        vm.getModelsByMakeId(vm.request.make_id);
                    }
                });
                /* model field */
                window.$(this.$refs.modelField).select2({
                    width:"100%",
                    minimumResultsForSearch: 10
                }).on("select2:select", function(e) {
                    vm.badges = [];
                    vm.series = [];
                    vm.request.model_id = this.value;
                    vm.request.badge_id = "";
                    vm.request.series_id = "";
                    if(vm.request.model_id){
                        vm.getBadgesByModelId(vm.request.model_id);
                    }
                });
                /* badge field */
                window.$(this.$refs.badgeField).select2({
                    width:"100%",
                    minimumResultsForSearch: 10
                }).on("select2:select", function(e) {
                    vm.series = [];
                    vm.request.badge_id = this.value;
                    if(vm.request.badge_id){
                        vm.getSeriesByBadgeId(vm.request.badge_id);
                    }
                });
                /* series field */
                window.$(this.$refs.seriesField).select2({
                    width:"100%",
                    minimumResultsForSearch: 10
                }).on("select2:select", function(e) {
                    vm.request.series_id = this.value;
                });

                if(this.vehicle){
                    this.request.make_id = this.vehicle.makes_id;
                    this.request.model_id = this.vehicle.models_id;
                    this.request.badge_id = this.vehicle.badge_id;
                    this.request.series_id = this.vehicle.series_id;
                    window.$(this.$refs.makeField).val(this.vehicle.makes_id).trigger('change');
                    window.$(this.$refs.modelField).val(this.vehicle.models_id).trigger('change');
                    window.$(this.$refs.badgeField).val(this.vehicle.badge_id).trigger('change');
                    window.$(this.$refs.seriesField).val(this.vehicle.series_id).trigger('change');
                }

            });

            if(this.localeName === 'kh'){
                Validator.localize('kh', mangeAdGeneralDictionary);
            }
        },
        created(){
            if(this.makeModels){
                this.models = this.makeModels;
            }
            if(this.modelBadges){
                this.badges = this.modelBadges;
            }
            if(this.badgeSeries){
                this.series = this.badgeSeries;
            }
            if(this.vehicle){
                this.steps = this.vehicle.steps;
                this.progress = (this.vehicle.steps * 20);

                this.request.vehicle_slug = this.vehicle.slug;
                this.request.engine_capacity =  this.vehicle.engine_capacity;
                this.request.chassis_number =  this.vehicle.chassis_number;
                if(this.vehicle.category_id){
                    this.request.type =  this.vehicle.category_id;
                }
            }else{
                this.progress = (this.steps*20);
            }
        },
        methods: {
            saveVehicle() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.request.title = this.vehicle_title;
                        axios.post('/api/vehicle/manage/general-info', this.request).then(response => {
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                window.location = '/vehicle/'+response.data.slug+'/features';
                            }else{
                                this.loading = false;
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            }
        },
        destroyed: function () {
            window.$(this.$refs.makeField).off().select2('destroy');
            window.$(this.$refs.modelField).off().select2('destroy');
            window.$(this.$refs.badgeField).off().select2('destroy');
            window.$(this.$refs.seriesField).off().select2('destroy');
        }
    }
</script>
