<template>
    <div class="select-outer">
        <select class="form-control sltd2"
            :name="name"
            :class="dataVError ? 'is-invalid': ''"
            @input="updateValue" 
            @change="updateValue" 
            @blur="$emit('blur')" 
            @focus="$emit('focus')"
            ref="select2_field"
        >
            <option value="">{{ placeholder ? placeholder : 'Select...' }}</option>
            <option v-for="(option, index) in options" :value="option.id">{{ option.name }}</option>
        </select>
        <div class="invalid-feedback" v-if="dataVError">
            {{ dataVError }}
        </div>
    </div>
</template>
<script>
    import 'select2/dist/css/select2.min.css';
    import select2 from 'select2';
    export default {
        name:"field-select2-component",
        props: {
            name: {
                type:String
            },
            placeholder: {
                type:String
            },
            options:{
                type:Array
            },
            defaultOption: {
                type:String | Number
            },
            dataVError: {
                type:String
            }
        },
        mounted(){
            let vm = this;
            Vue.nextTick(() => {
                window.$(this.$refs.select2_field).select2({
                    width:"100%",
                    minimumResultsForSearch: 10
                }).on("select2:select", function(e) {
                    window.$(vm.$refs.select2_field).val(this.value);
                    vm.$emit('input', this.value);
                });
                if(this.defaultOption){
                    window.$(this.$refs.select2_field).val(this.defaultOption).trigger('change');
                    vm.$emit('input', this.defaultOption);
                }
            });
        },
        methods: {
            updateValue (e) {
                this.$emit('input', e.target.value);
            }
        },
        destroyed: function () {
            window.$(this.$refs.select2_field).off().select2('destroy');
        }
    }
</script>
