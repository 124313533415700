<template>
    <div class="padd-t-80 padd-b-80 news-section similar-cars-sec">
        <div class="container">
            <div class="main-heading">
                <h2>{{ localeLang.TITLE }}</h2>
            </div>
            <div class="owl-carousel similar-cars-slider" ref="similarCarsSlider">
                <div class="items" v-for="similarVehicle in similarVehicles">
                    <div class="block-content">
                        <a :href="'/vehicle/'+similarVehicle.slug">
                            <div class="img-block"> 
                                <img :src="baseUrl+'/timthumb.php?src='+similarVehicle.main_image.image_full_path+'&w=408&h=236&zc=0'" v-if="similarVehicle.main_image" alt="car" /> 
                                <img src="/frontend/images/no-img-415x234.jpg" alt="car" v-else /> 
                            </div>
                        </a>
                        <div class="text-block">
                            <div class="review-desc">
                                <a :href="'/vehicle/'+similarVehicle.slug">
                                {{ similarVehicle.title }}
                                </a>
                            </div>
                            <div class="car-loaction-place">
                                <i class="fa fa-map-marker"></i> {{ similarVehicle.address }}
                            </div>
                            <a :href="'/vehicle/'+similarVehicle.slug" class="read-more">
                                {{ localeLang.ACTION_TEXT }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	import 'owl.carousel';
    import CommonMixin from '../../mixins/CommonMixin';
    export default {
        name:"vehicle-similar-component",
        props:[
            "similarVehicles",
            "localeLang"
        ],
        mixins: [CommonMixin],
        mounted() {
            let vm = this;
            Vue.nextTick(function(){
                window.$(this.$refs.similarCarsSlider).owlCarousel({
					margin: 40,
                    loop: false,
                    rewind: true,
                    dot:false,
                    nav:true,
                    autoplay:false,
                    responsive: {
                        0:{
                            items:1 
                        },
                        767:{
                            items:2
                        },
                        1024:{
                            items:3
                        }
                    }
				});
                
            }.bind(vm));
        },
        destroyed: function () {
            window.$(this.$refs.similarCarsSlider).owlCarousel('destroy');
        }
    }
</script>
