<template>
    <social-sharing
        :url="vehicleDeeplink"
        :title="vehicleTitle"
        :description="vehicleDescription"
        :media="vehicleMedia"
        quote="S.P Car Market"
        :hashtags="vehicleMake+','+vehicleModel+','+vehicleBadge+','+vehicleSeries"
        twitter-user="carmarket"
        inline-template>
        <ul class="mb-0 d-flex flex-wrap align-items-center overview-socila-links">
            <li>{{ $parent.vehicleSharerTitle }}</li>
            <li>
                <network network="facebook">
                    <a href="javascript:;">
                        <img src="/frontend/images/fb-icon.png" alt="fb-icon">
                    </a>
                </network>
            </li>
            <li>
                <network network="twitter">
                    <a href="javascript:;">
                        <img src="/frontend/images/tw-icon.png" alt="tw-icon">
                    </a>
                </network>
            </li>
            <li>
                <network network="whatsapp">
                    <a href="javascript:;">
                        <img src="/frontend/images/what-app-icon.png" alt="what-app-icon">
                    </a>
                </network>
            </li>
            <li>
                <network network="email">
                    <a href="javascript:;">
                        <img src="/frontend/images/msg-icon.png" alt="msg-icon">
                    </a>
                </network>
            </li>
        </ul>
    </social-sharing>
</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    import SocialSharing from 'vue-social-sharing';
    export default {
        name:"vehicle-social-share-component",
        props:[
            'vehicleDeeplink',
            "vehicleTitle",
            "vehicleDescription",
            "vehicleSlug",
            "vehicleMedia",
            "vehicleMake",
            "vehicleModel",
            "vehicleBadge",
            "vehicleSeries",
            "vehicleSharerTitle"
        ],
        components:{
            SocialSharing: SocialSharing
        },
        mixins: [CommonMixin]
    }
</script>
