<template>
    <div>
        <div class="car-find-sub-hd"> 
            {{ localeLang.SUBSCIPTION_PAYMENT.LABLES.STRIPE.TITLE }} 
        </div>
        <div class="row" v-show="paymentMethods.length > 0">
            <div class="col-12">
                <div class="custom-radio radio-inline form-group">
                    <div class="radio-inner">
                        <input type="radio" id="payment_method_as_1" name="payment_method_as" value="1" v-model="request.payment_method_as" checked>
                        <label for="payment_method_as_1">{{ localeLang.SUBSCIPTION_PAYMENT.LABLES.NEW_PAYMENT_METHOD }}</label>
                    </div>
                    <div class="radio-inner">
                        <input type="radio" id="payment_method_as_2" name="payment_method_as" value="2" v-model="request.payment_method_as" >
                        <label for="payment_method_as_2">{{ localeLang.SUBSCIPTION_PAYMENT.LABLES.SAVED_PAYMENT_METHOD }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="request.payment_method_as == 1">
            <div class="form-group">
                <label>
                    {{ localeLang.SUBSCIPTION_PAYMENT.LABLES.STRIPE.NAME_ON_CARD }}
                </label>
                <input type="text" class="form-control" name="name_on_card"  :placeholder="localeLang.SUBSCIPTION_PAYMENT.PLACEHOLDERS.NAME_ON_CARD" :class="errors.has('name_on_card')?'is-invalid':''" v-model="request.card_holder_name" v-validate="'required|alpha_spaces|max:50'" data-vv-as="Name on card">
                <div class="invalid-feedback" v-if="errors.has('name_on_card')">
                    {{ errors.first('name_on_card') }}
                </div>
            </div>
            <!-- Stripe Elements Placeholder -->
            <div class="form-group">
                <div id="stripe-card-element" ref="stripeCardElement"></div>
            </div>
            <div class="form-group wd1">
                <button type="button" class="btn cmn-btn blue-btn" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                    <span>{{ localeLang.SUBSCIPTION_PAYMENT.ACTIONS.SUBSCRIBE }}</span>
                </button>
                <button type="button" class="btn cmn-btn blue-btn" v-on:click="setupPaymentMethod()" v-else>
                    {{ localeLang.SUBSCIPTION_PAYMENT.ACTIONS.SUBSCRIBE }}
                </button>
            </div>
        </div>
        <div v-show="((paymentMethods.length > 0) && (request.payment_method_as == 2))">
            <div class="row">
                <div class="col-lg-6" v-for="(paymentMethod, index) in paymentMethods">
                    <div class="cmn-dash-tab blue-tab" :class="(request.payment_method_id === paymentMethod.id) ? 'selected' : ''" @click="request.payment_method_id = paymentMethod.id">
                        <div class="btm-dash-bar">
                            <div class="dash-text"> 
                                <span>xxxxxxxxxxxx{{ paymentMethod.last4 }}</span>
                            </div>
                            <div class="dash-icon">
                                {{ paymentMethod.brand }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group wd1">
                <button type="button" class="btn cmn-btn blue-btn" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                    <span>{{ localeLang.SUBSCIPTION_PAYMENT.ACTIONS.SUBSCRIBE }}</span>
                </button>
                <button type="button" class="btn cmn-btn blue-btn" v-on:click="subscribeToPlan()" v-else-if="request.payment_method_id">
                    {{ localeLang.SUBSCIPTION_PAYMENT.ACTIONS.SUBSCRIBE }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import FormMixin from '../../../mixins/FormMixin';
    import CommonMixin from '../../../mixins/CommonMixin';
    import VeeValidate, { Validator } from 'vee-validate';

    export default {
        name:"user-subscription-stripe-component",
        props:[
            "stripeKey",
            "paymentMethods",
            "plan",
            "discount",
            "subTotal",
            "discountTotal",
            "total",
            "paymentAs",
            "vehicleSlug",
            "localeLang"
        ],
        mixins: [CommonMixin, FormMixin],
        components:{
            VeeValidate: VeeValidate
        },
        data() {
            return {
                loading : false,
                stripeAPIToken: this.stripeKey,
                stripe: '',
                elements: '',
                card: '',
                intentToken: '',
                request:{
                    payment_method_as: 1,
                    slug: this.vehicleSlug,
                    payment_as: this.paymentAs,
                    payment_with: "stripe",
                    plan_id: this.plan.id ? this.plan.id : '',
                    discount_id: this.discount.id ? this.discount.id : '',
                    sub_total: this.subTotal,
                    discount_total: this.discountTotal,
                    total: this.total,
                    card_holder_name: '',
                    payment_method_id: ''
                }
            }
        },
        mounted: function () {
            this.includeStripe('js.stripe.com/v3/', function(){
                this.configureStripe();
            }.bind(this) );
            this.loadIntent();
        },
        watch: {
            paymentAs : function(n, o){
                this.request.payment_as = n;
            },
            plan : function(n, o){
                this.request.plan_id = n.id;
            },
            discount : function(n, o){
                this.request.discount_id = n.id;
            },
            subTotal : function(n, o){
                this.request.sub_total = n;
            },
            discountTotal : function(n, o){
                this.request.discount_total = n;
            },
            total : function(n, o){
                this.request.total = n;
            },
            'request.payment_method_as': function(n, o){
                this.request.payment_method_id = "";
            }
        },
        methods:{
            /*
                Includes Stripe.js dynamically
            */
            includeStripe( URL, callback ){
                let documentTag = document, tag = 'script',
                    object = documentTag.createElement(tag),
                    scriptTag = documentTag.getElementsByTagName(tag)[0];
                object.src = '//' + URL;
                if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            /*
                Configures Stripe by setting up the elements and 
                creating the card element.
            */
            configureStripe(){
                this.stripe = Stripe( this.stripeAPIToken );
                this.elements = this.stripe.elements();
                this.card = this.elements.create('card');
                this.card.mount(this.$refs.stripeCardElement);
            },

            /*
                Loads the payment intent key for the user to pay.
            */
            loadIntent(){
                axios.get('/api/subscription/stripe-setup-intent')
                    .then( function(response){
                        if(response.data.status === 'success'){
                            this.intentToken = response.data.setup_intent;
                        }else{
                            flash(response.data.message, response.data.status);
                        }
                    }.bind(this));
            },
            /*
                Setup payment method to pay.
            */
            async setupPaymentMethod(){
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.stripe.handleCardSetup(
                            this.intentToken.client_secret,
                            this.card, 
                            {
                                payment_method_data: {
                                    billing_details: {
                                        name: this.request.card_holder_name
                                    }
                                }
                            }
                        ).then(function(result) {
                            if (result.error) {
                                this.loading = false;
                                flash(result.error.message, 'error');
                            } else {
                                this.request.payment_method_id = result.setupIntent.payment_method;
                                this.subscribeToPlan();
                            }
                        }.bind(this));
                    }else{
                        this.loading = false;
                    }
                });
            },
            /*
                Subscribe user into the subscription plan.
            */
            subscribeToPlan(){
                let url = (this.request.payment_as === 'single') ? '/api/subscription/charge-through-stripe' : '/api/subscription/subscribe-through-stripe';
                if(!this.request.payment_method_id){
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, "error");
                    this.loading = false;
                    this.loadIntent();
                    return false;
                }
                axios.post(url, this.request).then(function(response){
                    this.card.clear();
                    this.request.card_holder_name = "";
                    this.$validator.reset();
                    flash(response.data.message, response.data.status);
                    if(response.data.status === 'incomplete'){
                        window.location = response.data.url;
                    }else if((response.data.status === 'success') || (response.data.status === 'error')){
                        window.location = response.data.url;
                    }else{
                        this.loading = false;
                        this.loadIntent();
                    }
                }.bind(this));
            },
        }
    }
</script>
<style scoped>
    .dash-text{font-size: 16px;}
    .dash-text span{font-size: 17px;}
    .setting-btn{font-size: 17px;}
    .dash-icon {font-size: 21px;}
    .cmn-dash-tab.selected{border: 1px solid #26980f;}
</style>
