<template>
    <div>
        <div class="row subscription-plan-block" v-if="subscriptionPlans">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-for="(subscriptionPlan, index) in subscriptionPlans">
                <div class="panel subs-plan-box" :class="{'ultimate-plan':(index == 0), 'premium-plan':(index === 1), 'standard-plan':(index === 2), 'lite-plan':(index === 3)}">
                    <div class="panel-heading">
                        <h3 class="panel-title">
                            {{ subscriptionPlan.title }}
                        </h3>
                    </div>
                    <div class="panel-body">
                        <table class="table">
                            <tbody>
                                <tr v-if="subscriptionPlan.total_listing_count">
                                    <td>
                                        {{ subscriptionPlan.total_listing_count }} 
                                        {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_IN_LISTING }}
                                    </td>
                                </tr>
                                <tr class="active" v-if="subscriptionPlan.total_featured_count">
                                    <td>
                                        {{ subscriptionPlan.total_featured_count }} 
                                        {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_IN_FEATURED }}
                                    </td>
                                </tr>
                                <tr v-if="subscriptionPlan.total_on_sale_count">
                                    <td>
                                        {{ subscriptionPlan.total_on_sale_count }} 
                                        {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_IN_ONSALE }}
                                    </td>
                                </tr>
                                <tr v-if="subscriptionPlan.total_until_sold_count">
                                    <td>
                                        {{ subscriptionPlan.total_until_sold_count }} 
                                        {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_IN_UNTILSOLD }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="panel-footer">
                        <div class="the-price">
                            <h2>{{ subscriptionPlan.price | toCurrency }}</h2>
                            <small v-if="subscriptionPlan.plan_interval == 'day'">
                                {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_A_DAY }}
                            </small>
                            <small v-else-if="subscriptionPlan.plan_interval == 'week'">
                                {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_A_WEEK }}
                            </small>
                            <small v-else-if="subscriptionPlan.plan_interval == 'month'">
                                {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_A_MONTH }}
                            </small>
                            <small v-else-if="subscriptionPlan.plan_interval == 'year'">
                                {{ localeLang.SUBSCIPTION_PLANS.LABELS.VEHICLE_AN_YEAR }}
                            </small>
                        </div>
                        <a :href="'/vehicle/create/new/'+subscriptionPlan.slug" class="submit-btn">
                            {{ localeLang.SUBSCIPTION_PLANS.ACTIONS.START_SELLING }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                <div class="no-post-inr">
                    <div class="no-post-img">
                        <img src="/frontend/images/no-post-found.jpg" alt="">
                    </div>
                    <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                    <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CommonMixin from '../../../mixins/CommonMixin';
    export default {
        name:"user-subscription-plan-component",
        props:[
            "subscriptionPlans",
            "localeLang"
        ],
        mixins: [CommonMixin],
        data() {
            return {
                loading : false
            }
        }
    }
</script>
<style scoped>
    .the-price h2{
        width: 100%;
    }
</style>
