export default {
	name: 'kh',
	custom:{
		email: {
	      	required: 'តំរូវអោយបំពេញអ៊ីម៉ែល ឫ លេខទូរស័ព្ទ',
	      	max: 'បំពេញអ៊ីម៉ែល ឫ លេខទូរស័ព្ទមិនអាចលើសពី100តួអក្សរ'
			
	    },
		password:{
			required: 'តំរូវអោយបំពេញលេខសំងាត់',
			min: 'បំពេញលេខសំងាត់យ៉ាងតិចមាន8តួលេខ',
			max: 'បំពេញលេខសំងាត់មិនអាចលើសពី12តួលេខ'
		},
		password_confirmation:{
			required: 'តំរូវអោយបញ្ជាក់លេខសំងាត់ថ្មី',
			confirmed: 'ការបញ្ជាក់លេខសំងាត់គឺមិនត្រឹមត្រូវ',
		},
	    phone: {
	      	required: 'តំរូវអោយបំពេញលេខទូរស័ព្ទ',
	      	numeric: 'បំពេញលេខទូរស័ព្ទអាចជាតួលេខប៉ុណ្ណោះ',
	      	min: 'បំពេញលេខទូរស័ព្ទយ៉ាងតិចមាន9តួលេខ',
	      	max: 'បំពេញលេខទូរស័ព្ទមិនអាចលើសពី15តួលេខ'
	    },
	    otp: {
	      	required: 'តំរូវអោយបំពេញ OTP',
	      	numeric: 'បំពេញ OTP អាចជាតួលេខប៉ុណ្ណោះ',
			min: 'បំពេញ OTP យ៉ាងតិចមាន6តួលេខ',
	      	max: 'បំពេញ OTP មិនអាចលើសពី6តួលេខ'
	    },
	},
};
