<template>
    <div>
        <div v-if="loading">
            <spinner-component></spinner-component>
        </div>
        <div v-else>
            <div v-if="allVehicles.total > 0">
                <div class="row">
                    <div class="col-sm-6" v-for="(vehicle, key) in allVehicles.data">
                        <div class="car-item" :class="vehicle.deleted_at ? 'disabled':''">
                            <div class="list-item-img">
                                <a :href="'/vehicle/'+vehicle.slug" v-if="vehicle.main_image">
                                    <img :src="baseUrl+'/timthumb.php?src='+vehicle.main_image.image_full_path+'&w=434&h=261&zc=0'" />
                                </a>
                                <a :href="'/vehicle/'+vehicle.slug" v-else>
                                    <img :src="baseUrl+'/timthumb.php?src='+baseUrl+'/frontend/images/no-img-415x234.jpg&w=434&h=261&zc=0'" />
                                </a>
                                <div class="price-block theme-green-bg" v-if="vehicle.is_approved === 1">
                                    {{ localeLang.BADGES.APPROVED }}
                                </div>
                                <div class="price-block theme-red-bg" v-else-if="vehicle.is_approved === 2">
                                    {{ localeLang.BADGES.REJECTED }}
                                </div>
                                <div class="price-block theme-yellow-bg" v-else>
                                    {{ localeLang.BADGES.AWAITING_APPROVAL }}
                                </div>
                                <div class="label-block" v-if="vehicle.is_featured == 1">
                                    {{ localeLang.BADGES.FEATURED }}
                                </div>
                                <div class="badges ad-badges" v-if="vehicle.is_inspected">
                                    <img src="/frontend/images/badges.png">
                                    {{ localeLang.BADGES.INSPECTED }}
                                </div>
                                <div class="edit-dropdown dropdown">
                                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu" v-if="vehicle.is_sold === 0">
                                        <a class="dropdown-item" href="javascript:;" v-if="vehicle.is_until_sold === 0" v-on:click.prevent="toggleVehicleListing(vehicle.id, key)">
                                            {{ (vehicle.is_listing == 1) ? localeLang.ACTIONS.REMOVE_LISTING : localeLang.ACTIONS.MARK_LISTING }}
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" v-on:click.prevent="toggleVehicleFeatured(vehicle.id, key)">
                                            {{ (vehicle.is_featured == 1) ? localeLang.ACTIONS.REMOVE_FEATURED : localeLang.ACTIONS.MARK_FEATURED }}
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" v-on:click.prevent="toggleVehicleOnSale(vehicle.id, key)">
                                            {{ (vehicle.is_on_sale == 1) ? localeLang.ACTIONS.REMOVE_ON_SALE : localeLang.ACTIONS.MARK_ON_SALE }}
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" v-if="vehicle.is_until_sold === 0" v-on:click.prevent="markVehicleUntilSold(vehicle.id, key)">
                                            {{ localeLang.ACTIONS.MARK_UNTIL_SOLD }}
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" v-on:click.prevent="markVehicleSold(vehicle.id, key)">
                                            {{ localeLang.ACTIONS.MARK_SOLD }}
                                        </a>
                                        <a class="dropdown-item" :href="'/vehicle/'+vehicle.slug+'/general'">
                                            {{ localeLang.ACTIONS.MARK_EDIT }}
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" v-on:click.prevent="deleteVehicle(allVehicles.data, key)">
                                            {{ localeLang.ACTIONS.MARK_DELETE }}
                                        </a>
                                    </div>
                                    <div class="dropdown-menu" v-else>
                                        <a class="dropdown-item" href="javascript:;" v-on:click.prevent="deleteVehicle(allVehicles.data, key)">
                                            {{ localeLang.ACTIONS.MARK_DELETE }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="list-item-detail">
                                <h4><a href="javascript:;">{{ vehicle.title }}</a></h4>
                                <ul class="d-flex flex-wrap">
                                    <li v-if="vehicle.bodystyle">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/body-type-icon.png" alt="body-type-icon">
                                            </div>
                                            <span>{{ vehicle.bodystyle.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.fuel_type">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/engine-icon.png" alt="odometer-icon">
                                            </div>
                                            <span>{{ vehicle.fuel_type.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.transmissions">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/transmision-icon.png" alt="transmision-icon">
                                            </div>
                                            <span>{{ vehicle.transmissions.name }}</span>
                                        </div>
                                    </li>
                                    <li v-if="vehicle.odometer">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="parts-icon">
                                                <img src="/frontend/images/odometer-icon.png" alt="odometer-icon">
                                            </div>
                                            <span>{{ vehicle.odometer | toNumber }} {{ localeLang.LABLES.KM }}</span>
                                        </div>
                                    </li>
                                </ul>
                                <div class="custom-checkbox">
                                    <input type="checkbox" :id="'ch1'+key" name="chk1" v-model="checkedVehicles" :value="vehicle.id">
                                    <label :for="'ch1'+key">{{ localeLang.LABLES.CHECK_FOR }}</label>
                                </div>
                                <div class="list" v-if="vehicle.is_sold === 0">
                                    <span v-show="vehicle.is_listing == 1">
                                        <img src="/frontend/images/list-pack.png" data-toggle="tooltip" :title="localeLang.LABLES.LISTING">&nbsp;
                                    </span>
                                    <span v-show="(vehicle.is_until_sold == 1) || (vehicle.is_until_sold == 2)">
                                        <img src="/frontend/images/untilsold-pack.png" data-toggle="tooltip" :title="localeLang.LABLES.UNTIL_SOLD">&nbsp;
                                    </span>
                                    <span v-show="vehicle.is_on_sale == 1">
                                        <img src="/frontend/images/sale-pack.png" data-toggle="tooltip" :title="localeLang.LABLES.ON_SALE">&nbsp;
                                    </span>
                                    <span v-show="vehicle.is_sold == 1">
                                        <img src="/frontend/images/sold-pack.png" data-toggle="tooltip" :title="localeLang.LABLES.SOLD">&nbsp;
                                    </span>
                                </div>
                                <div class="list" v-else>
                                    <span v-show="vehicle.is_sold == 1">
                                        <img src="/frontend/images/sold-pack.png" data-toggle="tooltip" :title="localeLang.LABLES.SOLD">&nbsp;
                                    </span>
                                </div>
                                <div class="view-info-sec d-flex">
                                    <div class="left-info" v-if="vehicle.discount_price">
                                        <s>{{ vehicle.price | toCurrency }}</s>
                                        <strong>{{ vehicle.discount_price | toCurrency }}</strong>
                                    </div>
                                    <div class="left-info" v-else>
                                        <strong>{{ vehicle.price | toCurrency }}</strong>
                                    </div>
                                    <div class="inquery-btn-col">
                                        <a class="view-detail-btn" :href="'/vehicle/'+vehicle.slug">
                                            {{ localeLang.ACTIONS.DETAIL }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                    <div class="no-post-inr">
                        <div class="no-post-img">
                            <img src="/frontend/images/no-post-found.jpg" alt="">
                        </div>
                        <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                        <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" v-if="allVehicles.last_page > 1">
            <div class="custom-pagination">
                <paginate
                    :page-count="allVehicles.last_page"
                    :page-range="5"
                    :click-handler="paginateVehicles"
                    :prev-text="localeLang.PAGINATION.PREV"
                    :next-text="localeLang.PAGINATION.NEXT"
                    :page-class="'page-item'"
                    :active-class="'active'"
                    :disabled-class="'disabled'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination justify-content-center'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>
<script>
    import bootbox from "bootbox";
    import Paginate from 'vuejs-paginate';
    import CommonMixin from '../../../mixins/CommonMixin';
    import SpinnerComponent from '../../../components/SpinnerComponent';
    export default {
        name:"user-manage-ad-component",
        props: [
            "vehicles",
            "localeLang"
        ],
        mixins: [CommonMixin],
        components:{
            Paginate:Paginate,
            SpinnerComponent:SpinnerComponent
        },
        data() {
            return {
                allVehicles: [],
                checkedVehicles:[],
                loading : false,
            }
        },
        mounted(){
            this.allVehicles = this.vehicles;
            let vm = this;
            Vue.nextTick(function(){
                window.$('.mark-listing').click(function(){
                    vm.markVehiclesListing();
                });
                window.$('.mark-featured').click(function(){
                    vm.markVehiclesFeatured();
                });
                window.$('.mark-on-sale').click(function(){
                    vm.markVehiclesOnSale();
                });
                window.$('.mark-until-sold').click(function(){
                    vm.markVehiclesUntilSold();
                });

            }.bind(vm));
        },
        methods:{
            /* Get vehicles */
            async getVehicles(page = 1){
                this.loading = true;
                let url = '/manage-ads/?page='+page;
                axios.get(url).then(response => {
                    this.loading = false;
                    this.allVehicles = response.data.data;
                    window.$(window).scrollTop(0);
                }).catch(error => {
                    this.loading = false;
                    this.allVehicles = [];
                    window.$(window).scrollTop(0);
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },
            /* delete vehicle */
            deleteVehicle(items, key){
                let vm = this;
                bootbox.confirm(vm.localeLang.MESSAGES.REMOVE_VEHICLE1+" "+vm.localeLang.MESSAGES.REMOVE_VEHICLE2, function(result){
                    if(result){
                        axios.get('/api/vehicle/manage/'+items[key].slug+'/delete').then(response => {
                            vm.loading = false;
                            if(response.data.status === "success"){
                                Vue.delete(items, key);
                            }
                            flash(response.data.message, response.data.status);
                        }).catch(error => {
                            vm.loading = false;
                            flash(vm.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            console.log(error);
                        });
                    }
                });
            },

            /* Mark vehicles as listing */
            markVehiclesListing(){
               axios.post('/api/vehicle/manage/mark-vehicles-as-listing',
                    {
                        vehicleIds: this.checkedVehicles
                    }
                ).then(response => {
                    flash(response.data.message, response.data.status);
                    if(response.data.status === 'success'){
                        let vehicleIds = response.data.data;
                        if(vehicleIds.length > 0){
                            for (let i = 0; i < vehicleIds.length; i++) {
                                if(this.allVehicles.data.some(item => item.id === vehicleIds[i])){
                                    let key = this.allVehicles.data.findIndex(item => item.id === vehicleIds[i]);
                                    let vehicle = this.allVehicles.data[key];
                                    vehicle.is_listing = 1;
                                    Vue.set(this.allVehicles, key, vehicle);
                                }
                            }
                        }
                    }
                    this.checkedVehicles = [];
                }).catch(error => {
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

            /* Mark vehicles as featured */
            markVehiclesFeatured(){
               axios.post('/api/vehicle/manage/mark-vehicles-as-featured',
                    {
                        vehicleIds: this.checkedVehicles
                    }
                ).then(response => {
                    flash(response.data.message, response.data.status);
                    if(response.data.status === 'success'){
                        let vehicleIds = response.data.data;
                        if(vehicleIds.length > 0){
                            for (let i = 0; i < vehicleIds.length; i++) {
                                if(this.allVehicles.data.some(item => item.id === vehicleIds[i])){
                                    let key = this.allVehicles.data.findIndex(item => item.id === vehicleIds[i]);
                                    let vehicle = this.allVehicles.data[key];
                                    vehicle.is_featured = 1;
                                    Vue.set(this.allVehicles, key, vehicle);
                                }
                            }
                        }
                    }
                    this.checkedVehicles = [];
                }).catch(error => {
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },
            /* Mark vehicles as on sale */
            markVehiclesOnSale(){
               axios.post('/api/vehicle/manage/mark-vehicles-as-on-sale',
                    {
                        vehicleIds: this.checkedVehicles
                    }
                ).then(response => {
                    flash(response.data.message, response.data.status);
                    if(response.data.status === 'success'){
                        let vehicleIds = response.data.data;
                        if(vehicleIds.length > 0){
                            for (let i = 0; i < vehicleIds.length; i++) {
                                if(this.allVehicles.data.some(item => item.id === vehicleIds[i])){
                                    let key = this.allVehicles.data.findIndex(item => item.id === vehicleIds[i]);
                                    let vehicle = this.allVehicles.data[key];
                                    vehicle.is_on_sale = 1;
                                    Vue.set(this.allVehicles, key, vehicle);
                                }
                            }
                        }
                    }
                    this.checkedVehicles = [];
                }).catch(error => {
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

            /* Mark vehicles as until sold */
            markVehiclesUntilSold(){
               axios.post('/api/vehicle/manage/mark-vehicles-as-until-sold',
                    {
                        vehicleIds: this.checkedVehicles
                    }
                ).then(response => {
                    flash(response.data.message, response.data.status);
                    if(response.data.status === 'success'){
                        let vehicleIds = response.data.data;
                        if(vehicleIds.length > 0){
                            for (let i = 0; i < vehicleIds.length; i++) {
                                if(this.allVehicles.data.some(item => item.id === vehicleIds[i])){
                                    let key = this.allVehicles.data.findIndex(item => item.id === vehicleIds[i]);
                                    let vehicle = this.allVehicles.data[key];
                                    vehicle.is_until_sold = 1;
                                    Vue.set(this.allVehicles, key, vehicle);
                                }
                            }
                        }
                    }
                    this.checkedVehicles = [];
                }).catch(error => {
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

            /* Toggle vehicle listing */
            toggleVehicleListing(vehicleId, vehicleKey){
                axios.get('/api/vehicle/manage/'+vehicleId+'/toggle-vehicle-listing').then(response => {
                    if(response.data.status === 'success'){
                        let vehicle = this.allVehicles.data[vehicleKey];
                        vehicle.is_listing = response.data.is_listing;
                        Vue.set(this.allVehicles.data, vehicleKey, vehicle);
                    }
                    flash(response.data.message, response.data.status);
                }).catch(error => {
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

            /* Toggle vehicle featured */
            toggleVehicleFeatured(vehicleId, vehicleKey){
                axios.get('/api/vehicle/manage/'+vehicleId+'/toggle-vehicle-featured').then(response => {
                    if(response.data.status === 'success'){
                        let vehicle = this.allVehicles.data[vehicleKey];
                        vehicle.is_featured = response.data.is_featured;
                        Vue.set(this.allVehicles.data, vehicleKey, vehicle);
                    }
                    flash(response.data.message, response.data.status);
                }).catch(error => {
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

            /* Toggle vehicle on sale */
            toggleVehicleOnSale(vehicleId, vehicleKey){
               axios.get('/api/vehicle/manage/'+vehicleId+'/toggle-vehicle-on-sale').then(response => {
                    if(response.data.status === 'success'){
                        let vehicle = this.allVehicles.data[vehicleKey];
                        vehicle.is_on_sale = response.data.is_on_sale;
                        Vue.set(this.allVehicles.data, vehicleKey, vehicle);
                    }
                    flash(response.data.message, response.data.status);
                }).catch(error => {
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },

            /* Toggle vehicle until sold */
            markVehicleUntilSold(vehicleId, vehicleKey){
                let vm = this;
                bootbox.confirm(vm.localeLang.MESSAGES.MARK_VEHICLE_UNTIL_SOLD1+" "+vm.localeLang.MESSAGES.MARK_VEHICLE_UNTIL_SOLD2, function(result){
                    if(result){
                        axios.get('/api/vehicle/manage/'+vehicleId+'/toggle-vehicle-until-sold').then(response => {
                            if(response.data.status === 'success'){
                                let vehicle = vm.allVehicles.data[vehicleKey];
                                vehicle.is_until_sold = response.data.is_until_sold;
                                Vue.set(vm.allVehicles.data, vehicleKey, vehicle);
                            }
                            flash(response.data.message, response.data.status);
                        }).catch(error => {
                            flash(vm.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            console.log(error);
                        });
                    }
                });
            },

            /* Mark vehicle sold */
            markVehicleSold(vehicleId, vehicleKey){
                let vm = this;
                bootbox.confirm(vm.localeLang.MESSAGES.MARK_VEHICLE_SOLD1+" "+vm.localeLang.MESSAGES.MARK_VEHICLE_SOLD2, function(result){
                    if(result){
                        axios.get('/api/vehicle/manage/'+vehicleId+'/mark-vehicle-as-sold').then(response => {
                            if(response.data.status === 'success'){
                                let vehicle = vm.allVehicles.data[vehicleKey];
                                vehicle.is_sold = response.data.is_sold;
                                Vue.set(vm.allVehicles.data, vehicleKey, vehicle);
                            }
                            flash(response.data.message, response.data.status);
                        }).catch(error => {
                            flash(vm.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            console.log(error);
                        });
                    }
                });
            },

            /* Paginate vehicles */
            paginateVehicles(page) {
                this.getVehicles(page);
            }

        }
    }
</script>
