export default {
	name: 'kh',
	custom:{
		old_password: {
	      	required: 'តំរូវអោយបំពេញលេខសំងាត់',
	      	min: 'បំពេញលេខសំងាត់យ៉ាងតិចមាន8តួលេខ',
	      	max:'បំពេញលេខសំងាត់មិនអាចលើសពី12តួលេខ'
	    },
		new_password: {
	      	required: 'តំរូវអោយបំពេញលេខសំងាត់ថ្មី',
	      	min: 'បំពេញលេខសំងាត់ថ្មីយ៉ាងតិចមាន8តួលេខ',
	      	max:'បំពេញលេខសំងាត់ថ្មីមិនអាចលើសពី12តួលេខ'
	    },
	    new_password_confirmation: {
	      	required: 'តំរូវអោយបញ្ជាក់លេខសំងាត់ថ្មី',
	      	confirmed:'ការបញ្ជាក់លេខសំងាត់គឺមិនត្រឹមត្រូវ',
	    },
	},
};
