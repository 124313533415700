<template>
    <div>
        <div class="login-top">
            <h4>{{ title }}</h4>
            <p>{{ localeLang.SUB_TITLE }}</p>
        </div>
        <div class="login-middle">
            <form @submit.prevent="sendPasswrodResetLink" v-if="steps === 1">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <select name="countryCode"  v-model="request.countryCode" data-vv-as="country code" class="form-control">
                                <option :value="countryid" v-for="(country, countryid) in localeLang.FIELDS.COUNTRY">{{ country }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <input type="text" :placeholder="localeLang.FIELDS.PHONE" class="form-control"  name="phone" :class="errors.has('phone')?'is-invalid':''" v-model="request.phone" v-validate="'required|numeric|min:4|max:16'" data-vv-as="phone" key="phone">
                            <div class="invalid-feedback" v-if="errors.has('phone')">
                                {{ errors.first('phone') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.SEND_OTP }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block " :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.SEND_OTP }}</button>
                </div>  
                
            </form>
            <form @submit.prevent="resetPassword" v-else-if="steps === 2">
                <div class="form-group">
                    <input type="password" :placeholder="localeLang.FIELDS.PASSWORD" class="form-control passwords" name="password" :class="errors.has('password')?'is-invalid':''" v-model="request.password" v-validate="'required|min:8|max:12'" data-vv-as="password" ref="password" key="password">
                    <div class="invalid-feedback" v-if="errors.has('password')">
                        {{ errors.first('password') }}
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" :placeholder="localeLang.FIELDS.CONFIRM_PASSWORD" class="form-control passwords" name="password_confirmation" :class="errors.has('password_confirmation')?'is-invalid':''" v-model="request.password_confirmation" v-validate="'required|confirmed:password'" data-vv-as="password" key="password_confirmation">
                    <div class="invalid-feedback" v-if="errors.has('password_confirmation')">
                        {{ errors.first('password_confirmation') }}
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.RESET_PASSWORD }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block " :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.RESET_PASSWORD }}</button>
                </div>  
            </form>
            <form @submit.prevent="verifyOtp" v-else>
                <div class=" form-group">
                    <input type="text" :placeholder="localeLang.FIELDS.OTP" class="form-control"  name="otp" :class="errors.has('otp')?'is-invalid':''" v-model="request.otp"  v-validate="'required|numeric|min:6|max:6'" data-vv-as="OTP" key="otp" >
                    <div class="invalid-feedback" v-if="errors.has('otp')">
                        {{ errors.first('otp') }}
                    </div>
                </div>
                <div class="text-right">
                    <a href="javascript:;" v-if="loading === false" class="forgot-pass font-weight-bold" v-on:click="resendOtp()">{{ localeLang.ACTIONS.RSEND_OTP }}</a>
                    <a href="javascript:;" v-else class="forgot-pass font-weight-bold">{{ localeLang.ACTIONS.RSEND_OTP }}</a>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-custom btn-block" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                        <span>{{ localeLang.ACTIONS.VERIFY_OTP }}</span>
                    </button>
                    <button type="submit" class="btn btn-custom btn-block " :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.VERIFY_OTP }}</button>
                </div>
            </form>
            <div class="or-seperate">
                <span class="register">
                    <strong>{{ localeLang.LABELS.OR }}</strong>
                </span>
            </div>
            <span class="form-bottom">
                <a href="/login" class="join">{{ localeLang.ACTIONS.SIGN_IN }}</a>
            </span> 
        </div>
    </div>
</template>

<script>
    import FlashMessageComponent from '../../components/FlashMessageComponent';
    import FormMixin from '../../mixins/FormMixin';
    import loginDictionary from '../../locale/kh/loginDictionary';
    import VeeValidate, { Validator } from 'vee-validate';

    Vue.use(VeeValidate);

    export default {
        name:"auth-forgot-password-component",
        mixins: [FormMixin],
        props:[
            "localeName",
            "localeLang",
            "flashSuccess", 
            "flashError"
        ],
        data() {
            return {
                loading : false,
                steps: 1,
                title:this.localeLang.TITLES.FORGOT_PASSWORD,
                request: {
                    phone: "",
                    countryCode:"+855",
                    otp:"",
                    password: "",
                    otpType: 2,
                }
            }
        },
        mounted(){
            if(this.flashSuccess){
                flash(this.flashSuccess, 'success');
            }
            if(this.flashError){
                flash(this.flashError, 'error');
            }
            Vue.nextTick(function(){
                $(document).on({
                    keydown: function (e) {
                        if (e.which === 32)
                            return false;
                    },
                    change: function () {
                        this.value = this.value.replace(/\s/g, "");
                    }
                }, '.passwords');
            });

            if(this.localeName === 'kh'){
                Validator.localize('kh', loginDictionary);
            }
        },
        methods: {
            sendPasswrodResetLink() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/password/email', this.request).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            this.steps = 3;
                            this.title = this.localeLang.TITLES.VERIFY_OTP
                            if(response.data.code === 105){
                                this.request.otpType = 1;
                            }
                            if(response.data.status === 'error'){
                                this.steps = 1;
                            }
                            
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            verifyOtp() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/verify-otp', {phone: this.request.phone,otp:this.request.otp,otpType:this.request.otpType}).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                this.steps = 2;  
                                this.title = this.localeLang.TITLES.RESET_PASSWORD              
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            resetPassword() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/reset-password', {phone: this.request.phone,password:this.request.password}).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                if(response.data.code === 105){
                                    this.steps = 3;
                                    this.request.otp = "";
                                    this.request.otpType = 1;
                                }else{
                                    this.resetFormFields(this.request);
                                    window.location = "/login";
                                } 
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            } ,
            resendOtp() {
                this.loading = true;
                axios.post('/resend-otp', 
                        {phone: this.request.phone,otpType:this.request.otpType}
                    ).then(response => {
                    this.loading = false;
                    if(response.data.status === 'success'){
                        this.request.otp = "";
                        this.$validator.reset();
                    }
                    flash(response.data.message, response.data.status);
                }).catch(error => {
                    this.loading = false;
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    console.log(error);
                });
            },
        }
    }
</script>
