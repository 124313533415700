<template>
    <!-- Custom Popup -->
    <div class="modal fade custom-popup dealer-inquiry-modal" id="dealer-inquiry-modal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <div class="modal-body">
                    <button data-dismiss="modal" class="close" type="button">×</button>
                    <h2>{{ localeLang.ENQUIRY.TITLE }}</h2>


                    <form @submit.prevent="enquire">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6 input-col">
                                    <input type="text" :placeholder="localeLang.ENQUIRY.FORM.NAME_PLACEHOLDER" class="form-control" name="name" :class="errors.has('name')?'is-invalid':''" v-model="request.name" v-validate="'required|max:50'" data-vv-as="full name">
                                    <div class="invalid-feedback" v-if="errors.has('name')">
                                        {{ errors.first('name') }}
                                    </div>
                                </div>
                                <div class="col-md-6 input-col">
                                    <input type="text" :placeholder="localeLang.ENQUIRY.FORM.EMAIL_PLACEHOLDER" class="form-control" name="email" :class="errors.has('email')?'is-invalid':''" v-model="request.email" v-validate="'required|email|max:100'" data-vv-as="email" />
                                    <div class="invalid-feedback" v-if="errors.has('email')">
                                        {{ errors.first('email') }}
                                    </div>
                                </div>
                                <div class="col-md-6 input-col">
                                    <input type="text" :placeholder="localeLang.ENQUIRY.FORM.PHONE_PLACEHOLDER" class="form-control" name="phone" :class="errors.has('phone')?'is-invalid':''" v-model="request.phone" v-validate="'required|numeric|min:9|max:15'" data-vv-as="phone" />
                                    <div class="invalid-feedback" v-if="errors.has('phone')">
                                        {{ errors.first('phone') }}
                                    </div>
                                </div>
                                <div class="col-md-12 input-col">
                                    <textarea :placeholder="localeLang.ENQUIRY.FORM.MESSAGE_PLACEHOLDER" class="form-control" name="message" :class="errors.has('message')?'is-invalid':''" v-model="request.message" v-validate="'required|max:500'" data-vv-as="message"></textarea>
                                    <div class="invalid-feedback" v-if="errors.has('message')">
                                        {{ errors.first('message') }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group submit-form">
                            <button type="button" class="submit-btn" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                                <span>{{ localeLang.ENQUIRY.FORM.ACTION_TEXT }}</span>
                            </button>
                            <button type="submit" class="submit-btn" :disabled="errors.any()" v-else>{{ localeLang.ENQUIRY.FORM.ACTION_TEXT }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Custom Popup -->
</template>
<script>
    import CommonMixin from '../../mixins/CommonMixin';
    import FormMixin from '../../mixins/FormMixin';
    import bootbox from "bootbox";
    import vehicleDictionary from '../../locale/kh/vehicleDictionary';
    import VeeValidate, { Validator } from 'vee-validate';

    Vue.use(VeeValidate);

    export default {
        name:"dealer-inquiry-modal-component",
        mixins: [CommonMixin, FormMixin],
        props:[
            "vehicleForInquiry",

            "localeLang",
            "localeName",
            "loggedinUser"],
        data() {
            return {
                loading : false,
                request: {
                    vehicle_id: this.vehicleForInquiry,
                    name: "",
                    email: "",
                    phone: "",
                    message: ""
                }
            }
        },
        created(){
            if(this.loggedinUser){
                if(this.loggedinUser.name){
                    this.request.name = this.loggedinUser.name;
                }
                if(this.loggedinUser.email){
                    this.request.email = this.loggedinUser.email;
                }
                if(this.loggedinUser.phone){
                    this.request.phone = this.loggedinUser.phone;
                }
            }
        },
        mounted(){
            if(this.localeName === 'kh'){
                Validator.localize('kh', vehicleDictionary);
            }
        },
        methods: {
            enquire() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.request.vehicle_id = this.vehicleForInquiry;
                        axios.post('/api/conversation/save/inquiry', this.request).then(response => {
                            this.loading = false;
                            window.$('.dealer-inquiry-modal').modal('hide');
                            if(response.data.status === 'success'){
                                if(!this.loggedinUser){
                                    this.request.name = "";
                                    this.request.email = "";
                                    this.request.phone = "";
                                }
                                this.request.message = "";
                                /* reset the validation */
                                this.$validator.reset();

                                this.request.message = "";
                                bootbox.alert(response.data.message);
                                flash(response.data.message, response.data.status);
                            }else if(response.data.status === 'warning'){
                                if(!this.loggedinUser){
                                    this.request.name = "";
                                    this.request.email = "";
                                    this.request.phone = "";
                                }
                                this.request.message = "";
                                bootbox.alert(response.data.message);
                            }else{
                                flash(response.data.message, response.data.status);
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.OOPS, 'error');
                                window.$('.dealer-inquiry-modal').modal('hide');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            }
        }
    }
</script>
