export default {
    methods: {
        setErrorsFromResponse: function (responseError, isScope = false) {
            // only allow this function to be run if the validator exists
            if(!this.hasOwnProperty('$validator')) {
                return;
            }
            // clear errors
            this.$validator.errors.clear();

            // check if errors exist
            if(!responseError.hasOwnProperty('errors')) {
                return;
            }
            let errors = Object.keys(responseError.errors);
            if(errors){
                //this.$validator.reset();
                errors.map(formField => {
                    let message = responseError.errors[formField].join(', ');      
                    this.$validator.errors.add({ field: formField, msg: message });       
                });
            }
        },
        resetFormFields: function(fields){
            let self = this;
            Object.keys(fields).forEach(function (index, value) {
                self.request[index] = '';
            });
            // only allow this function to be run if the validator exists
            if(!self.hasOwnProperty('$validator')) {
                return;
            }
            self.$validator.reset();
        }
    },
}
