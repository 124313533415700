<template>
    <form @submit.prevent="enquire">
        <div class="global-form contact-form">
            <div class="form-group row">
                <div class="input-col col-md-6">
                    <label>
                        {{ localeLang.EMAIL_LABEL }}
                    </label> 
                    <input type="text" class="form-control" :placeholder="localeLang.EMAIL_PLACEHOLDER" name="email" :class="errors.has('email')?'is-invalid':''" v-model="request.email" v-validate="'email|max:100'" data-vv-as="email">
                    <div class="invalid-feedback" v-if="errors.has('email')">
                        {{ errors.first('email') }}
                    </div>
                </div> 
                <div class="input-col col-md-6">
                    <label>
                        {{ localeLang.PHONE_LABEL }}
                        <span class="required">*</span>
                    </label> 
                    <input type="text" class="form-control" :placeholder="localeLang.PHONE_PLACEHOLDER" name="phone" :class="errors.has('phone')?'is-invalid':''" v-model="request.phone" v-validate="'required|numeric|min:9|max:15'" data-vv-as="phone">
                    <div class="invalid-feedback" v-if="errors.has('phone')">
                        {{ errors.first('phone') }}
                    </div>
                </div> 
            </div>
            <div class="form-group row">
                <div class="input-col col-md-12">
                    <label>
                        {{ localeLang.ENQUIRY_RELATESTO.LABEL }}
                        <span class="required">*</span></label> 
                    <div class="select-outer">
                        <select class="form-control" name="type" :class="errors.has('type')?'is-invalid':''" v-model="request.type" v-validate="'required|max:100'" data-vv-as="type">
                            <option value="">{{ localeLang.ENQUIRY_RELATESTO.PLACEHOLDER }}</option>
                            <option v-for="(type, key) in localeLang.ENQUIRY_RELATESTO.OPTIONS" :value="key">{{ type }}</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.has('type')">
                            {{ errors.first('type') }}
                        </div>
                    </div>
                </div> 
            </div> 
            <div class="form-group row">
                <div class="input-col col-md-12">
                    <label>
                        {{ localeLang.SUBJECT_LABEL }}
                        <span class="required">*</span>
                    </label> 
                    <input type="text" class="form-control" :placeholder="localeLang.SUBJECT_PLACEHOLDER" name="subject" :class="errors.has('subject')?'is-invalid':''" v-model="request.subject" v-validate="'required|max:100'" data-vv-as="subject">
                    <div class="invalid-feedback" v-if="errors.has('subject')">
                        {{ errors.first('subject') }}
                    </div>
                </div>  
            </div> 
            <div class="form-group row">
                <div class="input-col col-md-12">
                    <label>
                        {{ localeLang.MESSAGE_LABEL }}
                        <span class="required">*</span>
                    </label> 
                    <textarea class="form-control" name="message" :placeholder="localeLang.MESSAGE_PLACEHOLDER" :class="errors.has('message')?'is-invalid':''" v-model="request.message" v-validate="'required|max:500'" data-vv-as="message"></textarea>
                    <div class="invalid-feedback" v-if="errors.has('message')">
                        {{ errors.first('message') }}
                    </div>
                </div>  
            </div>
            <div class="privacy-policy-row">
                <p>
                    {{ localeLang.BOTTOM_TEXT1 }} S.P Carmarket 
                    <a href="/privacy-policy" target="_blank" class="btn-custom"> {{ localeLang.BOTTOM_TEXT2 }}</a>
                </p> 
            </div> 
            <div class="btn-outer">
                <button type="button" class="submit-btn" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                    <span>{{ localeLang.ACTION_TEXT }}</span>
                </button>
                <button type="submit" class="submit-btn" :disabled="errors.any()" v-else>{{ localeLang.ACTION_TEXT }}</button>
            </div>
        </div>
    </form>
</template>
<script>
    import CommonMixin from '../mixins/CommonMixin';
    import FormMixin from '../mixins/FormMixin';
    import enquiryDictionary from '../locale/kh/enquiryDictionary';
    import VeeValidate, { Validator } from 'vee-validate';

    Vue.use(VeeValidate);

    export default {
        name:"inquiry-component",
        props:[
            "localeName",
            "localeLang", 
            "loggedinUser"
        ],
        mixins: [CommonMixin, FormMixin],
        data() {
            return {
                loading : false,
                request: {
                    email: "",
                    phone: "",
                    type: "",
                    subject: "",
                    message: ""
                }
            }
        },
        mounted(){
            if(this.loggedinUser){
                if(this.request.email){
                    this.request.email = this.loggedinUser.email;
                }
            }
            if(this.localeName === 'kh'){
                Validator.localize('kh', enquiryDictionary);
            }
        },
        methods: {
            enquire() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        axios.post('/contact-us/inquire', this.request).then(response => {
                            this.loading = false;
                            flash(response.data.message, response.data.status);
                            if(response.data.status === "success"){
                                this.resetFormFields(this.request);
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash('Somthing has gone wrong.', 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            }
        }
    }
</script>
