<template>
    <div class="dash-info-tab">
        <div  v-show="addPaymentMethodForm === true">
            <div class="form-group">
                <label>
                    {{ localeLang.LABLES.NAME_ON_CARD }}
                </label>
                <input type="text" class="form-control" name="name_on_card" :placeholder="localeLang.LABLES.NAME_ON_CARD" :class="errors.has('name_on_card')?'is-invalid':''" v-model="request.card_holder_name" v-validate="'required|alpha_spaces|max:50'" data-vv-as="Name on card">
                <div class="invalid-feedback" v-if="errors.has('name_on_card')">
                    {{ errors.first('name_on_card') }}
                </div>
            </div>
            <!-- Stripe Elements Placeholder -->
            <div class="form-group">
                <div id="stripe-card-element" ref="stripeCardElement"></div>
            </div>
            <div class="btn-outer">
                <button type="button" class="submit-btn" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                    <span>{{ localeLang.ACTIONS.ADD }}</span>
                </button>
                <button type="button" class="submit-btn" v-on:click="setupPaymentMethod()" v-else>
                    {{ localeLang.ACTIONS.ADD }}
                </button>
            </div>
        </div>
        <div v-show="addPaymentMethodForm === false">
            <div class="row" v-if="allPaymentMethods.length > 0">
                <div class="col-lg-6" v-for="(paymentMethod, index) in allPaymentMethods">
                    <div class="cmn-dash-tab blue-tab">
                        <div class="btm-dash-bar">
                            <div class="dash-text"> 
                                <span>xxxxxxxxxxxx{{ paymentMethod.last4 }}</span>
                            </div>
                            <div class="dash-icon">
                                {{ paymentMethod.brand }}
                            </div>
                        </div>
                        <div class="top-dash-bar"> 
                            <a href="javascript:;" class="setting-btn text-danger" data-toggle="tooltip" :title="localeLang.LABLES.MARK_AS_DEFAULT_PAYMENT_METHOD" v-on:click="markDefaultPaymentMethod(paymentMethod.id, index)" v-if="paymentMethod.is_default === false">
                                <i class="fa fa-info-circle"></i>
                            </a>
                            <a href="javascript:;" class="setting-btn text-success" data-toggle="tooltip" :title="localeLang.LABLES.MARKED_AS_DEFAULT_PAYMENT_METHOD" v-else>
                                <i class="fa fa-check-circle"></i>
                            </a>
                            <a href="javascript:;" class="view-dtl-btn" data-toggle="tooltip" :title="localeLang.LABLES.REMOVE_PAYMENT_METHOD" v-on:click="removePaymentMethod(paymentMethod.id, index)">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="no-result-block d-flex flex-wrap align-items-center justify-content-center">
                    <div class="no-post-inr">
                        <div class="no-post-img">
                            <img src="/frontend/images/no-post-found.jpg" alt="">
                        </div>
                        <h2>{{ localeLang.MESSAGES.OPPS }}</h2>
                        <p>{{ localeLang.MESSAGES.NO_RESULT }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import bootbox from "bootbox";
    import CommonMixin from '../../mixins/CommonMixin';
    import VeeValidate, { Validator } from 'vee-validate';

    export default {
        name:"user-payment-methods-component",
        props:[
            "paymentMethods",
            "stripeKey",
            "localeLang"
        ],
        mixins: [CommonMixin],
        components:{
            VeeValidate: VeeValidate
        },
        data() {
            return {
                loading : false,
                card: '',
                stripe: '',
                elements: '',
                intentToken: '',
                addPaymentMethodForm: false,
                stripeAPIToken: this.stripeKey,
                allPaymentMethods: this.paymentMethods,
                request:{
                    card_holder_name:"",
                    payment_method : ""
                }
            }
        },
        mounted(){
            let vm = this;
            Vue.nextTick(function(){
                window.$('.add-payment-method').click(function(){
                    vm.addPaymentMethodForm = true;
                });

                vm.includeStripe('js.stripe.com/v3/', function(){
                    vm.configureStripe();
                }.bind(vm) );
                vm.loadIntent();
                
            }.bind(vm));
        },
        methods:{
            /*
                Includes Stripe.js dynamically
            */
            includeStripe( URL, callback ){
                let documentTag = document, tag = 'script',
                    object = documentTag.createElement(tag),
                    scriptTag = documentTag.getElementsByTagName(tag)[0];
                object.src = '//' + URL;
                if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
                scriptTag.parentNode.insertBefore(object, scriptTag);
            },
            /*
                Configures Stripe by setting up the elements and 
                creating the card element.
            */
            configureStripe(){
                this.stripe = Stripe( this.stripeAPIToken );
                this.elements = this.stripe.elements();
                this.card = this.elements.create('card');
                this.card.mount(this.$refs.stripeCardElement);
            },

            /*
                Loads the payment intent key for the user to pay.
            */
            loadIntent(){
                axios.get('/api/subscription/stripe-setup-intent')
                    .then( function(response){
                        if(response.data.status === 'success'){
                            this.intentToken = response.data.setup_intent;
                        }else{
                            flash(response.data.message, response.data.status);
                        }
                    }.bind(this));
            },
            /*
                Setup payment method to pay.
            */
            async setupPaymentMethod(){
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.stripe.handleCardSetup(
                            this.intentToken.client_secret,
                            this.card, 
                            {
                                payment_method_data: {
                                    billing_details: {
                                        name: this.request.card_holder_name
                                    }
                                }
                            }
                        ).then(function(result) {
                            if (result.error) {
                                this.loading = false;
                                flash(result.error.message, 'error');
                            } else {
                                this.request.payment_method = result.setupIntent.payment_method;
                                this.addPaymentMethod();
                            }
                        }.bind(this));
                    }else{
                        this.loading = false;
                    }
                });
                
            },
            /* Add payment method. */
            addPaymentMethod(){
                let vm = this;
                axios.post('/api/payment-methods/add', vm.request)
                    .then(function(response){
                        vm.loading = false;
                        vm.card.clear();
                        vm.request.card_holder_name = "";
                        vm.$validator.reset();
                        if(response.data.status === 'success'){
                            vm.allPaymentMethods.push(response.data.data);
                            vm.addPaymentMethodForm = false;
                        }else{
                            vm.loadIntent();
                        }
                        flash(response.data.message, response.data.status);
                    }).catch(error => {
                        vm.loadIntent();
                        vm.loading = false;
                        flash(vm.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                        console.log(error);
                    });
            },
            /* remove payment method */
            removePaymentMethod(paymentMethodId, key){
                let vm = this;
                bootbox.confirm(vm.localeLang.MESSAGES.SURE_REMOVE_PAYMENT_METHOD, function(result){
                    if(result){
                        vm.loading = true;
                        axios.get("/api/payment-methods/"+paymentMethodId+"/remove").then(response => {
                            vm.loading = false;
                            if(response.data.status === "success"){
                                Vue.delete(vm.allPaymentMethods, key);
                            }
                            flash(response.data.message, response.data.status);
                        }).catch(error => {
                            vm.loading = false;
                            flash(vm.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            console.log(error);
                        });
                    }
                });
            },
            /* mark as default payment method */
            markDefaultPaymentMethod(paymentMethodId, key){
                let vm = this;
                bootbox.confirm(vm.localeLang.MESSAGES.SURE_MARK_DEFAULT_PAYMENT_METHOD, function(result){
                    if(result){
                        vm.loading = true;
                        axios.get("/api/payment-methods/"+paymentMethodId+"/mark-as-default").then(response => {
                            vm.loading = false;
                            if(response.data.status === "success"){
                                for (let i = 0; i < vm.allPaymentMethods.length; i++) {
                                    let paymentMethod = vm.allPaymentMethods[i];
                                    if(i === key){
                                        paymentMethod.is_default = true;
                                    }else{
                                        paymentMethod.is_default = false;
                                    }
                                    Vue.set(vm.allPaymentMethods, i, paymentMethod);
                                }
                            }
                            flash(response.data.message, response.data.status);
                        }).catch(error => {
                            vm.loading = false;
                            flash(vm.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                            console.log(error);
                        });
                    }
                });
            },
        }
    }
</script>
<style scoped>
    .dash-text{font-size: 16px;}
    .dash-text span{font-size: 17px;}
    .setting-btn{font-size: 17px;}
    .dash-icon {font-size: 21px;}
</style>
