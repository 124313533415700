export default {
    data() {
        return {
            loading : false,
            vehicleModels:[],
            vehicleBadges:[],
            vehicleSeries:[],
            vehicleCities:[],
            vehicleMinPriceLists: [
                {
                    disabled: false,
                    value: 3000,
                    display: "$3,000"
                },
                {
                    disabled: false,
                    value: 5000,
                    display: "$5,000"
                },
                {
                    disabled: false,
                    value: 7500,
                    display: "$7,500"
                },
                {
                    disabled: false,
                    value: 10000,
                    display: "$10,000"
                },
                {
                    disabled: false,
                    value: 15000,
                    display: "$15,000"
                },
                {
                    disabled: false,
                    value: 20000,
                    display: "$20,000"
                },
                {
                    disabled: false,
                    value: 25000,
                    display: "$25,000"
                },
                {
                    disabled: false,
                    value: 30000,
                    display: "$30,000"
                },
                {
                    disabled: false,
                    value: 35000,
                    display: "$35,000"
                },
                {
                    disabled: false,
                    value: 40000,
                    display: "$40,000"
                },
                {
                    disabled: false,
                    value: 45000,
                    display: "$45,000"
                },
                {
                    disabled: false,
                    value: 50000,
                    display: "$50,000"
                },
                {
                    disabled: false,
                    value: 60000,
                    display: "$60,000"
                },
                {
                    disabled: false,
                    value: 70000,
                    display: "$70,000"
                },
                {
                    disabled: false,
                    value: 80000,
                    display: "$80,000"
                },
                {
                    disabled: false,
                    value: 90000,
                    display: "$90,000"
                },
                {
                    disabled: false,
                    value: 100000,
                    display: "$100,000"
                },
                {
                    disabled: false,
                    value: 100000,
                    display: "$150,000"
                }
            ],
            vehicleMaxPriceLists: [
                {
                    disabled: false,
                    value: 3000,
                    display: "$3,000"
                },
                {
                    disabled: false,
                    value: 5000,
                    display: "$5,000"
                },
                {
                    disabled: false,
                    value: 7500,
                    display: "$7,500"
                },
                {
                    disabled: false,
                    value: 10000,
                    display: "$10,000"
                },
                {
                    disabled: false,
                    value: 15000,
                    display: "$15,000"
                },
                {
                    disabled: false,
                    value: 20000,
                    display: "$20,000"
                },
                {
                    disabled: false,
                    value: 25000,
                    display: "$25,000"
                },
                {
                    disabled: false,
                    value: 30000,
                    display: "$30,000"
                },
                {
                    disabled: false,
                    value: 35000,
                    display: "$35,000"
                },
                {
                    disabled: false,
                    value: 40000,
                    display: "$40,000"
                },
                {
                    disabled: false,
                    value: 45000,
                    display: "$45,000"
                },
                {
                    disabled: false,
                    value: 50000,
                    display: "$50,000"
                },
                {
                    disabled: false,
                    value: 60000,
                    display: "$60,000"
                },
                {
                    disabled: false,
                    value: 70000,
                    display: "$70,000"
                },
                {
                    disabled: false,
                    value: 80000,
                    display: "$80,000"
                },
                {
                    disabled: false,
                    value: 90000,
                    display: "$90,000"
                },
                {
                    disabled: false,
                    value: 100000,
                    display: "$100,000"
                },
                {
                    disabled: false,
                    value: 100000,
                    display: "$150,000"
                }
            ],
            vehicleMinSeats: [
                {
                    disabled: false,
                    value: 1,
                    display: "1"
                },
                {
                    disabled: false,
                    value: 2,
                    display: "2"
                },
                {
                    disabled: false,
                    value: 3,
                    display: "3"
                },
                {
                    disabled: false,
                    value: 4,
                    display: "4"
                },
                {
                    disabled: false,
                    value: 5,
                    display: "5"
                },
                {
                    disabled: false,
                    value: 6,
                    display: "6"
                },
                {
                    disabled: false,
                    value: 7,
                    display: "7"
                },
                {
                    disabled: false,
                    value: 8,
                    display: "8"
                },
                {
                    disabled: false,
                    value: 9,
                    display: "9"
                },
                {
                    disabled: false,
                    value: 10,
                    display: "10"
                }
            ],
            vehicleMaxSeats: [
                {
                    disabled: false,
                    value: 1,
                    display: "1"
                },
                {
                    disabled: false,
                    value: 2,
                    display: "2"
                },
                {
                    disabled: false,
                    value: 3,
                    display: "3"
                },
                {
                    disabled: false,
                    value: 4,
                    display: "4"
                },
                {
                    disabled: false,
                    value: 5,
                    display: "5"
                },
                {
                    disabled: false,
                    value: 6,
                    display: "6"
                },
                {
                    disabled: false,
                    value: 7,
                    display: "7"
                },
                {
                    disabled: false,
                    value: 8,
                    display: "8"
                },
                {
                    disabled: false,
                    value: 9,
                    display: "9"
                },
                {
                    disabled: false,
                    value: 10,
                    display: "10"
                }
            ],
            vehicleMinCapacity: [
                {
                    disabled: false,
                    value: 1000,
                    display: "1,000 cc"
                },
                {
                    disabled: false,
                    value: 1500,
                    display: "1,500 cc"
                },
                {
                    disabled: false,
                    value: 2000,
                    display: "2,000 cc"
                },
                {
                    disabled: false,
                    value: 2500,
                    display: "2,500 cc"
                },
                {
                    disabled: false,
                    value: 3000,
                    display: "3,000 cc"
                },
                {
                    disabled: false,
                    value: 3500,
                    display: "3,500 cc"
                },
                {
                    disabled: false,
                    value: 4000,
                    display: "4,000 cc"
                },
                {
                    disabled: false,
                    value: 4500,
                    display: "4,500 cc"
                },
                {
                    disabled: false,
                    value: 5000,
                    display: "5,000 cc"
                },
                {
                    disabled: false,
                    value: 5500,
                    display: "5,500 cc"
                },
                {
                    disabled: false,
                    value: 6000,
                    display: "6,000 cc"
                },
            ],
            vehicleMaxCapacity: [
                {
                    disabled: false,
                    value: 1000,
                    display: "1,000 cc"
                },
                {
                    disabled: false,
                    value: 1500,
                    display: "1,500 cc"
                },
                {
                    disabled: false,
                    value: 2000,
                    display: "2,000 cc"
                },
                {
                    disabled: false,
                    value: 2500,
                    display: "2,500 cc"
                },
                {
                    disabled: false,
                    value: 3000,
                    display: "3,000 cc"
                },
                {
                    disabled: false,
                    value: 3500,
                    display: "3,500 cc"
                },
                {
                    disabled: false,
                    value: 4000,
                    display: "4,000 cc"
                },
                {
                    disabled: false,
                    value: 4500,
                    display: "4,500 cc"
                },
                {
                    disabled: false,
                    value: 5000,
                    display: "5,000 cc"
                },
                {
                    disabled: false,
                    value: 5500,
                    display: "5,500 cc"
                },
                {
                    disabled: false,
                    value: 6000,
                    display: "6,000 cc"
                },
            ],
            vehicleFilters:{
                keyword:"",
                type: "",
                user: "",
                added:"",
                make:"",
                model:"",
                badge:"",
                series:"",
                min_price:"",
                max_price:"",
                body_type:"",
                drive_type:"",
                fuel_type:"",
                fuel_economy:"",
                transmission:"",
                cylinder:"",
                interior_color:"",
                exterior_color:"",
                features:"",
                lifestyle:"",
                min_year:"",
                max_year:"",
                doors:"",
                min_engine_size:"",
                max_engine_size:"",
                min_seats:"",
                max_seats:"",
                address:"",
                city:"",
                latitude:"",
                longitude:"",
                distance:"",
                onsale:"",
                sort_by:1
            }
        }
    },
    methods: {

        /* Get vehicle all make models */
        async getModels(){
            let url = '/api/vehicle/make/'+this.vehicleFilters.make+'/models-by-slug';
            axios.get(url).then(response => {
                this.vehicleModels = response.data.data;
                this.loading = false;
            }).catch(error => {
                this.vehicleModels = [];
                flash('Somthing went wrong. Please try again.', 'error');
                this.loading = false;
                console.log(error);
            });
        },
        /* Get vehicle all badges */
        async getBadges(){
            let url = '/api/vehicle/model/'+this.vehicleFilters.model+'/badges-by-slug';
            axios.get(url).then(response => {
                this.vehicleBadges = response.data.data;
                this.loading = false;
            }).catch(error => {
                this.vehicleBadges = [];
                flash('Somthing went wrong. Please try again.', 'error');
                this.loading = false;
                console.log(error);
            });
        },

        /* Get vehicle all series */
        async getSeries(){
            let url = '/api/vehicle/badge/'+this.vehicleFilters.badge+'/series-by-id';
            axios.get(url).then(response => {
                this.vehicleSeries = response.data.data;
                this.loading = false;
            }).catch(error => {
                this.vehicleSeries = [];
                flash('Somthing went wrong. Please try again.', 'error');
                this.loading = false;
                console.log(error);
            });
        },

        /* Get years as array object */
        yearsRange(startYear, next=0){
            let currentYear = new Date().getFullYear() + next;
            console.log("date");
            //alert(currentYear);
            let years = [];

            startYear = startYear || 1989;
            while ( startYear <= currentYear ) {
                years.push({
                    id: startYear,
                    name: startYear
                });
                startYear++;
            }
            return years.reverse();
        },

    }
}
