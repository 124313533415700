<template>
    <div>
        <div class="card-header" role="tab" id="heading-B">
            <a class="collapsed" data-toggle="collapse" href="#collapse-D" data-parent="#content" aria-expanded="false" aria-controls="collapse-D">
                {{ localeLang.TITLE }}
            </a>
        </div>  
        <div id="collapse-D" class="collapse review-tab" role="tabpanel" aria-labelledby="heading-D">
            <div v-if="loading">
                <spinner-component></spinner-component>
            </div>
            <div v-else>
                <a class="icon-btn ps4" href="javascript:;" v-on:click="toggleReviewForm" :disabled="showReviewForm">
                    <i class="fa fa-edit"></i> {{ localeLang.ACTIONS.ADD_REVIEW }}
                </a>
                <div class="review-form global-form" v-if="showReviewForm">
                    <form @submit.prevent="review">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{ localeLang.FORM.TITLE_LABEL }}</label>
                                    <input type="text" class="form-control" name="title" :placeholder="localeLang.FORM.TITLE_PLACEHOLDER" :class="errors.has('title')?'is-invalid':''" v-model="request.title" v-validate="'required|max:100'" data-vv-as="title">
                                    <div class="invalid-feedback" v-if="errors.has('title')">
                                        {{ errors.first('title') }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{ localeLang.FORM.RATING_LABEL }}</label>
                                    <div class="yellow-star-rating">
                                        <star-rating 
                                            name="rating"
                                            v-model="request.rating"
                                            :increment="0.5"
                                            :star-size="26"
                                            :show-rating="false"
                                        ></star-rating>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>{{ localeLang.FORM.DESCRIPTION_LABEL }}</label>
                                    <textarea class="form-control" name="review" :placeholder="localeLang.FORM.DESCRIPTION_PLACEHOLDER" :class="errors.has('review')?'is-invalid':''" v-model="request.review" v-validate="'required|max:500'" data-vv-as="description"></textarea>
                                    <div class="invalid-feedback" v-if="errors.has('review')">
                                        {{ errors.first('review') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn submit-btn" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm text-danger" role="status" aria-hidden="true"></span>
                                <span>{{ localeLang.ACTIONS.ACTION_SUBMIT }}</span>
                            </button>
                            <button type="submit" class="btn submit-btn" :disabled="errors.any()" v-else>{{ localeLang.ACTIONS.ACTION_SUBMIT }}</button>
                        </div>
                    </form>
                </div>
                <div v-else-if="allReviews.total > 0">
                    <div class="review-block" v-for="review in allReviews.data"> 
                        <div class="yellow-star-rating">
                            <star-rating 
                                :rating="review.rating" 
                                :star-size="14"
                                :show-rating="false" 
                                :read-only="true"
                                :round-start-rating="false"
                            ></star-rating>
                        </div> 
                        <h3>
                            <a href="javascript:;">{{ review.title }}</a>
                        </h3>
                        <p>{{ review.review }}</p>
                        <div class="author-detail">
                            <div class="name">
                                {{ localeLang.LABELS.BY }} {{ review.user.name }}
                            </div>
                            <div class="date">
                                {{ localeLang.LABELS.ON }}: {{ review.human_date }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="d-flex flex-wrap align-items-center justify-content-center">
                        <p>{{ localeLang.MESSAGES.NO_REVIEWS }}</p>
                    </div>
                    
                </div>
            </div>
            <div class="row text-center" v-if="allReviews.last_page > 1">
                <div class="custom-pagination">
                    <paginate
                        :page-count="allReviews.last_page"
                        :page-range="5"
                        :click-handler="paginateReviews"
                        :prev-text="localeLang.PAGINATION.PREV"
                        :next-text="localeLang.PAGINATION.NEXT"
                        :page-class="'page-item'"
                        :active-class="'active'"
                        :disabled-class="'disabled'"
                        :page-link-class="'page-link'"
                        :prev-link-class="'page-link'"
                        :next-link-class="'page-link'"
                        :container-class="'pagination justify-content-center'"
                    >
                    </paginate>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import bootbox from "bootbox";
    import Paginate from 'vuejs-paginate';
    import StarRating from 'vue-star-rating';
    import FormMixin from '../../mixins/FormMixin';
    import CommonMixin from '../../mixins/CommonMixin';
    import SpinnerComponent from '../../components/SpinnerComponent';
    import VeeValidate from 'vee-validate';
    Vue.use(VeeValidate);
    export default {
        name:"vehicle-reviews-component",
        props:[
            "vehicleId", 
            "vehicleSlug",
            "localeLang",
            "reviews",
            "isLoggedIn"
        ],
        mixins: [CommonMixin, FormMixin],
        components:{
            SpinnerComponent:SpinnerComponent,
            StarRating:StarRating,
            Paginate:Paginate,
        },
        data() {
            return {
                loading:false,
                showReviewForm:false,
                allReviews:[],
                request:{
                    vehicle_id:'',
                    title:'',
                    rating:0,
                    review:''
                }
            }
        },
        created(){
            this.allReviews = this.reviews;
        },
        methods:{
            /* Get reviews */
            async getReviews(page = 1){
                this.loading = true;
                let url = '/vehicle/'+this.vehicleSlug+'?page='+page;
                axios.get(url).then(response => {
                    this.allReviews = response.data.data;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                    this.allReviews = [];
                    console.log(error);
                });
            },
            /* Save vehicle review */
            review() {
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.request.vehicle_id = this.vehicleId;
                        axios.post('/api/vehicle/manage/save-review', this.request).then(response => {
                            this.loading = false;
                            if(response.data.status === 'success'){
                                flash(response.data.message, response.data.status);
                                this.showReviewForm = false;
                                this.resetFormFields(this.request);
                            }else if(response.data.status === 'warning'){
                                this.showReviewForm = false;
                                bootbox.alert(response.data.message);
                                this.resetFormFields(this.request);
                            }else{
                                flash(response.data.message, response.data.status);
                            }
                        }).catch(error => {
                            this.loading = false;
                            if(error.hasOwnProperty('response')){
                                this.setErrorsFromResponse(error.response.data);
                            }else{
                                flash(this.localeLang.MESSAGES.SOMTHING_WRONG, 'error');
                                console.log(error);
                            }
                        });
                    }else{
                        this.loading = false;
                    }
                });
            },
            /* Paginate reviews */
            async paginateReviews(page) {
                this.getReviews(page);
            },
            /* Toggle review form */
            toggleReviewForm(){
                if(this.isLoggedIn){
                    if(this.showReviewForm){
                        this.showReviewForm = false;
                    }else{
                        this.showReviewForm = true;
                    }
                }else{
                    window.location = "/login?intended=true";
                }
                
            }
        }
    }
</script>
